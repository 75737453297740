import {
  Affix,
  Breadcrumb,
  Form,
  Input,
  message,
  notification,
  Spin,
  TreeSelect,
} from "antd";

import * as S from "./styles";
import "./index.css";

import { useDispatch, useSelector } from "react-redux";
import { reportActions, reporttSelector } from "store";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ModelFeedback from "../ModelFeedback/ModelFeedback";
import { DropUpSvg } from "assets/svg";

const AddEmployee = () => {
  const [form] = Form.useForm();
  const lstDeparment = useSelector(reporttSelector.selectLst);
  const [valuedepart, setValueDepart] = useState([] as any);
  const [modelFeedback, setModelFeedback] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const createdata = useSelector(reporttSelector.selectItem);

  const { SHOW_PARENT } = TreeSelect;
  const roleid = sessionStorage.getItem("roles");
  const roles = roleid;
  let deparmentlst = lstDeparment?.result;
  deparmentlst = deparmentlst.slice(1);

  const converDepartment = deparmentlst?.map((o: any) => {
    if (o?.children?.length > 0) {
      return {
        ...o,
        disabled: true,
      };
    } else {
      return {
        ...o,
      };
    }
  });
  const converDepartmentTwo = deparmentlst?.map((o: any) => {
    let children = o.children.map((chid: any) => {
      return {
        ...chid,
        disabled: true,
      };
    });
    return {
      ...o,
      children,
      disabled: true,
    };
  });

  const [dataList, setDataList] = useState(converDepartment);
 
  const onChange = (newValuedeparment: string[]) => {
    if (newValuedeparment.length === 1) {
      setDataList(converDepartmentTwo);
    } else {
      setDataList(converDepartment);
    }
    setValueDepart(newValuedeparment);
  };

  const tPropDepartment = {
    treeData: dataList,
    value: valuedepart,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Enter Department",
    style: {
      width: "100%",
    },
  };
  const hanldeClickFeedback = () => {
    setModelFeedback(!modelFeedback);
  };

  const onCreateEmployee = (inputData: any) => {
    const inputAdd = {
      ...inputData,
      Department: inputData?.Department.toString(),
    };
    dispatch(
      reportActions.addempolyeePlain({
        params: inputAdd,
        onSuccess: (res) => {
          message.success("Add a new empployee successfully.");
          navigate("/tracking");
        },

        onError: (err) => {
          notification.error({
            message: `${err?.message}`,
          });
        },
      })
    );
  };

  const onCancel = () => {
    navigate("/tracking");
  };
  useEffect(() => {
    if (roles) {
      dispatch(reportActions.listdeparment({}));
    }
  }, []);
  return (
    <>
      {lstDeparment?.loading ? (
        <Spin />
      ) : (
        <div className="wrapper_create">
          <S.WrapperForm>
            <S.ControlBracrum>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <a href="/tracking" style={{ fontSize: 16 }}>
                    Home
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <S.Customa>
                    <a href="/tracking" style={{ fontSize: 16 }}>
                      Churn Prediction
                    </a>
                  </S.Customa>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="/tracking" style={{ fontSize: 16 }}>
                    Add Employee
                  </a>
                </Breadcrumb.Item>
              </Breadcrumb>
            </S.ControlBracrum>

            <S.CustomH2>Add Employee</S.CustomH2>
            <div className="control_add">
              <div className="screen">
                <div className="screen__content">
                  <Form
                    form={form}
                    onFinish={onCreateEmployee}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <div>
                      <Form.Item
                        name="EmployeeID"
                        label="Account"
                        rules={[
                          { required: true, message: "Account is required" },
                          {
                            min: 3,
                            message: "Account must be minimum 3 characters.",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Account"
                          style={{ maxWidth: 442 }}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        name="Department"
                        label="Department"
                        rules={[
                          { required: true, message: "Department is required" },
                        ]}
                      >
                        <TreeSelect
                          size="large"
                          {...tPropDepartment}
                          treeDefaultExpandAll
                          allowClear
                          showSearch
                          style={{ maxWidth: 442 }}
                          dropdownMatchSelectWidth={false}
                          dropdownStyle={{
                            overflow: "auto",
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        name="Text_Explanation"
                        label="Reasons"
                        rules={[
                          { required: true, message: "Reasons is required" },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Reasons"
                          style={{ maxWidth: 442 }}
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        name="Comments"
                        label="Note"
                        rules={[
                          { required: true, message: "Note is required" },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Note"
                          style={{ maxWidth: 442 }}
                        />
                      </Form.Item>
                    </div>

                    <S.PostionBtn>
                      <S.ButtonCancel onClick={() => onCancel()}>
                        Cancel
                      </S.ButtonCancel>
                      <S.ButtonSubmit
                        htmlType="submit"
                        loading={createdata.loading}
                      >
                        Add Employee
                      </S.ButtonSubmit>
                    </S.PostionBtn>
                  </Form>
                </div>
              </div>
            </div>
          </S.WrapperForm>
        </div>
      )}

      {/* {modelFeedback ? (
        <ModelFeedback isModel={setModelFeedback} />
      ) : (
        <Affix offsetBottom={70}>
          <div className="custom_feedback_employ">
            <div className="feedback_child_employ">
              <S.ButtonFeedback
                type="text"
                icon={<DropUpSvg className="css_svg" />}
                onClick={hanldeClickFeedback}
              >
                Feedback
              </S.ButtonFeedback>
            </div>
          </div>
        </Affix>
      )} */}
    </>
  );
};

export default AddEmployee;
