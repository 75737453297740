import styled from "styled-components";

export const NotFoundTitle = styled.p`
  font-size: 20px;
  font-weight: 510;
  line-height: 32px;
  letter-spacing: 0.15000000596046448px;
  color: #000000de;
  margin-bottom: 4px;
`;

export const NotFoundDes = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.4000000059604645px;
  color: #000000;
  opacity: 60%;
  margin-bottom: 4px;
`;

export const NotFoundArea = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
`