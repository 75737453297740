import { Button, Col, Form, message, Modal, ModalProps, Radio, Row, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportActions, reporttSelector } from "store";
import * as S from "./styles";
import { LoadingOutlined, StarFilled } from "@ant-design/icons";
type Props = ModalProps & {
  setIsModalBulConfirm: (value: boolean) => void;
  dataValueChurn: any;
  valueChurn: any;
  setBulCheck: (value: boolean) => void;
  setIsModalThankYou: (value: boolean) => void;
  getData: () => void;
  inputComments: any;
};

type FormType = {};

const ModelBulConfirmNo = (props: Props) => {
  const [form] = Form.useForm<FormType>();
  const onOk = async () => form.submit();
  const dispatch = useDispatch<any>();
  const onCancel = () => onInvisible();
  const [focused, setFocused] = useState(false);
  // const fromData = useSelector(reporttSelector.selectdate);
  // const fromDataDate = fromData?.result?.from_date;
  const currentDate = useSelector(reporttSelector.selectCurrentDate);
  const [disabledComment, setDisabledComment] = useState(true);
  let dates = new Date();
  const datesNow = moment(dates).format("YYYY/MM/DD");
  const [showOk, setShowOk] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onInvisible = () => {
    props.setIsModalBulConfirm(false);
    form.resetFields();
    props?.getData();
  };
  const handleChange = (e: any) => {
    checkDisabledBtnOk();
  };

  const checkDisabledBtnOk = () => {
    if (
      (form.getFieldValue("reason") !== "other") ||
      (form.getFieldValue("reason") === "other" &&
        form.getFieldValue("Comment"))
    ) {
      setShowOk(false);
    } else {
      setShowOk(true);
    }
  };

  const handleChangeReason = (e: any) => {
    checkDisabledBtnOk();
    if (e.target.value === "other") {
      setDisabledComment(false);
    } else {
      setDisabledComment(true);
    }
  };

  useEffect(() => {
    if (props?.dataValueChurn?.ChurnIntention !== 'No') return
    const listReason = [
      "Làm việc bình thường, vui vẻ với công việc",
      "Được tăng lương/ thưởng",
      "Khách hàng đánh giá tốt",
    ];
    form.setFieldValue("reason", props.inputComments);
    if (!listReason.includes(props.inputComments)) {
      form.setFieldValue("reason", 'other');
      form.setFieldValue("Comment", props.inputComments);
    }
  }, []);

  const onFinish = async (inputData: any) => {
    // const comment = inputData.Comment;
    if(isLoading) return;

    const dataChurnNo = {
      employee_id: props?.dataValueChurn?.EmployeeID,
      pred_date: props?.dataValueChurn?.PredictionDate,
      churn_intention: props?.valueChurn,
      bul_known: 'No',
      note:
        inputData?.reason !== undefined && inputData?.reason !== "other"
          ? inputData?.reason
          : inputData?.Comment !== undefined
            ? inputData?.Comment
            : props.inputComments !== undefined
              ? props.inputComments
              : "",
      pred_date_from: currentDate ? currentDate : "",
      pred_date_to: datesNow,
    };

    console.log('dataChurnNo', dataChurnNo)
    try {
      setIsLoading(true);
      await new Promise((res) =>
        dispatch(
          reportActions.updateChurnIntention({
            params: dataChurnNo,
            onSuccess: (res) => {
              props.setIsModalBulConfirm(false);

              message.success(`${res?.message}`);
              props?.getData();
              if (res?.last_empl === "true") {
                props?.setIsModalThankYou(true);
              }
            },
            onError: (res: any) => {
              message.error("Update churn fail");
            },
          })
        )
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Update churn fail");
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" preserve={false}>
      <Modal
        {...props}
        centered
        title={null}
        destroyOnClose
        width={600}
        footer={null}
        closable={false}
      >
        <S.BUGradeHeader>
          <S.ModalTitle>BUL Confirm</S.ModalTitle>
        </S.BUGradeHeader>
        <Space align="start" style={{ marginBottom: "7px" }}>
          <StarFilled style={{ fontSize: "16px", color: "#722ED1", marginTop: '3px' }} />
          <S.CustomPTitle>
            Anh chị đã chọn No - anh chị đồng ý nhân viên không có dấu hiệu nghỉ
            việc vì có các biểu hiện nào dưới đây:
          </S.CustomPTitle>
        </Space>
        <Form.Item name="reason">
          <Radio.Group
            onChange={handleChangeReason}
            defaultValue={props?.dataValueChurn?.Bul_Known}
          >
            <S.CustomRadio value={"Làm việc bình thường, vui vẻ với công việc"}>
              Làm việc bình thường, vui vẻ với công việc
            </S.CustomRadio>
            <S.CustomRadio value={"Được tăng lương/ thưởng"}>
              Được tăng lương/ thưởng
            </S.CustomRadio>
            <S.CustomRadio value={"Khách hàng đánh giá tốt"}>
              Khách hàng đánh giá tốt
            </S.CustomRadio>
            <S.CustomRadio value={"other"}>Lý do khác</S.CustomRadio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="Comment"
          style={{ marginLeft: "32px", marginBottom: "20px" }}
        >
          <TextArea
            placeholder="Enter other reason"
            title="Note"
            rows={3}
            disabled={disabledComment}
            onChange={checkDisabledBtnOk}
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: '20px'
          }}
        >
          <div style={{ display: "flex", gap: "1rem" }}>
            <Form.Item>
              <Button
                onClick={onCancel}
                style={{
                  padding: "8px, 16px, 8px, 16px",
                  width: "116px",
                  height: "40px",
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  padding: "8px, 16px, 8px, 16px",
                  width: "116px",
                  height: "40px",
                }}
                onClick={onOk}
                disabled={showOk}
              >
                Submit {isLoading && <LoadingOutlined />}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default ModelBulConfirmNo;
