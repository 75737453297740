import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncAction } from "../../helpers/thunkWrapper";
import { ActionParams, Response } from "../../types/common";
import { buildUrl } from "helpers/utils/app";

const _subenponit: string = "permission";

export const listUser = createAsyncThunk(
  "list/get_user",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/${_subenponit}/get-user-list`;

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
export const addRole = createAsyncThunk(
  "add/role",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/${_subenponit}/add-roles`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const removeRole = createAsyncThunk(
  "remove/removerole",
  createAsyncAction(async (payload: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/${_subenponit}/remove-roles`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const removeUser = createAsyncThunk(
  "remove/removeuser",
  createAsyncAction(async (payload: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/${_subenponit}/remove-users`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const updateRole = createAsyncThunk(
  "update/updaterole",
  createAsyncAction(async (payload: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/${_subenponit}/update-roles`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const getUser = createAsyncThunk(
  "read/readone",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(
      `${process.env.REACT_APP_API_URL}/${_subenponit}/get-user`,
      payload.params
    );

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
export const searchPermission = createAsyncThunk(
  "search/searchPermission",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(
      `${process.env.REACT_APP_API_URL}/${_subenponit}/search-permission`,
      payload.params
    );

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
export const updateUser = createAsyncThunk(
  "update/updateuser",
  createAsyncAction(async (payload: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/${_subenponit}/update-user`;
    const response = await api.caller.post(endpoint, payload.params);

    const data = response.data;

    return data;
  })
);
