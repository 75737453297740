import {
  Button,
  DatePicker,
  DatePickerProps,
  Input,
  List,
  Pagination,
  PaginationProps,
  Select,
  Space,
} from "antd";
import * as S from "./styled";
import {
  IconAdd,
  IconArrowLeft,
  IconArrowRight,
  IconDot,
  IconStar,
} from "assets/svg";
import { fakeData } from "./fakeData";
import { useEffect, useState } from "react";
import { getChatbotSurvey } from "services/chatbot";
import {
  IChatSurvey,
  IGetChatbotSurveyParams,
  statusFilter,
  submitStatus,
} from "types/chatbot";

interface IProps {
  setAdd: (item: any) => void;
  setDetail: (item: any) => void;
  increaseCount: number;
  detailData?: IChatSurvey;
}
export const MenuMessages = ({ setAdd, setDetail, increaseCount, detailData }: IProps) => {
  const { Search } = Input;
  const [currentPage, setCurrentPage] = useState(1);
  const [surveyData, setSurveyData] = useState<IChatSurvey[]>();
  const [itemId, setItemId] = useState<string>();
  const [filter, setFilter] = useState<IGetChatbotSurveyParams>();
  const pageSize = 10;
  useEffect(() => {
    if(!detailData) {
      setItemId(undefined)
    }
  }, [detailData])

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const onChangeMonth: DatePickerProps["onChange"] = (date, dateString) => {
    console.log("date, dateString", date, dateString);
    setFilter({
      ...filter,
      month: dateString,
    });
  };

  const statusArray = Object.values(statusFilter).map((status) => ({
    value: status,
    label: status,
  }));

  const handleChangeSelect = (value: string) => {
    setFilter({
      ...filter,
      status: value !== statusFilter.all ? value as statusFilter : undefined,
    });
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    console.log(current, pageSize);
  };

  const statusColor = (value: string) => {
    console.log(value)
    if (value === "running") {
      return <span style={{ color: "#389E0D" }}>Running</span>;
    } else if (value === "created") {
      return <span style={{ color: "#1890FF" }}>Created</span>;
    } else if (value === "paused") {
      return <span style={{ color: "#FAAD14" }}>Paused</span>;
    } else if (value === "done") {
      return <span style={{ color: "#F5222D" }}>Done</span>;
    } else {
      return <span style={{ color: "grey" }}>Draft</span>;
    }
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <a>
          <IconArrowLeft />
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          <IconArrowRight />
        </a>
      );
    }
    return originalElement;
  };

  const getSurveyData = async () => {
    try {
      const data = await getChatbotSurvey(filter);
      if (data) {
        setSurveyData(data.surveys);
        setDetail(data.surveys[0]);
        setItemId(data.surveys[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddOrEdit = (status: string) => {
    if (status ==='add') {
        setDetail(null);
    }
    setAdd(status);
  };

  const onSearch = (value: string) => {
    console.log("value", value);
    setFilter({
      ...filter,
      name: value,
    });
  };

  const handleClick = (item: IChatSurvey) => {
    setDetail(item);
    handleAddOrEdit("edit");
    setItemId(item?.id);
  };

  useEffect(() => {
    getSurveyData();
  }, [increaseCount, filter]);

  return (
    <S.MenuWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <S.MenuTitle>Messages</S.MenuTitle>
        <S.AddNewBtn onClick={() => handleAddOrEdit("add")}>
          <span>
            <IconAdd />
          </span>
          <span>New</span>
        </S.AddNewBtn>
      </div>
      <div>
        <S.MenuAction>
          <Space direction="horizontal" style={{ width: "568px" }}>
            <DatePicker
              onChange={onChangeMonth}
              picker="month"
              size="large"
              style={{ width: 120 }}
            />
            <Select
              placeholder="Choose status"
              size="large"
              style={{ width: 120 }}
              onChange={handleChangeSelect}
              options={statusArray}
            />
          </Space>
          <Search
            placeholder="Search"
            allowClear
            enterButton
            size="large"
            onSearch={onSearch}
            style={{ width: 300 }}
          />
        </S.MenuAction>
        <div style={{ marginTop: "20px", fontWeight: "600", fontSize: "12px" }}>
          Showing: {surveyData?.length ? surveyData?.length : 0}
        </div>
      </div>
      <S.CardsWrapper>
        {surveyData &&
          surveyData
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((item, index) => {
              return (
                <S.CustomCard
                  key={item.id}
                  onClick={() => handleClick(item)}
                  style={{
                    backgroundColor: itemId === item.id ? "#F9F0FF" : "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <S.IconWrapper>
                      <span>
                        <IconStar />
                      </span>
                    </S.IconWrapper>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <S.SurveyText>{item.name}</S.SurveyText>
                      <S.TimeText>
                        {item.start_date.replace(/-/g, "/")} -{" "}
                        {item.end_date.replace(/-/g, "/")}{" "}
                        <span>
                          {" "}
                          <IconDot />{" "}
                        </span>{" "}
                        <S.UppercaseText>{item.frequency}</S.UppercaseText>{" "}
                        <span>
                          {" "}
                          <IconDot />
                        </span>{" "}
                        {item.time}
                      </S.TimeText>
                      <S.DepartmentText>
                        {item.departments?.toString()}
                      </S.DepartmentText>
                    </div>
                  </div>
                  <S.CardStatus>{statusColor(item.status)}</S.CardStatus>
                </S.CustomCard>
              );
            })}
      </S.CardsWrapper>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          total={surveyData?.length}
          itemRender={itemRender}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          current={currentPage}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </S.MenuWrapper>
  );
};
