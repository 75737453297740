import { useEffect } from "react";

import { memo } from "react";

import TableHrpr from "../components/TableHrpr/TableHrpr";
import { useNavigate } from "react-router-dom";
import TableBul from "../components/TableBul/TableBul";
import TableHRA from "../components/TableHRA/TableHRA";

const Tracking = () => {
  const navigate = useNavigate();
  const roleid = sessionStorage.getItem("roles");
  const roles = roleid;

  useEffect(() => {
    if (!roles) {
      navigate("/no_role");
    }
  }, []);

  useEffect(() => {
    if (roles === "ADMIN") {
      navigate("/admin");
    }
  }, []);

  return (
    <>
      {roles?.includes("HRPR") && <TableHrpr />}
      {roles?.includes("BUL") && <TableBul />}
      {roles?.includes("HRA") && <TableHRA />}
    </>
  );
};

export default memo(Tracking);
