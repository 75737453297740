import { Popover, Tooltip } from "antd";
import { useEffect, useState } from "react";
import * as S from "./style";
import "./index.scss";
import { TNote } from "types/note";

type Props = {
  datanote: TNote[];
  key: any;
};

const Note = (props: Props) => {
  const isDataNote = props?.datanote;
  const [dataShow, setDataShow] = useState<any[]>([]);
  const onClickLess = (e: any) => {
    setDataShow(isDataNote?.slice(0, 3));
  };

  const handleDataList = () => {
    setDataShow((state) => {
      if (!isDataNote?.length || state.length === isDataNote.length)
        return state;
      const step = Math.floor(state.length / 3);

      const arr = isDataNote.slice(step * 3, (step + 1) * 3); //
      if (!arr.length) return state;
      return [...state, ...arr];
    });
  };

  const onClick = handleDataList;

  useEffect(() => {
    handleDataList();
  }, []);

  return (
    <>
      <div>
        {!isDataNote && (
          <>
            <div></div>
          </>
        )}

        {isDataNote &&
          Object.values(dataShow)?.map((value: any, index) => {
            if (value?.content?.length === 0) {
              return "";
            } else {
              return (
                <>
                  <S.Div>
                    <S.customNoteEmailhr>
                      {new String(value?.user_email)?.split("@")[0]}
                    </S.customNoteEmailhr>
                    :
                    {new String(value?.content).length > 40 ? (
                    
                      <Tooltip  placement="left" title={value?.content} getPopupContainer={(triggerNode) => triggerNode.parentElement!}>
                        {value?.content.slice(0, 40)}...
                      </Tooltip>
                    ) : (
                      <span style={{ marginLeft: 5 }}>{value?.content}</span>
                    )}
                  </S.Div>
                </>
              );
            }
          })}

        {dataShow.length < (isDataNote?.length || 0) ? (
          <div>
            <S.ButtonMore type="text" size="small" onClick={onClick}>
              more
            </S.ButtonMore>
          </div>
        ) : dataShow.length > 3 ? (
          <div>
            <S.ButtonMore type="text" size="small" onClick={onClickLess}>
              less
            </S.ButtonMore>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Note;
