import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncAction } from "../../helpers/thunkWrapper";
import { ActionParams } from "../../types/common";

export const sendEmail = createAsyncThunk(
  "send/send_email",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/notify-status`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
