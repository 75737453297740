import {
  Button,
  DatePicker,
  DatePickerProps,
  Input,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import * as S from "./styled";
import avatar from "../../../../assets/images/avatar.png";
import { BUGrade } from "./components/BU_Grade";
import { Congratulation } from "./components/Congatulation";
import { PreviewEmail } from "./components/Preview_Email";
import { NotFoundArea } from "../NotFoundArea";
import { getLeadership, updateProcessing } from "services/leadership";
import { ILeadership, IUpdateBody } from "types/leadership";
import moment from "moment";

interface DataType {
  key: React.Key;
  no: string;
  bu: string;
  bul: string;
  contact: string;
  fullFeedback: string;
  informativeFeedback: string;
  totalScore: number;
  rank: number;
  note: string;
}
export const Processing = () => {
  const { Title, Paragraph } = Typography;
  const { Search } = Input;
  const [isLoading, setIsLoading] = useState(false);
  const isUpdating = useRef(false);
  const isSending = useRef(false);
  const [originalData, setOriginalData] = useState<ILeadership[]>([]);
  const [dataShow, setDataShow] = useState<ILeadership[]>([]);
  const [isBUGradeModalOpen, setIsBUGradeModalOpen] = useState<boolean>(false);
  const [isCongratulationModalOpen, setIsCongratulationModalOpen] =
    useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selected, setSelected] = useState<ILeadership>();
  const [selectedDate, setDate] = useState<string>("");
  const [selectedType, setType] = useState<string>("A");
  const [updatedEmail, setEmail] = useState<string>("");
  const textSearch = useRef("");

  const columns: ColumnsType<ILeadership> = [
    {
      title: "No",
      dataIndex: "no",
      width: 60,
      render: (value: string, record: ILeadership, index: number) => {
        const no = (currentPage - 1) * 10 + index + 1;
        return (
          <div>
            <span className="pl-2">{no}</span>
          </div>
        );
      },
    },
    {
      title: "BU",
      dataIndex: "bu_name",
      width: 161,
      sorter: (a, b) => a.bu_name.localeCompare(b.bu_name),
    },
    {
      title: "BUL",
      dataIndex: "department",
      width: 200,
      sorter: (a, b) => a.department.localeCompare(b.department),
      render: (text, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              <S.ProcessTitle>{text}</S.ProcessTitle>
              <div>
                {record?.bul_contact
                  ? record?.bul_contact?.replace("@fpt.com", "")
                  : ""}
              </div>
            </span>
          </div>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: "bul_contact",
      width: 161,
      sorter: (a, b) => a.bul_contact.localeCompare(b.bul_contact),
      render: (text) => {
        return (
          <div>
            <S.ProcessTitle>{text}</S.ProcessTitle>
            {/* <div>{text}</div> */}
          </div>
        );
      },
    },
    {
      title: "Full Feedback",
      dataIndex: "point_confirm",
      width: 161,
      sorter: (a, b) => a.point_confirm - b.point_confirm,
      render: (value: number) => `${Math.round(value)}/30`,
    },
    {
      title: "Fast Feedback",
      dataIndex: "time_score",
      width: 145,
      sorter: (a, b) => a.time_score - b.time_score,
      render: (value: number) => `${Math.round(value)}/30`,
    },
    {
      title: "Informative Feedback",
      dataIndex: "point_feedback",
      width: 161,
      sorter: (a, b) => a.point_feedback - b.point_feedback,
      render: (value: number) => `${Math.round(value)}/40`,
    },
    {
      title: "Total score",
      dataIndex: "total_score",
      width: 161,
      sorter: (a, b) => a.total_score - b.total_score,
      render: (value: number, record: ILeadership) =>
        `${Math.round(value)}/100`,
    },
    {
      title: "Rank",
      dataIndex: "rank",
      width: 161,
      sorter: (a, b) => a.rank - b.rank,
      defaultSortOrder: "ascend",
    },
    {
      title: "Note",
      dataIndex: "note",
      width: 161,
      sorter: (a, b) => a.note.localeCompare(b.note),
    },
  ];

  const showAddModal = (record: ILeadership) => {
    setSelected(record);
    setIsBUGradeModalOpen(true);
  };

  const onChangeMonth: DatePickerProps["onChange"] = (date) => {
    const dateData = date ? moment(date).format("YYYY-MM") : "";
    setDate(dateData);
    getLeadershipData(selectedType, dateData);
  };

  const handleChangeSelect = (value: string) => {
    setType(value);
    getLeadershipData(value, selectedDate);
  };

  const getLeadershipData = async (tabType: string, date?: string) => {
    try {
      setIsLoading(true);
      const data = await getLeadership(
        date || "",
        tabType === "A" ? "campain_large" : "campain_small"
      );
      onSearch(textSearch.current);
      let newList: ILeadership[] = data?.data?.filter((item) =>
        item.department.toLowerCase().includes(textSearch.current.toLowerCase())
      );
      setDate(data?.leaderboard_period)
      setDataShow(newList);
      setOriginalData(data?.data);
      setIsLoading(false);
    } catch (error) {
      setOriginalData([]);
      setDataShow([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLeadershipData(selectedType, selectedDate);
  }, []);

  useEffect(() => {
    if (originalData) {
      setCurrentPage(1);
    }
  }, [originalData]);

  const onSearch = (value: string) => {
    textSearch.current = value;
    const newList: ILeadership[] = originalData.filter((item) =>
      item.department.toLowerCase().includes(textSearch.current.toLowerCase())
    );
    setDataShow(newList);
  };

  const handleChangePage = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current ?? 1);
  };

  const updateInfo = async (data: IUpdateBody) => {
    if (isUpdating.current) return
    try {
      isUpdating.current = true
      const info = {
        ...data,
        reward_price: selected?.reward_price || "0",
        leaderboard_type: selectedType === "A" ? "large" : "small",
        leaderboard_size: selectedType === "A" ? ">=5" : "<5",
        rank: selected?.rank,
        leaderboard_period:
          selectedDate || moment(new Date()).format("YYYY-MM"),
      };
      await updateProcessing(info);
      getLeadershipData(selectedType, selectedDate);
      setIsBUGradeModalOpen(false);
      message.success("Update note successfully");
      isUpdating.current = false;
    } catch (error) {
      console.log(error);
      message.error("Update note fail");
      isUpdating.current = false;
    }
  };

  const sendEmail = async (data: IUpdateBody, modalType: string) => {
    if (!data.bu_email && !updatedEmail) {      
      return;
    }
    if (isSending.current) return
    try {
      isSending.current = true;
      const info = {
        ...data,
        reward_price: selected?.reward_price || "0",
        leaderboard_type: selectedType === "A" ? "large" : "small",
        leaderboard_size: selectedType === "A" ? ">=5" : "<5",
        rank: selected?.rank,
        leaderboard_period:
          selectedDate || moment(new Date()).format("YYYY-MM"),
        bu_note: data.bu_note || "",
      };
      if (modalType === "preview") {
        setEmail(data.bu_email);
        isSending.current = false;
        return;
      } else if (modalType === "sendEmail") {
        info.bu_email = updatedEmail;
      }

      await updateProcessing(info);
      message.success("Send mail successfully");
      setIsCongratulationModalOpen(false);
      setIsPreviewModalOpen(false);
      isSending.current = false;
    } catch (error) {
      console.log(error);
      message.error("Send mail fail");
      isSending.current = false;
    }
  };

  return (
    <>
      <S.LeadershipTabWrapper>
        <S.LeadershipContent>
          <S.LeadershipLeft>
            <S.LeadershipActionTable>
              <Space direction="horizontal">
                <Select
                  defaultValue="Board A"
                  size="large"
                  style={{ width: 120 }}
                  onChange={handleChangeSelect}
                  options={[
                    {
                      value: "A",
                      label: "Board A",
                    },
                    {
                      value: "B",
                      label: "Board B",
                    },
                  ]}
                />
                <DatePicker
                  onChange={onChangeMonth}
                  picker="month"
                  size="large"
                  style={{ width: 140 }}
                  format={'YYYY - MM'}
                  value={selectedDate ? moment(selectedDate) : null}
                  allowClear={false}
                />
                {/* <Select
                  defaultValue="Campaign 01"
                  size="large"
                  style={{ width: 180 }}
                  onChange={handleChangeSelect}
                  options={[
                    {
                      value: "Campaign 01",
                      label: "Campaign 01",
                    },
                    {
                      value: "Campaign 02",
                      label: "Campaign 02",
                    },
                    {
                      value: "Campaign 03",
                      label: "Campaign 03",
                    },
                  ]}
                /> */}
              </Space>
              <Search
                placeholder="Search BU"
                allowClear
                enterButton
                size="large"
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </S.LeadershipActionTable>
            <Table
              style={{ position: "relative" }}
              columns={columns}
              dataSource={dataShow}
              loading={isLoading}
              pagination={{
                position: ["bottomLeft"],
                current: currentPage,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30"],
              }}
              scroll={isLoading ? { x: 800 } : undefined}
              // scroll={{ y: 240 }}
              // scroll={{ x: 'max-content' }}
              locale={{
                emptyText: <NotFoundArea />,
              }}
              onChange={handleChangePage}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    setEmail(record.bul_contact);
                    showAddModal(record);
                  }, // click row
                };
              }}
              rowKey={(o) => o?.department}
            />
            {/* <S.PublishBtn>Publish</S.PublishBtn> */}
          </S.LeadershipLeft>
        </S.LeadershipContent>
      </S.LeadershipTabWrapper>
      {isBUGradeModalOpen && (
        <BUGrade
          selectedDate={selectedDate}
          updateInfo={updateInfo}
          data={selected}
          isBUGradeModal={isBUGradeModalOpen}
          onClose={() => {
            setIsBUGradeModalOpen(false);
          }}
          onOpenCongrat={() => {
            setIsCongratulationModalOpen(true);
          }}
        />
      )}
      {isCongratulationModalOpen && (
        <Congratulation
          updatedEmail={updatedEmail}
          sendEmail={sendEmail}
          data={selected}
          isCongratulationModal={isCongratulationModalOpen}
          onClose={() => {
            setIsCongratulationModalOpen(false);
          }}
          onOpenBUGrade={() => {
            setIsBUGradeModalOpen(true);
          }}
          onOpenPreview={() => {
            setIsPreviewModalOpen(true);
          }}
        />
      )}

      {isPreviewModalOpen && (
        <PreviewEmail
          type={selectedType}
          sendEmail={sendEmail}
          data={selected}
          updatedEmail={updatedEmail}
          isPreviewModal={isPreviewModalOpen}
          onClose={() => {
            setIsPreviewModalOpen(false);
          }}
          onOpenBUGrade={() => {
            setIsCongratulationModalOpen(true);
          }}
        />
      )}
    </>
  );
};
