import { Button, Modal } from "antd";
import { IconWarning } from "assets/svg";
import { submitStatus } from "types/chatbot";
interface IProps {
  isOpen: boolean;
  setIsOpen: (item: boolean) => void;
  deleteItem: () => void;
  confirmStatus: string;
  createAndUpdate: () => void;
  checkInputContent: boolean;
  setInputContent: (item: boolean) => void;
}
export const ConfirmModal = ({
  isOpen,
  setIsOpen,
  deleteItem,
  confirmStatus,
  createAndUpdate,
  checkInputContent,
  setInputContent
}: IProps) => {
  let status = confirmStatus;
  if (status === "paused") {
    status = "pause";
  } else if (status === "running") {
    status = "enable";
  } else {
    status = "delete";
  }
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleOk = () => {
    if (checkInputContent) {
      setInputContent(false);
      setIsOpen(false);
    }
    else if (confirmStatus === submitStatus.delete) {
      console.log(confirmStatus);
      deleteItem();
    } else {
      createAndUpdate();
    }
    setIsOpen(false);
  };

  const handleCancel = () => {
    console.log("handleCancel");
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} footer={null} onCancel={handleCancel} width={416}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <span>
          <IconWarning />
        </span>
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {!checkInputContent ? `Are you sure to ${status} this campaign?` : 'You need to input content to create this campaign'}
        </span>
      </div>
      <div style={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}>
        <Button
          onClick={handleCancel}
          style={{
            padding: "8px, 16px, 8px, 16px",
            width: "116px",
            height: "40px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          type="primary"
          htmlType="submit"
          style={{
            padding: "8px, 16px, 8px, 16px",
            width: "116px",
            height: "40px",
          }}
        >
          {!checkInputContent ? capitalizeFirstLetter(status) : 'Input content'}
        </Button>
      </div>
    </Modal>
  );
};
