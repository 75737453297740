import { useState, useRef, useEffect, createContext } from "react";

export const WebsocketContext = createContext([false, null, () => {}] as any);

export const WebsocketProvider = ({ children }: any) => {
  const [isReady, setIsReady] = useState(false);
  const [val, setVal] = useState(null);

  const ws = useRef<any>(null);

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL as any);

    socket.onopen = () => setIsReady(true);
    socket.onclose = () => setIsReady(false);
    socket.onmessage = (event) => setVal(event.data);

    ws.current = socket;

    return () => {
      socket.close();
    };
  }, []);

  const ret = [isReady, val, ws.current?.send.bind(ws.current)];

  return (
    <WebsocketContext.Provider value={ret}>
      {children}
    </WebsocketContext.Provider>
  );
};
