import React from "react";
import ReactDOM from "react-dom";
import Router from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "configs/auth";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { theme, ThemeProvider } from "./helpers/styled-component";
import { store } from "./store/store";
import { ConfigProvider } from "antd";
import "antd/dist/antd.less";
import { WebsocketProvider } from "context";
import "antd/dist/antd.variable.min.css";
import GlobalStyled from "components/GlobalStyled";
import { APP_VERSION } from "version";

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

ConfigProvider.config({ theme: { primaryColor: "#5440B8" } });
ReactDOM.render(
  <>
    <WebsocketProvider>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <ReduxProvider store={store}>
              <BrowserRouter>
                <ConfigProvider>
                  <GlobalStyled>
                    <Router version={APP_VERSION} />
                  </GlobalStyled>
                </ConfigProvider>
              </BrowserRouter>
            </ReduxProvider>
          </QueryClientProvider>
        </MsalProvider>
      </ThemeProvider>
    </WebsocketProvider>
  </>,
  document.getElementById("root")
);
