import { createSlice } from "@reduxjs/toolkit";
import { feedBack } from "./actions";
import { State } from "./type";

const initialState: State = {
  item: {
    loading: false,
    result: undefined,
  },
};

export const slice = createSlice({
  name: "send",
  initialState,
  reducers: {
   
  },
 
  extraReducers: (builder) => {
    builder.addCase(feedBack.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(feedBack.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(feedBack.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
  },
});

export const actions = {
  ...slice.actions,
  feedBack,
};

export const { reducer } = slice;
