import { Button, Form } from "antd";
import styled from "styled-components";

export const LeadershipTabWrapper = styled.div`
  width: 100%;
`;

export const LeadershipContent = styled.div`
  display: flex;
  /* background-color: yellow; */
  gap: 20px;
  padding: 24px;
`;

export const LeadershipLeft = styled.div`
  flex: 1;
`;

export const LeadershipActionTable = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ProcessTitle = styled.div`
  font-weight: 590;
  line-height: 16px
`
export const BUGradeHeader = styled.div`
  display:flex;
  flex-direction:column; 
  justify-content:center; 
  align-items:center;
  margin-bottom: 1rem;
`

export const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
`
export const ModalSubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;

`

export const RowItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const ItemDetail = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`
export const CustomFormLabel = styled(Form.Item)`
  width: 100%;
  font-size: 14px;
  font-weight: 510;
  line-height: 20px;
  text-align: left;
`;

export const CustomText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color:#531DAB;
`

export const PublishBtn = styled(Button)`
    position: absolute; 
    right: 21px; 
    bottom: 46px;
    width: 200px;
    height: 40px;
    padding: 6.4px 15px 6.4px 15px;
    gap: 10px;
    border-radius: 2px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    background-color: #722ED1;
    color: #fff;
`