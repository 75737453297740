import { Button, Modal } from "antd";
import { IconWarning } from "assets/svg";
interface IProps {
  submitText: string;
  cancelText: string;
  content: string;
  isOpen: boolean;
  setIsOpen: (item: boolean) => void;
  submit: () => void;
}
export const ConfirmModal = ({
  submitText,
  cancelText,
  content,
  isOpen,
  setIsOpen,
  submit,
}: IProps) => {
  const handleOk = () => {
    submit();
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <Modal open={isOpen} footer={null} onCancel={handleCancel} width={416}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <span>
          <IconWarning />
        </span>
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {content}
        </span>
      </div>
      <div style={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}>
        <Button
          onClick={handleCancel}
          style={{
            padding: "8px, 16px, 8px, 16px",
            width: "116px",
            height: "40px",
          }}
        >
          {cancelText}
        </Button>
        <Button
          onClick={handleOk}
          type="primary"
          htmlType="submit"
          style={{
            padding: "8px, 16px, 8px, 16px",
            width: "116px",
            height: "40px",
          }}
        >
          {submitText}
        </Button>
      </div>
    </Modal>
  );
};
