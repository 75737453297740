
import { Form, message, ModalProps } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { memo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportActions, reporttSelector } from "store";
import { Wrapper } from "./Model.styles";

type Props = ModalProps & {
  setVisible: (value: boolean) => void;
  onDone: () => void;
  EmployeeID?: string;
  id: object;
  pred_date?: string;
};

type FormType = {};

const ModalComment = (props: Props) => {
  const [form] = Form.useForm<FormType>();
  const onOk = async () => form.submit();
  const dispatch = useDispatch<any>();
  const onCancel = () => onInvisible();
  const [focused, setFocused] = useState(false);

  const employee_id = props.EmployeeID;
  const pred_date = props.pred_date;
  const [showOk, setShowOk] = useState(true);

  const updatecomment = useSelector(reporttSelector.selectItem);

  const onInvisible = () => {
    props.setVisible(false);
    form.resetFields();
  };
  const hanldeChangeComment = (e: any) => {
    if (e.target.value !== "") {
      setShowOk(false);
    } else {
      setShowOk(true);
    }
  };
  const onFinish = async (inputData: any) => {
    const comment = inputData.Comment;

    const data = {
      employee_id: employee_id,
      pred_date: pred_date,
      comment: comment,
    };
    try {
      await new Promise((res) =>
        dispatch(
          reportActions.saveComment({
            params: data,
            onSuccess: () => {
              message.success("Save comment successfully");
              props.setVisible(false);
              props.onDone();
            },
            onError: () => {
              message.error("Save comment fail");
            },
          })
        )
      );
    } catch (error) {
      message.error("Save comment fail");
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" preserve={false}>
      <Wrapper
        {...props}
        centered
        destroyOnClose
        confirmLoading={updatecomment?.loading}
        onCancel={onCancel}
        onOk={onOk}
        width={500}
        okButtonProps={{ disabled: showOk }}
      >
        <Form.Item name="Comment">
          <TextArea
            rows={6}
            placeholder="Comment note"
            onChange={hanldeChangeComment}
            onFocus={onFocus}
          />
        </Form.Item>
      </Wrapper>
    </Form>
  );
};

export default memo(ModalComment);
