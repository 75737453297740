import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addRole,
  getUser,
  listUser,
  removeRole,
  removeUser,
  searchPermission,
  updateRole,
  updateUser,
} from "./actions";
import { State } from "./type";

const initialState: State = {
  list: {
    loading: false,
    result: [],
  },

  item: {
    loading: false,
    result: undefined,
  },
  read: {
    loading: false,
    result: undefined,
  },
  delete: {
    loading: false,
  },
  update: {
    loading: false,
  },
};

export const slice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addRole.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(addRole.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(addRole.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
    builder.addCase(listUser.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(listUser.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(listUser.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });

    builder.addCase(removeUser.pending, (state) => {
      state.delete.loading = true;
      // state.delete.result = undefined;
      state.delete.error = {};
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      state.delete.loading = false;
      // state.delete.result = action.payload;
      state.delete.error = {};
    });
    builder.addCase(removeUser.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = action.error;
      // state.delete.result = undefined;
    });

    builder.addCase(getUser.pending, (state) => {
      state.read.loading = true;
      state.read.result = initialState.read.result;
      state.read.error = {};
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.read.loading = false;
      state.read.result = action.payload;
      state.read.error = {};
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.read.loading = false;
      state.read.error = action.error;
      state.read.result = initialState.read.result;
    });
    builder.addCase(searchPermission.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(searchPermission.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(searchPermission.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.update.loading = true;
      // state.update.result = undefined;
      state.update.error = {};
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.update.loading = false;
      // state.update.result = action.payload;
      state.update.error = {};
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.update.loading = false;
      state.update.error = action.error;
      // state.update.result = undefined;
    });
  },
});

export const actions = {
  ...slice.actions,
  listUser,
  addRole,
  removeRole,
  removeUser,
  updateRole,
  getUser,
  searchPermission,
  updateUser,
};

export const { reducer } = slice;
