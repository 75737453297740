import no1Svg from "assets/svg/no1.svg";
import no2Svg from "assets/svg/no2.svg";
import no3Svg from "assets/svg/no3.svg";
import { Image } from "antd";
import * as S from "./styled";
import { ILeadership } from "types/leadership";
import { addComma } from "helpers/number";
import moment from "moment";
export const RankingComponent = ({
  data,
  tabType,
  selectedDate,
}: {
  data: ILeadership[];
  tabType?: string;
  selectedDate: string;
}) => {
  const handleChangeCampaign = (value: string) => {
    console.log(`selected ${value}`);
  };
  if (!data) return null;
  return (
    <S.RankingWrapper className="wrapper-ranking">
      <S.RankingSelectGroup>
        <div className="ranking-title">Leadership Board {tabType}</div>
        <div className="ranking-time">
          {selectedDate
            ? `${moment(selectedDate).format("MMMM")} ${moment().format("DD")}, ${moment(selectedDate).format("YYYY")}`
            : ""}
        </div>
        {/* <S.SelectCampaign
          defaultValue="Campaign 1"
          style={{ width: 120 }}
          onChange={() => handleChangeCampaign}
          options={[
            { value: "Campaign 1", label: "Campaign 1" },
            { value: "Campaign 2", label: "Campaign 2" },
            { value: "Campaign 3", label: "Campaign 3" },
          ]}
          size="large"
        /> */}
      </S.RankingSelectGroup>
      <S.RankingChartWrap>
        <S.RankingChartItemNo2>
          <Image src={no2Svg} preview={false} className="ranking-img" />
          <div className="ranking-name">Silver medal</div>
          {data?.[0]?.reward_price && (
            <div className="ranking-reward">
              {data?.[1]?.reward_price ? addComma(data?.[1]?.reward_price) : '200,000'} VND
            </div>
          )}
          <div className="ranking-BU">{data?.[1]?.department ? data?.[1]?.department :  ""}</div>
          {data?.[1]?.total_score && (
            <div className="ranking-score">
              Total score: {Math.round(data?.[1]?.total_score) || 0}/100
            </div>
          )}
        </S.RankingChartItemNo2>
        <S.RankingChartItemNo1>
          <Image src={no1Svg} preview={false} className="ranking-img" />
          <div className="ranking-name">Gold medal</div>
          {data?.[0]?.reward_price && (
            <div className="ranking-reward">
              {data?.[0]?.reward_price ? addComma(data?.[0]?.reward_price) : '300,000'} VND
            </div>
          )}
          <div className="ranking-BU">{data?.[0]?.department ? data?.[0]?.department :  ""}</div>
          {data?.[0]?.total_score && (
            <div className="ranking-score">
              Total score: {Math.round(data?.[0]?.total_score) || 0}/100
            </div>
          )}
        </S.RankingChartItemNo1>
        <S.RankingChartItemNo3>
          <Image src={no3Svg} preview={false} className="ranking-img" />
          <div className="ranking-name">Bronze medal</div>
          {data?.[0]?.reward_price && (
            <div className="ranking-reward">
              {data?.[2]?.reward_price ? addComma(data?.[2]?.reward_price) : '100,000'} VND
            </div>
          )}
          <div className="ranking-BU">{data?.[2]?.department ? data?.[2]?.department :  ""}</div>
          {data?.[2]?.total_score && (
            <div className="ranking-score">
              Total score: {Math.round(data?.[2]?.total_score) || 0}/100
            </div>
          )}
        </S.RankingChartItemNo3>
      </S.RankingChartWrap>
    </S.RankingWrapper>
  );
};
