import { createSlice } from "@reduxjs/toolkit";
import { sendEmail } from "./actions";

const initialState: any = {
  item: {
    loading: false,
    result: undefined,
  },
};

export const slice = createSlice({
  name: "send",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
  },
});

export const actions = {
  ...slice.actions,
  sendEmail,
};

export const { reducer } = slice;
