import { Select } from "antd";
import styled from "styled-components";
import StarBg from "../../../../assets/images/Star.png";
export const RankingWrapper = styled.div`
  height: 650px;
  width: 100%;
  margin-bottom: 24px;
  padding: 24px 24px 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: radial-gradient(
    92.83% 92.83% at 50% 66.43%,
    #6126cd 15.62%,
    #381281 55%,
    #280961 78.62%
  );

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${StarBg}), url(${StarBg});
    background-position:
      96px 76px,
      509px 418px;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;

export const RankingSelectGroup = styled.div`
  width: 410px;
  background: radial-gradient(
    73.4% 73.44% at 50% 26.56%,
    #faf7ff 0%,
    #cbb2ff 100%
  );
  border-radius: 24px;
  border: 3px solid #ffffff;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 24px;
  text-align: center;
  z-index: 2;


  .ranking-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #722ed1;
  }
  .ranking-time {
    font-weight: 700;
    font-size: 16;
    line-height: 22px;
    color: #262626;
    margin-bottom: 12px;
  }
`;

export const RankingChartWrap = styled.div`
  display: flex;
  width: 696px;
  gap: 18px;
  align-items: flex-end;
  justify-content: space-between;
`;

export const RankingChartItem = styled.div`
  display: flex;
  width: 220px;
  background: linear-gradient(180deg, #964bff 0%, #690fe7 100%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  .ranking-img {
    margin-bottom: 12px;
  }
  .ranking-name {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }
  .ranking-reward,
  .ranking-BU {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .ranking-BU {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-top: 16px;
  }
`;

export const RankingChartItemNo1 = styled(RankingChartItem)`
  height: 430px;
`;

export const RankingChartItemNo2 = styled(RankingChartItem)`
  height: 330px;
`;

export const RankingChartItemNo3 = styled(RankingChartItem)`
  height: 254px;
`;

export const SelectCampaign = styled(Select)`
  min-width: 170px;
  text-align: left;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 50px;
  }
`;
