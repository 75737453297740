import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state: any) => state.user;

export const selector = {
  selectProjectData: createSelector(selectSelf, (state) => state),
  selectList: createSelector(selectSelf, (state) => state.list),
  selectRead: createSelector(selectSelf, (state) => state.read),
  selectUpdate: createSelector(selectSelf, (state) => state.update),
  selectItem: createSelector(selectSelf, (state) => state.item),
};
