import React from "react";
import loadingImg from "assets/images/loading.gif";
import "components/Loading/Loading.css";
import { Spin } from "antd";

function Loading() {
  return (
    <div className="loading-global">
      <div className="loading">
        {/* <img src={loadingImg} alt="loading" /> */}
        <Spin size="large" />
      </div>
    </div>
  );
}

export default Loading;
