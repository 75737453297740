// export async function uploadCV(username: string, file: File) {
//   const endpoint = `https://cvmatch-indexer.azurewebsites.net/api/v1/backend/fileshare/cv/indexed/sync?username=${username}`;

import api from "api";
import { IBuddyInfo } from "types/buddy";
import { IBuddyHint, IChatBotBody, IChatInfor } from "types/chatbot";

//   const formData = new FormData();
//   formData.append("pdf_file", file);
//   const response = await api.caller.post(endpoint, formData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
//   console.log(response);

//   return response;
// }

export async function getBuddyMsg(): Promise<IChatInfor[]> {
  const endpoint = `https://happihr-backend.azurewebsites.net/messages/list`;

  const response = await api.caller.get(endpoint);

  const data = response?.data?.messages || [];

  return data as IChatInfor[];
}

export async function getBuddyNewMsg(): Promise<IChatInfor[]> {
  const endpoint = `https://happihr-backend.azurewebsites.net/messages/new`;

  const response = await api.caller.get(endpoint);

  const data = response?.data?.messages || [];

  return data as IChatInfor[];
}

export async function sendBuddyMsg(item: IChatBotBody) {
  const endpoint = `https://happihr-backend.azurewebsites.net/messages/send`;

  const response = await api.caller.post(endpoint, item);
  const data = response?.data?.message || [];

  return data as IChatInfor[];
}

export async function reactionBuddyMsg(item: {
  id: string;
  reaction: string;
}) {
  const endpoint =
    "https://happihr-backend.azurewebsites.net/messages/reaction";

  const response = await api.caller.post(endpoint, item);
  const data = response.data;

  return data as { status: string };
}

export async function hintsBuddy() {
  const endpoint =
    "https://happihr-buddy.azurewebsites.net/buddy/hints";

  const response = await api.caller.get(endpoint);
  const data = response.data;

  return data as { message: IBuddyHint[] };
}


