import React, { useEffect, useRef, useState } from "react";

import {
  Col,
  DatePicker,
  Button,
  Breadcrumb,
  Form,
  message,
  TreeSelect,
  Table,
  Menu,
  Checkbox,
  Input,
  Dropdown,
  Affix,
  Spin,
  Empty,
} from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import { useDispatch, useSelector } from "react-redux";

import {
  reportActions,
  reporttSelector,
  sendActions,
  sendSelector,
} from "store";

import ModalComment from "../ModelComment/ModelComment";
import { DropUpSvg, ExportSvg, NotifiedSvg } from "assets/svg";
import ModelEdit from "../ModelEdit/ModelEdit";
import "./TableHrpr.scss";
import confirm from "antd/lib/modal/confirm";
import { datahrpr } from "./config";
import ModelFeedback from "../ModelFeedback/ModelFeedback";
import ExportDataExelHR from "../ExportExcel/ExportExcelHrpr";
import ExportCSVHrpr from "../ExportCSV/ExportCSVHrpr";
import { Link } from "react-router-dom";
import type { ColumnsType } from 'antd/es/table';


interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const columns_test: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
];

const data_test: DataType[] = [];
for (let i = 0; i < 46; i++) {
  data_test.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}

const TableHrpr = () => {
  const useId = () => {
    const idRef = useRef(0);
    idRef.current += 1;
    return idRef.current;
  };
  const uniqueId = useId();

  const dispatch = useDispatch()<any>;
  const dateFormat = "YYYY/MM/DD";
  const navigate = useNavigate();
  const roleid = sessionStorage.getItem("roles");
  const roles = roleid;
  const check = roles === "ADMIN";
  const [departments, setDepartments] = useState<any>([""]);
  const [inputComment, setInputComment] = useState({} as any);
  const [inputComments, setInputComments] = useState({} as any);
  const listReport = useSelector(reporttSelector.selectList);
  const dataReport = listReport?.result;
  const [valueSearch, setValueSearch] = useState<any>();
  const [dataReportchurn, setDataReportChurn] = useState(dataReport);

  const [changeData, setChangeData] = useState([]);
  const [form] = Form.useForm();
  const [churn, setChurn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const lstDeparment = useSelector(reporttSelector.selectLst);

  const converTreeData = lstDeparment?.result;
  const [selectAll, setSelectAll] = useState(false);
  const treeData = converTreeData.slice(1);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [activeCancel, setActiveCancel] = useState(false);
  const [dataselectkey, setDataSlectRowKey] = useState([]);

  const [isselectAll, setIsSelectAll] = useState<any>(false);
  

  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [activeChurn, setActiveChurn] = useState(false);
  const sendEmailState = useSelector(sendSelector.selectItem);
  let dates = new Date();
  const datesNow = moment(dates).format("YYYY/MM/DD");
  const fromData = useSelector(reporttSelector.selectdate);

  const fromDataDate = fromData?.result?.from_date;
  const [indexComment, setIndexComment] = useState();
  const [modelFeedback, setModelFeedback] = useState(false);
  const hasSelected = selectedRowKeys.length > 0;
  const [filters, setFilters] = useState<any>();
  const [data, setData] = useState({
    pred_date_from: fromDataDate,
    pred_date_to: datesNow,
    department: "",
    account_query: "",
  });


  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRow: any
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDataSlectRowKey(selectedRow);
  };

  const rowSelection: any = {
    selectedRowKeys,

    onChange: onSelectChange,

    onSelectAll: (selected: any) => {
      
      setIsSelectAll(selected);
    },
  };


  const onHanldeCommnet = (row: any) => {
    setInputComment(row);
    setShowModal(true);
  };
  const onHanldeCommnets = (row: any) => {
    const userCom = row?.Comment?.map((o: any) => o?.user_email);
    const userLogin = sessionStorage.getItem("UserEmail");
    const index = userCom?.indexOf(userLogin);
    setInputComments(row);
    setIndexComment(row?.Comment?.[index]?.content);
    setShowModals(true);
  };

  const menuActions = (record: any) => {
    return (
      <Menu>
        <Menu.Item key={1}>
          <Button type="text" onClick={() => onHanldeCommnets(record)}>
            Edit
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const menuActionsNone = (record: any) => {
    return (
      <Menu>
        <Menu.Item key={2}>
          <Button type="text" onClick={() => onHanldeCommnet(record)}>
            Comment
          </Button>
        </Menu.Item>
      </Menu>
    );
  };
  const datanotify = {
    employee_id: dataselectkey?.map((o: any) => o?.EmployeeID),
    pred_date: dataselectkey?.map((o: any) => o?.PredictionDate),
  };


  const handleFilterChange = (filters: any, extra: any) => {
    setFilters(filters);
  };
  useEffect(() => {
    let newData = dataReport;
    if (filters?.NotifyStatus) {
      newData = newData?.filter((item: any) =>
        filters?.NotifyStatus?.includes(item?.NotifyStatus)
      );
    }
    if (filters?.Bul_Known) {
      newData = newData?.filter((item: any) =>
        filters?.Bul_Known?.includes(item?.Bul_Known)
      );
    }
    if (filters?.ChurnIntention) {
      newData = newData?.filter((item: any) =>
        filters?.ChurnIntention?.includes(item?.ChurnIntention)
      );
    }
    setChangeData(newData);
    const dataTable = newData?.map((item: any, index: any) => {
      return (
        {
          ...item,
          index: index + 1
        }
      )
    })
    setDataReportChurn(dataTable);
  }, [filters]);

  const hanldeChangeNotified = async (record: any, value: any) => {
    const datanotified = {
      employee_id: record?.EmployeeID,
      pred_date: record?.PredictionDate,
      notify_status: value.toLowerCase(),
    };

    confirm({
      className: "table-confirm",
      title: "Are you sure you want Update?",

      okType: "primary",
      async onOk() {
        try {
          await new Promise((res) =>
            dispatch(
              reportActions.updateNotified({
                params: datanotified,
                onSuccess: res,
                onError: (res: any) => {
                  message.error(`${res.message}`);
                },
              })
            )
          );

          setActiveChurn(true);
          message.success("Update notified successfully");
          setActiveChurn(false);
        } catch (error) {
          message.error("Update notified fail");
        }
      },
      centered: true,
      okText: "OK",
      cancelText: "Cancel",
      okButtonProps: {
        className: "custom-ok-btn",
      },
      cancelButtonProps: {
        className: "custom-cancel-btn",
      },
      onCancel() {
        setActiveCancel(!activeCancel);
      },
    });
  };
  const hanldeClickFeedback = () => {
    setModelFeedback(!modelFeedback);
  };
  const start = () => {
    datanotify &&
      dispatch(
        sendActions.sendEmail({
          params: datanotify,
          onSuccess(param) {
            message.success("Send email successfully");
            setIsSend(!isSend);
          },
          onError(param) {
            message.error(`${param.message}`);
            setIsSend(!isSend);
          },
        })
      );
    setIsSelectAll(false);

    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 2000);
  };
  const getAllKeys = (data: any[]) => {
    let keys = [] as any;
    data.forEach((item) => {
      keys.push(item.key);
      if (item.children) {
        keys = [...keys, ...getAllKeys(item.children)];
      }
    });
    return keys;
  };
  const allIds = treeData?.map(({ value }: any) => value);

  const handleSelectAll = (e: any) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      const allKeys = getAllKeys(treeData) as any;

      setSelectedValues(allKeys) as any;
      setDepartments([""]);
    } else {
      setSelectedValues([]);
      setDepartments([""]);
    }
  };
  const getParentKeys = (data: any[]) => {
    let keys = [] as any;
    data.forEach((item) => {
      keys.push(item.key);
    });
    return keys;
  };

  // const allKeysData = getAllKeys(treeData) as any;
  const PrerentKey = getParentKeys(treeData) as any;
  const onChange = (valueChange: any, label: any, extra: any) => {
    const isDeparment = JSON.stringify(valueChange);

    setDepartments(isDeparment);
    setData({ ...data, department: isDeparment });
    setSelectedValues(valueChange);

    if (
      valueChange?.length === PrerentKey?.length &&
      valueChange?.every((val: any) => PrerentKey.includes(val))
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const customDataHrpr = changeData?.map((item: any) => {
    return {
      Source: item?.Source,
      Account: item?.EmployeeID,
      Department: `${item?.Features?.fsu} ${item?.Features?.bul}`,
      Hire_Date: `${item?.Features?.hire_date}`,

      Age: `${item?.Features?.age}`,
      Skill: `${item?.Features?.skill}`,
      JobCode: `${item?.Features?.job_code}`,
      PredictionDate: item?.PredictionDate,

      Confident: Number(item?.Features?.churn_score).toFixed(2),

      Churn_Reason: `${item?.Text_Explanation?.map(
        (o: any) => o?.content
      )?.join(",")}`,
      BUL_Confirm: item?.ChurnIntention,
      Known_Churned_in_Advance: item?.Bul_Known,
      Notified: item?.NotifyStatus,
      Note: `${item?.Comment?.map(
        (o: any) => `${o?.user_email?.split("@")[0]}: ${o?.content}`
      )?.join("~")}`,
    };
  });

  useEffect(() => {
    dispatch(reportActions.getFromDate({}));
  }, []);

  useEffect(() => {
    if (fromDataDate) {
      setData({
        ...data,
        pred_date_from: fromDataDate,
        pred_date_to: datesNow,
      });
    }
  }, [fromDataDate]);

  const getData = async () => {
    await new Promise((res: any) => {
      dispatch(
        reportActions.fetchReport({
          params: data,
          onSuccess: res,
          onError: (res: any) => {
            message.error(`${res.message}`);
          },
        }) as any
      );
    });
  };
  useEffect(() => {
    if (roles && !check && fromData && fromDataDate) {
      getData();
    }
  }, [
    isSend,
    churn,
    departments,
    activeChurn,
    activeCancel,
    valueSearch,
    data,
  ]);

  const hanleSearch = async (value: string) => {
    setData({ ...data, account_query: value });
  };

  const onChangeStartDate = (valueDateStart: any, dataString: string) => {
    if (dataString?.length === 0) {
      setData({ ...data, pred_date_from: "" });
    } else {
      setData({ ...data, pred_date_from: dataString });
    }
  };
  const onChangeEndDate = (valueDateEnd: any, dataString: any) => {
    if (dataString?.length === 0) {
      setData({ ...data, pred_date_to: "" });
    } else {
      setData({ ...data, pred_date_to: dataString });
    }
  };

  const MenuExport = () => {
    return (
      <Menu>
        <Menu.Item>
          <ExportCSVHrpr dataExport={customDataHrpr} />
        </Menu.Item>

        <Menu.Item>
          <ExportDataExelHR dataExport={customDataHrpr} />
        </Menu.Item>
      </Menu>
    );
  };
  useEffect(() => {
    if (isselectAll === false) {
      setSelectedRowKeys([]);

    }
    if (isselectAll === true) {
      setSelectedRowKeys(
        dataReportchurn?.map((o: any) => {
          return o.ID;
        })
      );
      setDataSlectRowKey(dataReportchurn);
    }
  }, [isselectAll]);

  useEffect(() => {
    if (valueSearch === 0) {
      getData();
    }
  }, [valueSearch]);
  useEffect(() => {
    if (!roleid) {
      navigate("/no_role");
    }
  }, []);

  useEffect(() => {
    if (!check && roles) {
      dispatch(reportActions.listdeparment({}));
    }
  }, []);

  useEffect(() => {
    let newData = dataReport;

    if (filters?.NotifyStatus) {
      newData = newData?.filter((item: any) =>
        filters?.NotifyStatus?.includes(item?.NotifyStatus)
      );
    }
    if (filters?.Bul_Known) {
      newData = newData?.filter((item: any) =>
        filters?.Bul_Known?.includes(item?.Bul_Known)
      );
    }
    if (filters?.ChurnIntention) {
      newData = newData?.filter((item: any) =>
        filters?.ChurnIntention?.includes(item?.ChurnIntention)
      );
    }
    setChangeData(newData);
  }, [dataReport, activeChurn]);

  useEffect(() => {
    dataReport ? setDataReportChurn(dataReport) : setDataReportChurn([]);
  }, [listReport]);

  const [ischeck, setIsCheck] = useState(false);
  useEffect(() => {
    setIsCheck(true)
  }, [])
  const columns: any = [
    {
      title: 'Full Name',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Age',
      width: 100,
      dataIndex: 'age',
      key: 'age',
      fixed: 'left',
      sorter: true,
    },
    { title: 'Column 1', dataIndex: 'address', key: '1', width: 100, },
    { title: 'Column 2', dataIndex: 'address', key: '2', width: 100, },
    { title: 'Column 3', dataIndex: 'address', key: '3', width: 100, },
    { title: 'Column 4', dataIndex: 'address', key: '4', width: 100, },
    { title: 'Column 5', dataIndex: 'address', key: '5', width: 100, },
    { title: 'Column 6', dataIndex: 'address', key: '6', width: 100, },
    { title: 'Column 7', dataIndex: 'address', key: '7', width: 100, },
    { title: 'Column 8', dataIndex: 'address', key: '8', width: 100, },
    { title: 'Column 8', dataIndex: 'address', key: '8', width: 100, },
    { title: 'Column 8', dataIndex: 'address', key: '9', width: 100, },
    { title: 'Column 8', dataIndex: 'address', key: '10', width: 100, },
    { title: 'Column 8', dataIndex: 'address', key: '10', width: 100, },
    { title: 'Column 8', dataIndex: 'address', key: '12', width: 100, },

    { title: 'Column 8', dataIndex: 'address', key: '13', width: 100, },
    { title: 'Column 8', dataIndex: 'address', key: '14', width: 100, },
    { title: 'Column 8', dataIndex: 'address', key: '14', width: 100, },

    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: () => <a>action</a>,
    },
  ];

  const dataTest: any = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York Park',
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
    },
  ];

  return (
    <>
      <div className="container-bracrum">
        <S.ControlBracrum>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span style={{ fontSize: 16 }}>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="">
              <span style={{ fontSize: 16 }}> Churn Prediction</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </S.ControlBracrum>
      </div>
      <div className="wrapper_container">
        <div>
          <>
            <TreeSelect
              onChange={onChange}
              allowClear={true}
              placeholder="Choose Department"
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              style={{
                minWidth: "15%",
                maxWidth: "35%",
                borderRadius: 4,
              }}
              size="large"
              dropdownStyle={{
                maxHeight: 300,
                overflow: "auto",
                minWidth: 200,
              }}
              showSearch
              value={selectedValues}
              maxTagCount={2}
              maxTagPlaceholder={(omittedValues) =>
                `+ ${omittedValues.length} Deparments ...`
              }
              treeData={[
                {
                  className: "hide-checkbox",
                  title: (
                    <div className="checkbox">
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        style={{
                          color: "#5440B8",
                          cursor: "pointer",
                          marginLeft: "-3px",
                        }}
                      >
                        <span className="span_select">SELECT ALL</span>
                      </Checkbox>
                    </div>
                  ),
                  value: "xxx",
                  disableCheckbox: true,
                  disabled: true,
                },
                ...treeData,
              ]}
            />
          </>
          <DatePicker
            key={fromDataDate}
            size="large"
            style={{ marginLeft: 20, width: 130, borderRadius: 4 }}
            onChange={onChangeStartDate}
            placeholder="Start Date"
            defaultValue={
              fromDataDate ? moment(fromDataDate, dateFormat) : undefined
            }
            format={dateFormat}
          />
          <DatePicker
            key={dateFormat}
            size="large"
            style={{ marginLeft: 20, width: 130, borderRadius: 4 }}
            onChange={onChangeEndDate}
            placeholder="End Date"
            defaultValue={moment(dates, dateFormat)}
            format={dateFormat}
          />
          <Button
            icon={<UploadOutlined />}
            size="large"
            style={{ marginLeft: 20, color: "#5440B8" }}
            type="text"
          >
            <Link to="/tracking/upload">Upload</Link>
          </Button>
        </div>
        <div>
          <Search
            className="style_search"
            placeholder="Search Account"
            onSearch={(value: any) => hanleSearch(value)}
            style={{ width: 250 }}
            size="large"
            allowClear
            enterButton
          />
        </div>
      </div>
      {lstDeparment?.loading || fromData?.loading ? (
        <div className="style_loading">
          <Spin size="large" />
        </div>
      ) : (

        <Col>
          <Form form={form} component={false}>

            <S.containerTable>
              <Table
                rowKey={(row: any) => row?.ID}
                rowSelection={rowSelection}
                columns={datahrpr({
                  parentCallback: { hanldeChangeNotified },
                  menuActions: { menuActions },
                  menuActionsNone: { menuActionsNone },
                  keyId: { uniqueId },
                })}

                dataSource={dataReportchurn}
                loading={listReport?.loading || sendEmailState.loading}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
                locale={{
                  emptyText: (
                    <Empty
                      description="No results found"
                      className="custom-empty"
                    />
                  ),
                }}
                size="small"
                tableLayout="fixed"
                style={{ width: "100%", height: "100%" }}
                scroll={!(listReport?.loading) ? { x: 1600 } : undefined}
                onChange={(filters, extra) => {
                  handleFilterChange(filters, extra);
                }}

              />


              <div className="style-notified">
                <S.PostionBtn>
                  {dataReportchurn?.length === 0 ? (
                    ""
                  ) : (
                    <>
                      <Dropdown overlay={MenuExport} trigger={["hover"]}>
                        <S.ButtonExport
                          icon={<ExportSvg style={{ marginRight: 10 }} />}
                        >
                          Export
                        </S.ButtonExport>
                      </Dropdown>
                    </>
                  )}

                  <S.ButtonSubmit
                    onClick={start}
                    disabled={!hasSelected}
                    htmlType={"submit"}
                    loading={loading}
                    icon={<NotifiedSvg style={{ marginRight: 10 }} />}
                  >
                    Notify
                  </S.ButtonSubmit>
                </S.PostionBtn>
              </div>
            </S.containerTable>
          </Form>
        </Col>
      )}

      {
        <ModalComment
          open={showModal}
          setVisible={setShowModal}
          EmployeeID={inputComment?.EmployeeID}
          pred_date={inputComment?.PredictionDate}
          onDone={getData}
          id={(o: any) => o.ID}
        />
      }
      {
        <ModelEdit
          open={showModals}
          setVisible={setShowModals}
          EmployeeID={inputComments?.EmployeeID}
          inputComments={indexComment}
          pred_date={inputComments?.PredictionDate}
          onDone={getData}
          id={(o: any) => o.ID}
        />
      }
      {/* {modelFeedback ? (
        <ModelFeedback isModel={setModelFeedback} />
      ) : (
        <Affix offsetBottom={70}>
          <div className="custom_feedbackhrpr">
            <div className="feedback_childhrpr">
              <S.ButtonFeedback
                type="text"
                icon={<DropUpSvg className="css_svghrpr" />}
                onClick={hanldeClickFeedback}
              >
                Feedback
              </S.ButtonFeedback>
            </div>
          </div>
        </Affix>
      )} */}
    </>
  );
};

export default TableHrpr;
