import { Tabs } from "antd";
import * as S from "./styled";
import { CvManagement } from "./components/CvManagement";
import { useState } from "react";
import { Chatbot } from "./components/Chatbot";
import { RatingModal } from "features/Chatbot/RatingModal/RatingModal";

export const CVMatchMaker = () => {
  const [isOpenRatingModal, setOpenRatingModal] = useState<boolean>(false);

  const tabItem = [
    {
      label: `Chatbot`,
      key: "1",
      children: <Chatbot 
      setOpenRatingModal={setOpenRatingModal}/>,
    },
    {
      label: `CV Management`,
      key: "2",
      children: <CvManagement />,
    },
  ];

  return (
    <S.Wrapper>
      <h2>CV Match Maker</h2>
      <Tabs
        defaultActiveKey="1"
        destroyInactiveTabPane={true}
        items={tabItem}
      />
      {isOpenRatingModal && (
        <RatingModal
          isOpenModal={isOpenRatingModal}
          onClose={() => {
            setOpenRatingModal(false);
          }}
        />
      )}
    </S.Wrapper>
  );
};
