import chatbotLogo from "assets/images/chatbot-logo.png";
import faceLogo from "assets/images/face-logo.png";
import happiLogo from "assets/images/happi-logo.png";
import menuIcon from "assets/images/menu.png";
import sendIcon from "assets/images/send-icon.png";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Upload, UploadProps } from "antd";
import { getChatbotMsg, readMsg, sendMsg } from "services/chatbot";
import {
  IBuddyHint,
  IChatBotBody,
  IChatInfor,
  IReadMsgRequest,
} from "types/chatbot";
import { isArray } from "lodash";
import { IconStarPurple } from "assets/svg";
import dayjs from "dayjs";
import {
  ChatBotBody,
  ChatBotContentWrap,
  ChatBotFooter,
  ChatBotHeader,
  ChatBotMessageReceive,
  ChatBotWrap,
  TypingMsg,
} from "./styled";
import { ChatContent } from "features/Chatbot/WindowChat/components/ChatContent";
import {
  FolderOpenOutlined,
  UploadOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { dummyRequest } from "features/Chatbot/MessageTab/MessageSettings/components/Schedule";
import {
  getBuddyMsg,
  getBuddyNewMsg,
  hintsBuddy,
  sendBuddyMsg,
} from "services/buddy";
import { useNavigate } from "react-router-dom";

interface Props {
  setOpenRatingModal: (isOpen: boolean) => void;
}

const OTPInput: React.FC = () => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value) && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
    >
      {Array(6)
        .fill(null)
        .map((_, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            style={{
              width: "40px",
              height: "40px",
              margin: "0 5px",
              textAlign: "center",
              fontSize: "24px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            ref={(el) => (inputRefs.current[index] = el)}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              input.value = input.value.replace(/[^0-9]/g, "");
            }}
          />
        ))}
    </div>
  );
};

export const Chatbot = (props: Props) => {
  const { setOpenRatingModal } = props;
  const [form] = Form.useForm();
  const [isOpenSuggestion, setIsOpenSuggestion] = useState<boolean>(true);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMsg, setIsLoadingMsg] = useState(false);
  const [listOldChat, setListOldChat] = useState<IChatInfor[]>([]);
  const listOldChatRef = useRef<IChatInfor[]>([]);
  const listChatRef = useRef<IChatInfor[]>([]);
  const hasNewMsg = useRef<boolean>(false);
  const [suggestionHeight, setSuggestionHeight] = useState(0)
  const [listChat, setListChat] = useState<IChatInfor[]>([
    {
      content_message: `Chào bạn! Mình là Happi Buddy, rất vui được gặp bạn! 😊🎉 Nếu bạn có bất kỳ câu hỏi nào liên quan đến hỗ trợ IT hoặc logistics, mình rất sẵn lòng hỗ trợ kết nối bạn đến các liên kết hoặc người liên quan để hỗ trợ.`,
      from: "happi-bot",
    },
  ]);
  const [listHints, setListHints] = useState<IBuddyHint[]>([]);
  let timer: any;
  const isLoadingCheckIsRead = useRef<boolean>(false);
  const userLogin = sessionStorage.getItem("UserEmail");
  const currenDeparment = sessionStorage.getItem("currendepar");
  const suggestionRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currenDeparment?.includes("AIC")) {
      navigate("/");
      return;
    }
    getListMessage();
    getHints();
    scrollIntoView();
    handleIsReadMsg();
  }, []);

  const scrollIntoView = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const handleScroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleIsReadMsg();
    }, 500);
  };

  useEffect(() => {
    listChatRef.current = [...listChat];
  }, [listChat]);

  const getListMessage = async () => {
    try {
      setIsLoading(true);
      let data = await getBuddyMsg(); /* list_message, new_message */
      if (isArray(data) && data?.length === 0) return;
      hasNewMsg.current = data?.some((item) => item.is_read === "no");
      setListOldChat((pre) => [...pre, ...data]);
      listOldChatRef.current = data;
      scrollIntoView();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getHints = async () => {
    try {
      const res = await hintsBuddy();
      setListHints(res.message);
      console.log("res", res);
    } catch (error) {}
  };

  const handleSelectSuggestion = (hint: string) => {
    setSelectedSuggestion(hint);
    setListChat((pre) => [
      ...pre,
      {
        from: userLogin || "",
        content_message: hint,
      },
    ]);
    scrollIntoView();
    setIsLoadingMsg(true);
    setTimeout(() => {
      setIsLoadingMsg(false);
      setListChat((pre) => [
        ...pre,
        {
          from: "happi-bot",
          content_message: listHints.find((item) => item.Q === hint)?.A || "",
        },
      ]);
    }, 1000);
    setIsOpenSuggestion(false);
  };

  const openSuggestion = () => {
    setSelectedSuggestion("");
    setIsOpenSuggestion((prev) => !prev);
  };

  useEffect(() => {
    console.log((suggestionRef.current as HTMLDivElement)?.clientHeight)
    setSuggestionHeight((suggestionRef.current as HTMLDivElement)?.clientHeight - 11 || 0);
  }, [isOpenSuggestion])

  const onFinish = (values: any) => {
    if (!values?.msgContent || isLoadingMsg) return;
    setListChat((pre) => [
      ...pre,
      {
        from: userLogin || "",
        content_message: values.msgContent,
        is_read: "no",
      },
    ]);
    form.setFieldValue("msgContent", "");
    scrollIntoView();
    handleSendMsg(values.msgContent);
  };

  const handleSendMsg = async (msg: string) => {
    setIsLoadingMsg(true);
    try {
      const data: IChatBotBody = {
        // request_type: "happi_send_message",
        message: msg,
      };
      scrollIntoView();
      const res = await sendBuddyMsg(data);
      if (res.length > 0) {
        hasNewMsg.current = true;
        handleIsReadMsg(res[res.length - 1].id);
        setListChat((pre) => [...pre, ...res]);
      }
      setIsLoadingMsg(false);
      scrollIntoView();
    } catch (error) {
      console.log(error);
      // if (listChat.length > 0) {
      //   setListChat((pre) => {
      //     pre[pre.length - 1].isError = true;
      //     return pre;
      //   });
      // }
      setIsLoadingMsg(false);
    }
  };

  const handleIsReadMsg = async (id?: string) => {
    if (!hasNewMsg.current || isLoadingCheckIsRead.current) return;
    try {
      isLoadingCheckIsRead.current = true;
      let tempId = id;
      if (!id) {
        if (listOldChat.length > 0) {
          tempId =
            listOldChat[listOldChat.length - 1].is_read === "no"
              ? listOldChat[listOldChat.length - 1].id
              : undefined;
        }
        if (listChat.length > 1) {
          tempId =
            listChat[listChat.length - 1].is_read === "no"
              ? listChat[listChat.length - 1].id
              : undefined;
        }
      }
      if (!tempId) return;
      const data: IReadMsgRequest = {
        request_type: "happi_latest_message_read",
        timestamp: dayjs().unix(),
      };
      const res = await readMsg(data);
      hasNewMsg.current = res.status === "complete";
      isLoadingCheckIsRead.current = false;
    } catch (error) {
      console.log(error);
      isLoadingCheckIsRead.current = false;
    }
  };

  useEffect(() => {
    scrollIntoView();
  }, [listChat]);

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    console.log(newFileList);
  };

  const divStyle = {
    height: `calc(100vh - ${suggestionHeight}px - 295px)`,
  };

  return (
    <ChatBotWrap>
      {/* <OTPInput /> */}
      <ChatBotHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "12px" }}>
            <img
              src={chatbotLogo}
              alt="chatbot logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div>
            <img src={happiLogo} alt="happi logo" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setOpenRatingModal(true)}
          >
            <IconStarPurple />
          </div>
        </div>
      </ChatBotHeader>
      <ChatBotBody
        onClick={() => handleIsReadMsg()}
        onScroll={handleScroll}
        style={divStyle}
      >
        <ChatBotContentWrap>
          <ChatContent listMsg={listOldChat} isMarkdownContent={true} />
          <ChatContent listMsg={listChat} isMarkdownContent={true} />
          {isLoadingMsg && (
            <ChatBotMessageReceive>
              <div style={{ width: "32px" }}>
                <img
                  src={chatbotLogo}
                  alt="chatbot logo"
                  width={"32px"}
                  height={"32px"}
                />
              </div>
              <div className="content">
                <TypingMsg>
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </TypingMsg>
              </div>
            </ChatBotMessageReceive>
          )}
          <div ref={messagesEndRef} />
        </ChatBotContentWrap>
      </ChatBotBody>
      <ChatBotFooter onClick={() => handleIsReadMsg()}>
        {isOpenSuggestion && (
          <div className="message-options" ref={suggestionRef}>
            {listHints.map((item) => (
              <div
                key={item.Q}
                className={`message ${selectedSuggestion === item.Q && "active"}`}
                onClick={() => handleSelectSuggestion(item.Q)}
              >
                {item.Q}
              </div>
            ))}
          </div>
        )}
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img
            src={menuIcon}
            style={{ cursor: "pointer" }}
            onClick={openSuggestion}
          />
          <Form
            form={form}
            onFinish={onFinish}
            style={{ display: "flex", width: "100%" }}
          >
            <Form.Item
              name="msgContent"
              rules={[{ required: true, message: "" }]}
              style={{ flex: 1 }}
            >
              <Input placeholder="Type Message..." />
            </Form.Item>
            <Form.Item>
              <button type="submit">
                <img src={sendIcon} style={{ cursor: "pointer" }} />
              </button>
            </Form.Item>
          </Form>
        </div>
      </ChatBotFooter>
    </ChatBotWrap>
  );
};
