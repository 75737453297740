import * as S from "./styled";
import { useState } from "react";
import { Chatbot } from "./components/Chatbot";
import { RatingModal } from "features/Chatbot/RatingModal/RatingModal";

export const HappiBuddy = () => {
  const [isOpenRatingModal, setOpenRatingModal] = useState<boolean>(false);

  return (
    <S.Wrapper>
      <h2 style={{marginBottom: "40px"}}>Happi Buddy</h2>
      <Chatbot setOpenRatingModal={setOpenRatingModal}/>
      {isOpenRatingModal && (
        <RatingModal
          isOpenModal={isOpenRatingModal}
          onClose={() => {
            setOpenRatingModal(false);
          }}
        />
      )}
    </S.Wrapper>
  );
};
