import { IconAICenter, IconDownload, LogoHappi } from "assets/svg";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Select,
  Space,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { FullAnalysis } from "../components/FullAnalysis";
import {
  getChat2Insight,
} from "services/chatToInsight";
import { IChartItem, IChatToInsightParam } from "types/chatToInsight";
import dayjs from "dayjs";
import { Responsive, WidthProvider } from "react-grid-layout";
import { MenuAction } from "../components/InforgraphicList/styled";
import {
  ChartItem,
  ChartWrapper,
  CustomText,
  HappiTitle,
  HappiWrapper,
} from "../styled";
import moment from "moment";
import { ChatbotWindow } from "../Inforgraphic Management/ChatbotWindow";
import saveAs from 'file-saver';
import * as htmlToImage from 'html-to-image';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const Inforgraphics = ({isUpdate}: {isUpdate: number}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [publishDate, setPublishDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [layouts, setLayout] = useState<IChartItem[]>([]);
  const [params, setParams] = useState<IChatToInsightParam>({status: "published"});
  const [selectedItem, setSelectedItem] = useState<IChartItem>();
  const [selectedDate, setSeletedDate] = useState<string>();
  const [selectedCampaign, setSeletedCampaign] = useState<string>();
  const [listOptions, setListOptions] =
    useState<{ value: string; label: string }[]>();
  const pdfArea = useRef<HTMLDivElement | null>(null);

  const handleGeneratePdf = async () => {
    const img = await htmlToImage.toBlob(pdfArea.current as HTMLDivElement);
    saveAs(img as Blob, `${title}.png`)
  };

  const getChat2InsightList = async () => {
    try {
      const res = await getChat2Insight(params);
      if (res.length > 0) {
        setSeletedDate(res[0].publish_date || undefined);
        const data = res.map((item) => {
          return { value: item.id || "", label: item.name };
        });
        setListOptions(data);
        setSeletedCampaign(res[0].id);
      } else {
        setSeletedCampaign(undefined);
        setListOptions([]);
      }
    } catch (error) {
      setListOptions([]);
      console.log(error);
    }
  };

  useEffect(() => {
    getChat2InsightList();
  }, [params, isUpdate]);

  const getChat2InsightDetail = async () => {
    try {
      const res = await getChat2Insight({
        campaign_id: selectedCampaign || undefined,
      });
      if (res.length > 0) {
        setLayout(res[0].charts);
        setTitle(res[0].name);
        setStatus(res[0].status);
        setPublishDate(res[0].publish_date || "");
      }
    } catch (error) {
      setLayout([]);
    }
  };

  useEffect(() => {
    if (selectedCampaign) {
      getChat2InsightDetail();
    } else {
      setLayout([]);
      setTitle("");
    }
  }, [selectedCampaign]);

  const showModal = (item: IChartItem) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const onChangeMonth: DatePickerProps["onChange"] = (date, dateString) => {
    setSeletedDate(dateString || undefined);
    setParams({
      ...params,
      month: dateString,
    });
  };
  const handleChangeSelect = (value: string) => {
    setSeletedCampaign(value);
  };

  return (
    <div
      style={{ display: "flex", gap: "20px", padding: "20px 24px 20px 24px" }}
    >
      <div style={{ width: "60%" }}>
        <MenuAction style={{ marginBottom: "20px" }}>
          <Space direction="horizontal" style={{ width: "568px" }}>
            <DatePicker
              onChange={onChangeMonth}
              picker="month"
              size="large"
              style={{ width: 120 }}
              value={selectedDate ? moment(selectedDate) : undefined}
            />
            <Select
              size="large"
              style={{ width: 120 }}
              onChange={handleChangeSelect}
              options={listOptions}
              value={selectedCampaign}
            />
          </Space>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 28px",
              }}
              onClick={handleGeneratePdf}
              disabled={!title}
            >
              <span style={{ marginRight: "16px", height: "14px" }}>
                <IconDownload />
              </span>
              <span>Download</span>
            </Button>
          </div>
        </MenuAction>
        {title && (
          <div style={{ width: "100%", paddingBottom: "100px", background: "#fff" }} ref={pdfArea}>
            <HappiWrapper>
              <div>
                <IconAICenter />
              </div>

              <HappiTitle>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "24px",
                    color: "#531DAB",
                  }}
                >
                  {title}
                </div>
                {status === "published" && (
                  <div>Published {dayjs(publishDate).format("DD/MM/YYYY")}</div>
                )}
              </HappiTitle>
              <div>
                <span>
                  <LogoHappi />
                </span>
              </div>
            </HappiWrapper>
            <ChartWrapper>
              <ResponsiveGridLayout
                isDraggable={false}
                isResizable={false}
                preventCollision={false}
                breakpoints={{
                  lg: 1200,
                  md: 996,
                  sm: 768,
                  xs: 480,
                  xxs: 0,
                }}
                cols={{ lg: 8, md: 8, sm: 8, xs: 8, xxs: 4 }}
                compactType="horizontal"
                style={{ minHeight: "200px", width: "100%" }}
                className="hide-resize-icon"
              >
                {layouts.map((item) => (
                  <ChartItem key={item.i} data-grid={item}>
                    <div>
                      <img
                        src={item.image}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div style={{ fontWeight: 500, fontSize: "24px", wordBreak: "break-word" }}>
                      {item.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center ",
                      }}
                    >
                      <div>
                        <CustomText
                          style={{ cursor: "pointer" }}
                          onClick={() => showModal(item)}
                        >
                          View full analysis
                        </CustomText>
                      </div>
                    </div>
                  </ChartItem>
                ))}
              </ResponsiveGridLayout>
            </ChartWrapper>
          </div>
        )}
      </div>

      <ChatbotWindow />
      {selectedItem && (
        <FullAnalysis
          selectedItem={selectedItem}
          isOpenModal={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
};
