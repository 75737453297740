import { createSelector } from "@reduxjs/toolkit";
import { State } from "./type";

const selectSelf = (state: any) => state.report;

export const selector = {
  selectProjectData: createSelector(selectSelf, (state) => state),
  selectList: createSelector(selectSelf, (state) => state.list),
  selectItem: createSelector(selectSelf, (state) => state.item),
  selectLst: createSelector(selectSelf, (state) => state.lstDepart),
  selectdate: createSelector(selectSelf, (state) => state.getdate),
  seleclstDeparmentall: createSelector(
    selectSelf,
    (state) => state.lstdepartmentall
  ),
  selectCurrentDate: createSelector(selectSelf, (state) => state.currentDate),
};
