import styled from "styled-components";

export const ChatBotWrap = styled.div`
  border-radius: 16px;
  background: #f5f5f5;
  width: 460px;
  overflow: hidden;
  box-shadow: 0px 3px 36px 0px #0000001a;
  box-shadow: 0px 0px 4px 0px #00000029;
  width: 100%;
`;

export const ChatBotHeader = styled.div`
  padding: 8px 20px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChatBotBody = styled.div`
  padding: 20px;
  overflow: auto;
`;

export const ChatBotMessageReceive = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: flex-end;
  .content {
    margin-left: 8px;
    background: #fff;
    color: #262626;
    padding: 12px;
    border-radius: 0 12px 12px 0;
    max-width: 90%;
  }
`;

export const ChatBotMessageSend = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  .content {
    background: linear-gradient(90deg, #531dab 0%, #7d3fff 98.21%);
    color: white;
    padding: 12px;
    border-radius: 12px 0 0 12px;
    max-width: 70%;
  }
`;

export const ChatBotNewMsg = styled.div`
  background: red;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: -5px;
  font-weight: bold;
`;

export const ChatBotFooter = styled.div`
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  padding: 11px 20px;
  position: relative;
  .message-options {
    width: 100%;
    background: #fafafa;
    padding: 8px 20px 2px;
    .message {
      background: #f9f0ff;
      border: 1px solid #ffffff;
      padding: 8px 12px;
      border-radius: 50px;
      color: #213992;
      width: auto;
      display: inline-block;
      cursor: pointer;
      margin-right: 6px;
      margin-bottom: 6px;
      box-shadow: 0px 1px 6px 0px #2506581a;
      &.active {
        background: #efdbff;
      }
    }
  }
  input {
    width: 100%;
    border: none;
    &:active,
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
  button {
    box-shadow: none !important;
    border: none !important;
    background-color: transparent;
  }
`;

export const ChatBotNoti = styled.div`
  position: relative;
  margin-bottom: 5px;
  font-weight: 500;
  text-align: center;
  max-width: 200px;
  padding: 12px 16px 12px 16px;
  border-radius: 18px;
  opacity: 0px;
  background: radial-gradient(
    133.61% 325.12% at 0% -10.71%,
    rgba(222, 211, 255, 0.9) 3.94%,
    rgba(255, 255, 255, 0.9) 34.73%,
    rgba(255, 255, 255, 0.9) 67.04%
  );
  box-shadow: 1px 5px 16px 0px #722ed14d;
  &::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-right: 6px solid white;
    border-top: 6px solid white;
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    top: 100%;
    right: 50px;
  }
`;

export const TypingMsg = styled.div`
  .lds-ellipsis {
    /* change color here */
    color: #722ed1;
  }
  .lds-ellipsis,
  .lds-ellipsis div {
    box-sizing: border-box;
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 6px;
  }
  .lds-ellipsis div {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 2px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 2px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 16px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 32px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export const ChatBotContentWrap = styled.div`
  width: 70%;
  max-width: 800px;
  margin: auto;
`