import api from "api";
import { ILeadership, IUpdateBody } from "types/leadership";

interface IResponse {
  data: ILeadership[];
  leaderboard_period: string;
}

export async function getLeadership(
  date: string,
  key: string
): Promise<IResponse> {
  console.log('date', date)
  const response = await api.caller.get(
    `${process.env.REACT_APP_API_URL}/leader-board/leaderboard-feedback?action_time=${date ? date : ''}`
  );
  const data = {
    data: response?.data?.[key] || [],
    leaderboard_period: response?.data?.leaderboard_period || "",
  };

  return data as IResponse;
}

export async function updateProcessing(item: IUpdateBody) {
  const endpoint = `${process.env.REACT_APP_API_URL}/leader-board/leaderboard-feedback`;

  const response = await api.caller.post(endpoint, item);
  const data = response.data;

  return data;
}
