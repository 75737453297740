import { createSelector } from "@reduxjs/toolkit";
import { State } from "./type";

const selectSelf = (state: any) => state.uploadbul;

export const selector = {
  selectProjectData: createSelector(selectSelf, (state) => state),

  selectListBul: createSelector(selectSelf, (state) => state.listbul),
  selectListBulReview: createSelector(
    selectSelf,
    (state) => state.listbulreview
  ),
};
