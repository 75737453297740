import { Modal } from "antd";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  .title-group {
    display: flex;
    flex-direction: column;
    text-align: center;
    .main-title {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      color: #262626;
    }
    .sub-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #43434343;
    }
  }
  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }
  .ant-modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .main-content {
    display: flex;
    .left {
      width: 50%;
      background-color: #f5f5f5;
      padding: 20px 16px;
    }
    .right {
      width: 50%;
      padding: 20px 16px;
      .list-cvs {
        height: 350px;
        overflow-y: auto;
        .ant-checkbox+span {
          word-break: break-word;
        }
      }
    }
  }
  .header-group {
    display:  flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .input-search {
    margin-bottom: 16px;
  }
  .list-item {
    padding: 8px 12px;
    border-bottom: 1px solid #f0f0f0;
    &:last-child {
      border-bottom: none;
    }
    .ant-checkbox {
      margin-right: 4px;
    }
  }

  .selected-list {
    list-style: none;
    padding-left: 0;
    overflow: auto;
    height: 350px;
    &:hover {
    }
  }

  .selected-item {
    padding: 8px 36px 8px 12px;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    background-color: #ffffff;
    position: relative;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    .item-wrap {
      display: flex;
    }
    &:hover {
      border: 1px solid #b37feb;
      background-color: #f9f0ff;
    }
    .item-icon {
      width: 24px;
      height: 24px;
      padding: 4px;
      background-color: #f9f0ff;
      border-radius: 8px;
      line-height: 16px;
      display: inline-block;
      text-align: center;
    }

    .item-text {
      margin: 0 8px;
      word-break: break-word;
    }
    .item-action {
      width: 24px;
      height: 24px;
      padding: 4px;
      background-color: #ffffff;
      border-radius: 8px;
      line-height: 16px;
      display: inline-block;
      text-align: center;
      position: absolute;
      right: 12px;
      top: 8px;
      cursor: pointer;
    }
  }
`;
