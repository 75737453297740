import axios, { AxiosInstance } from "axios";


class Api {
  public caller!: AxiosInstance;

  static instance?: Api;

  static getInstance() {
    // const token = sessionStorage.getItem("SpaceX");
    if (!this.instance) this.instance = new Api();
    return this.instance;
  }

  constructor() {
    this.init();
  }

  private init() {
    this.caller = axios.create({
      headers: {
        "Content-type": "application/json",
        responseType: "blob",
      },
    });
    // - Token
    const token = sessionStorage.getItem("SpaceX");

    // eslint-disable-next-line
    if (token)
      this.caller.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // - Config
    this.caller.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    this.caller.defaults.timeout = 1000 * 60 * 5;
  }

  //> Handle
  public setToken(token: string) {
    sessionStorage.setItem("SpaceX", token);

    // eslint-disable-next-line
    if (token)
      this.caller.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  public removeToken() {
    sessionStorage.removeItem("SpaceX");
    // eslint-disable-next-line
    this.caller.defaults.headers.common["Authorization"] = "";
  }
}

const api = Api.getInstance();

export default api;
