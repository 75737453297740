import { Button } from "antd";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 0;
`

export const Wrapper = styled.div`
  margin-top: 56px;
  flex: 1;
`;

export const CustomBtn = styled(Button)`
    height: 40px;
    padding: 8px 16px;
    border-radius: 2px ;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 400;
`

export const HappiWrapper = styled.div`
    display: flex;
    border-radius: 12px;
    padding: 12px 20px;
    background-color: #F9F0FF;
    align-items: center;
`

export const HappiTitle = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const ChartWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 16px 0;
`

export const ChartItem = styled.div`
    border-radius: 16px;
    padding: 20px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const CustomText = styled.span`
    font-weight: 500;
    color: #722ED1;
`

export const CustomWrapper = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 8px;
    background-color: #F9F0FF; 
    width: 40%;
`
export const ItemWrapper = styled.div`
    border-radius: 50px;
     padding: 8px 12px;
     border: 1px solid #fff;
     background-color: #F9F0FF;
     color: #371580;
     cursor: pointer;
     &:hover  {
        background-color: #D3ADF7;
     }
`