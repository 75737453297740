import { Button, Modal } from "antd";
import { ChartDefaultImg } from "assets/images";
import { IconDownload } from "assets/svg";
import { CustomModal } from "features/Inforgraphic/styled";
import { useRef } from "react";
import { IChartItem } from "types/chatToInsight";
import jsPDF from "jspdf";

interface IProps {
  selectedItem: IChartItem;
  isOpenModal: boolean;
  onClose: () => void;
}

export const FullAnalysis = ({
  selectedItem,
  isOpenModal,
  onClose,
}: IProps) => {
  const pdf = useRef(null);
  const handleAddCancel = () => {
    onClose();
  };

  const handleGeneratePdf = () => {
    if (!pdf.current) return;
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");
    doc.setFontSize(14);

    doc.html(pdf.current, {
      async callback(doc) {
        await doc.save(selectedItem.title);
      },
    });
  };

  return (
    <CustomModal
      title={selectedItem.title}
      open={isOpenModal}
      onCancel={handleAddCancel}
      width={"800px"}
      footer={null}
    >
      <div style={{display: "none"}}>
        <div style={{ margin: "30px", width: "400px" }} ref={pdf}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <img
              src={selectedItem.image}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div
            style={{ width: "100%", fontSize: "10px" }}
            dangerouslySetInnerHTML={{ __html: selectedItem.description }}
          ></div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={selectedItem.image}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: selectedItem.description }}
        ></div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}
          onClick={handleGeneratePdf}
        >
          <IconDownload />
          <span style={{ fontWeight: "600", color: "#531DAB" }}>Download</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            <Button
              onClick={onClose}
              style={{
                height: "40px",
                width: "116px",
                borderRadius: "2px",
                border: "1px solid #D9D9D9",
                padding: "8px 16px",
                backgroundColor: "#6E2AFF",
                color: "#fff",
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
