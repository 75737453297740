import { Radio } from "antd";
import styled from "styled-components";

export const CustomP = styled.p`
  margin-bottom: 10px;
  margin-left: 32px;
`;
export const CustomPTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #0d0d0d;
  margin-bottom: 5px;
  margin-left: 8px;
`;

export const CustomRadio = styled(Radio)`
  margin-left: 32px;
  width: 100%;
  span.ant-radio + * {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0,0,0,0.85);
    margin-bottom: 8px;
  }
`;
export const BUGradeHeader = styled.div`
  display:flex;
  flex-direction:column; 
  justify-content:center; 
  align-items:center;
  margin-bottom: 1rem;
`

export const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
`