import { Button, Form, Modal } from "antd";
import * as S from "../../styled";
import { IconBack } from "assets/svg";
import CongratA from "assets/images/CongratA.png";
import CongratB from "assets/images/CongratB.png";
import { ILeadership, IUpdateBody } from "types/leadership";
import moment from "moment";
interface IProps {
  type: string;
  updatedEmail: string;
  data?: ILeadership;
  isPreviewModal: boolean;
  onClose: () => void;
  onOpenBUGrade: () => void
  sendEmail: (data: IUpdateBody, modalType: string) => void;
}

export const PreviewEmail = ({
  type,
  updatedEmail,
  data,
  isPreviewModal,
  onClose,
  sendEmail,
  onOpenBUGrade
}: IProps) => {
  let medal = "";

  console.log('data', data)

  if (data?.rank === 2) {
    medal = "Bạc";
  } else if (data?.rank === 3) {
    medal = "Đồng";
  } else if (data?.rank === 1) {
    medal = "Vàng";
  }

  const handleAddCancel = () => {
    onClose();
  };
  const handleBUGrade = () => {
    onClose();
    onOpenBUGrade();
  };

  const handleSend = () => {
    if (!data) return;
    const info = {
      bu_name: data.bu_name,
      bu_email: updatedEmail,
      bu_note: data.note,
      leaderboard_period: moment(data.action_time).format('YYYY-MM'),
      request_type: 'bu_send_email',
    }
    sendEmail(info, 'sendEmail')
  }
  return (
    <Modal
      title={null}
      open={isPreviewModal}
      onCancel={handleAddCancel}
      width={"800px"}
      footer={null}
      closable={false}
    >
      <div>
        <div style={{ marginBottom: "24px" }}>
          <img src={type === 'A' ? CongratA : CongratB} alt="Congrat img" />
        </div>
        <div
          style={{
            padding: "0 40px",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            marginBottom: "2rem",
          }}
        >
          <div>Dear Anh/Chị,</div>
          <div>
            {medal && "Xin chúc mừng"}{" "}
            <span style={{ fontWeight: "700" }}>{data?.department}</span>{" "}
            {medal ? "đã" : "chưa"} đạt được{" "}
            <span style={{ fontWeight: "700" }}>Huy Chương {medal}</span> của
            chương trình Leadership board trong{" "}
            <span style={{ fontWeight: "700" }}>
              tháng {moment(data?.action_time).format("MM")} năm{" "}
              {moment(data?.action_time).format("YYYY")}
            </span>{" "}
            .{" "}
            {medal &&
              "Anh chị hãy liên hệ với SSC để được hỗ trợ nhận giải thưởng."}
          </div>
          <div>
            Trường hợp Anh/Chị cần thêm thông tin hoặc hỗ trợ, vui lòng tham
            khảo <span style={{ textDecoration: "underline" }}>Q&A</span> trên
            Portal; hoặc gửi email đến:
          </div>
          <div>
            <div>
              <span style={{ marginRight: "2rem" }}>.</span>
              <span style={{ fontWeight: "700", marginRight: "11rem" }}>
                Hà Nội:
              </span>
              <span style={{ fontWeight: "700" }}>NhanPT10@fpt.com</span>
            </div>
            <div>
              <span style={{ marginRight: "2rem" }}>.</span>
              <span style={{ fontWeight: "700", marginRight: "1rem" }}>
                Đà Nẵng/Quy Nhơn/Tam Kỳ/Huế:
              </span>
              <span style={{ fontWeight: "700" }}>VyHT@fpt.com</span>
            </div>
            <div>
              <span style={{ marginRight: "2rem" }}>.</span>
              <span style={{ fontWeight: "700" }}>Hồ Chí Minh & Cần Thơ:</span>
              <span style={{ fontWeight: "700", marginRight: "4.5rem" }}>
                AnNT36@fpt.com
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleBUGrade}
        >
          <div style={{ padding: "8px" }}>
            <IconBack />
          </div>
          <S.CustomText>Back</S.CustomText>
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                padding: "8px, 16px, 8px, 16px",
                width: "116px",
                height: "40px",
              }}
              onClick={handleSend}
              disabled={!medal || !updatedEmail}
            >
              Send email
            </Button>
          </Form.Item>
        </div>
      </div>
    </Modal>
  );
};
