import styled from "styled-components";
import { Button as AntButton, Checkbox, Select, TreeSelect } from "antd";
export const ButtonCancel = styled(AntButton)`
  padding: 5px 15px 5px 15px;
  min-width: 140px;
  height: 36px;
  background: #fff;
  color: #2b88d8;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 1.25px;
  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  line-height: 16px;
  margin: 10px;
  border: 1px solid #f1f1f1;
  &:hover {
    color: #2b88d8;
    border-color: #2b88d8;
  }
  &:focus {
    color: #2b88d8;
    border-color: #2b88d8;
  }
`;
export const ButtonSubmit = styled(AntButton)`
  padding: 5px 15px 5px 15px;
  min-width: 140px;
  height: 36px;
  background: #5440b8;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 1.25px;
  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0px;
  margin-top: 10px;
  border: 1px solid #5440b8;
  &:hover {
    color: #fff;
    border-color: #5440b8;
    background: #5440b8;
    opactity: 0.8;
  }
  &:focus {
    color: #fff;
    border-color: #5440b8;
    background: #5440b8;
    opactity: 0.8;
  }
`;
export const ButtonCreate = styled(AntButton)`
  /* padding: 5px 25px 5px 25px;
  min-width: 140px; */
  /* height: 34px; */
  background: #5440b8;
  border-radius: 4px;

  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0px;
  border: 1px solid #5440b8;
  &:hover {
    color: #fff;
    border-color: #5440b8;
    background: #5440b8;
    opactity: 0.8;
  }
  &:focus {
    color: #fff;
    border-color: #5440b8;
    background: #5440b8;
    opactity: 0.8;
  }
`;
export const PostionBtn = styled.div`
  text-align: end;
  margin-bottom: 60px;
`;
export const Customa = styled.a`
  font-size: 16px;
`;
export const customNoteEmail = styled.p`
  font-size: 14px;
  color: #388e3c;
`;
export const customNoteEmailhr = styled.p`
  font-size: 14px;
  color: #5440b8;
`;
export const customNoteContent = styled.p`
  font-size: 14px;
`;
export const TitleFail = styled.div`
  text-align: center;
  .ant-select-selection-item {
    color: #ff3d00;
  }
`;
export const TitleSuccess = styled.div`
  text-align: center;
  .ant-select-selection-item {
    color: #388e3c;
  }
`;
export const TitleDefault = styled.div`
  text-align: center;
`;
export const containerTable = styled.div`
  padding: 1rem;
  .ant-table-wrapper {
    max-width: 100%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
`;

export const SPagination = styled.div`
  margin-top: 10px;
`;

export const TitleUnknown = styled.div`
  text-align: center;

  .ant-select-selection-item {
    color: #ff9800;
  }
`;
export const TitleBlank = styled.div`
  /* text-align: center; */
  /* .ant-select.ant-select-borderless.ant-select-single.ant-select-show-arrow {
    ;
  } */
`;
export const TitleCenter = styled.div`
  /* text-align: center; */
`;

export const ExpandComment = styled.div`
  text-align: end;
`;
export const percentage = styled.span`
  min-width: 100px;
`;
export const WrapperCheckbox = styled(Checkbox)``;
export const ButtonExport = styled(AntButton)`
  padding: 5px 15px 5px 15px;
  min-width: 140px;
  height: 36px;
  background: #fff;
  color: #5440b8;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 1.25px;
  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  line-height: 16px;
  margin: 10px;
  border: 1px solid #f1f1f1;
  &:hover {
    color: #5440b8;
    border-color: #5440b8;
  }
  &:focus {
    color: #5440b8;
    border-color: #5440b8;
  }
`;
export const ButtonFeedback = styled(AntButton)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #322e41;
  margin: 5px 0px 5px 0px;
`;
export const ControlBracrum = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
export const ControlP = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;
  margin-left: 18px;
`;
