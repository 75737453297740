import {
  Button,
  Col,
  Form,
  Grid,
  message,
  Modal,
  ModalProps,
  Radio,
  Row,
  Space,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportActions, reporttSelector } from "store";
import * as S from "./styles";
import moment from "moment";
import { LoadingOutlined, StarFilled } from "@ant-design/icons";

type Props = ModalProps & {
  setIsModalBulConfirm: (value: boolean) => void;
  dataValueChurn: any;
  valueChurn: any;
  setBulCheck: (value: boolean) => void;
  setIsModalThankYou: (value: boolean) => void;
  getData: () => void;
  inputComments: any;
};

type FormType = {};

const ModelBulConfirmYes = (props: Props) => {
  const [form] = Form.useForm<FormType>();
  const onOk = async () => form.submit();
  const dispatch = useDispatch<any>();
  const onCancel = () => onInvisible();
  const [focused, setFocused] = useState(false);
  const [valueYesOrNo, setValueYesOrNo] = useState("No");
  let dates = new Date();
  console.log("props", props);

  const datesNow = moment(dates).format("YYYY/MM/DD");
  const [showOk, setShowOk] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledComment, setDisabledComment] = useState(true);
  // const fromData = useSelector(reporttSelector.selectdate);
  // const fromDataDate = fromData?.result?.from_date;
  const currentDate = useSelector(reporttSelector.selectCurrentDate);
  const onInvisible = () => {
    props.setIsModalBulConfirm(false);
    form.resetFields();
    props?.getData();
  };
  const handleChange = (e: any) => {
    checkDisabledBtnOk();
  };

  useEffect(() => {
    if (props?.dataValueChurn?.ChurnIntention !== 'Yes') return
    const listReason = [
      'Đang xin nghỉ việc/ đã nghỉ việc',
      'Muốn thay đổi phòng ban/ dự án/ định hướng nghề nghiệp',
      'Performance không đáp ứng công việc, xem xét điều chuyển đơn vị/ hạ cấp',
      'Leader đã tư vấn và định hướng cho nhân viên để giữ chân nhân viên',
      'Có ý định nghỉ việc vì lý do lương thưởng/ cơ hội bên ngoài/ sức khỏe/ lý do cá nhân khác',
      'Stress/ công việc quá tải'
    ];
    form.setFieldValue("reason", props.inputComments);
    if (!listReason.includes(props.inputComments)) {
      form.setFieldValue("reason", 'other');
      form.setFieldValue("Comment", props.inputComments);
    }
    form.setFieldValue("Radio", props?.dataValueChurn?.Bul_Known);
  }, []);

  const checkDisabledBtnOk = () => {
    if (
      (form.getFieldValue("reason") !== "other" &&
        form.getFieldValue("Radio")) ||
      (form.getFieldValue("reason") === "other" &&
        form.getFieldValue("Comment") &&
        form.getFieldValue("Radio"))
    ) {
      setShowOk(false);
    } else {
      setShowOk(true);
    }
  };

  const handleChangeReason = (e: any) => {
    checkDisabledBtnOk();
    if (e.target.value === "other") {
      setDisabledComment(false);
    } else {
      setDisabledComment(true);
    }
  };

  // useEffect(() => {
  //   if (props?.dataValueChurn?.Bul_Known === "Default") {
  //     setShowOk(false);
  //   } else {
  //     setShowOk(true);
  //   }
  // }, [props?.dataValueChurn?.Bul_Known]);
  const onFinish = async (inputData: any) => {
    if(isLoading) return;
    const dataChurnYes = {
      employee_id: props?.dataValueChurn?.EmployeeID,
      pred_date: props?.dataValueChurn?.PredictionDate,
      churn_intention: props?.valueChurn,
      bul_known:
        inputData?.Radio === undefined
          ? props?.dataValueChurn?.Bul_Known
          : inputData?.Radio,
      note:
        inputData?.reason !== undefined && inputData?.reason !== "other"
          ? inputData?.reason
          : inputData?.Comment !== undefined
            ? inputData?.Comment
            : props.inputComments !== undefined
              ? props.inputComments
              : "",
      pred_date_from: currentDate ? currentDate : "",
      pred_date_to: datesNow,
    };

    try {
      setIsLoading(true);
      await new Promise((res) =>
        dispatch(
          reportActions.updateChurnIntention({
            params: dataChurnYes,
            onSuccess: (res) => {
              props.setIsModalBulConfirm(false);

              message.success(`${res?.message}`);
              props?.getData();

              if (res?.last_empl === "true") {
                props?.setIsModalThankYou(true);
              }
            },
            onError: (res: any) => {
              message.error("Update churn fail");
            },
          })
        )
      );
      setShowOk(false);
    } catch (error) {
      message.error("Update churn fail");
      setShowOk(false);
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  return (
    <Form form={form} preserve={false} onFinish={onFinish} layout="vertical">
      <Modal
        {...props}
        centered
        title={null}
        footer={null}
        destroyOnClose
        width={600}
        closable={false}
      >
        <S.BUGradeHeader>
          <S.ModalTitle>BUL Confirm</S.ModalTitle>
        </S.BUGradeHeader>
        <Space align="start" style={{ marginBottom: "7px" }}>
          <StarFilled
            style={{ fontSize: "16px", color: "#722ED1", marginTop: "3px" }}
          />
          <S.CustomPTitle>
            Anh chị đã chọn Yes - anh chị đồng ý nhân viên có dấu hiệu nghỉ việc
            vì có các biểu hiện nào dưới đây:
          </S.CustomPTitle>
        </Space>
        <Form.Item name="reason">
          <Radio.Group
            onChange={handleChangeReason}
            defaultValue={props?.dataValueChurn?.Bul_Known}
          >
            <S.CustomRadio value={"Đang xin nghỉ/ đã nghỉ"}>
              Đang xin nghỉ/ đã nghỉ
            </S.CustomRadio>
            <S.CustomRadio
              value={"Muốn thay đổi phòng ban/ dự án/ định hướng nghề nghiệp"}
            >
              Muốn thay đổi phòng ban/ dự án/ định hướng nghề nghiệp
            </S.CustomRadio>
            <S.CustomRadio value={"Performance không đáp ứng công việc, xem xét điều chuyển đơn vị/ hạ cấp"}>
            Performance không đáp ứng công việc, xem xét điều chuyển đơn vị/ hạ cấp
            </S.CustomRadio>
            <S.CustomRadio
              value={
                "Leader đã tư vấn và định hướng cho nhân viên để giữ chân nhân viên"
              }
            >
              Leader đã tư vấn và định hướng cho nhân viên để giữ chân nhân viên
            </S.CustomRadio>
            <S.CustomRadio
              value={
                "Có ý định nghỉ việc vì lý do lương thưởng/ cơ hội bên ngoài/ sức khỏe/ lý do cá nhân khác"
              }
            >
              Có ý định nghỉ việc vì lý do lương thưởng/ cơ hội bên ngoài/ sức khỏe/ lý do cá nhân khác
            </S.CustomRadio>
            <S.CustomRadio
              value={
                "Stress/ công việc quá tải"
              }
            >
              Stress/ công việc quá tải
            </S.CustomRadio>
            <br/>
            <S.CustomRadio value={"other"}>Lý do khác</S.CustomRadio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="Comment"
          style={{ marginLeft: "32px", marginBottom: "20px" }}
        >
          <TextArea
            placeholder="Enter other reason"
            title="Note"
            rows={3}
            // defaultValue={props.inputComments}
            disabled={disabledComment}
            onChange={checkDisabledBtnOk}
          />
        </Form.Item>
        <Space align="center" style={{ marginBottom: "7px" }}>
          <StarFilled style={{ fontSize: "16px", color: "#722ED1" }} />
          <S.CustomPTitle>
            Anh/chị đã biết trước việc nhân viên sẽ nghỉ việc hay không?
          </S.CustomPTitle>
        </Space>
        <S.CustomP>
          Chọn “Yes” nếu anh/chị đã biết trước nhân viên sẽ nghỉ việc. Chọn “No”
          nếu anh/chị chưa biết trước
        </S.CustomP>
        <Form.Item name="Radio" style={{ marginLeft: "32px" }}>
          <Radio.Group
            style={{ width: "100%" }}
            onChange={handleChange}
          >
            <Row gutter={12}>
              <Col className="gutter-row" span={12}>
                <Radio value={"Yes"}>Yes</Radio>
              </Col>
              <Col className="gutter-row" span={12}>
                <Radio value={"No"}>No</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", gap: "1rem" }}>
            <Form.Item>
              <Button
                onClick={onCancel}
                style={{
                  padding: "8px, 16px, 8px, 16px",
                  width: "116px",
                  height: "40px",
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  padding: "8px, 16px, 8px, 16px",
                  width: "116px",
                  height: "40px",
                }}
                onClick={onOk}
                disabled={showOk}
              >
                Submit {isLoading && <LoadingOutlined />}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default ModelBulConfirmYes;
