import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Popover, Row, Select } from "antd";
import { IconInfoSvg } from "assets/svg";
import moment from "moment";
import * as S from "./styles";
import Note from "../NoteComponent/Note";
const { Option } = Select;

export const datahrpr = (props: any): any => {
  const listBU: string[] = ["D", "B", "C", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P"];
  return [
    {
      title: "Account",
      dataIndex: "EmployeeID",
      key: "EmployeeID",
      width: 91,
      ellipsis: true,
      render: (record: any) => {
        return (
          <>
            <span className="">{record}</span>
          </>
        );
      },
    },

    {
      title: "Department".replace("_", " "),
      dataIndex: "Features",
      key: "Features",
      width: 100,
      sorter: (a: any, b: any) =>
        b?.Features?.fsu.localeCompare(a?.Features?.fsu),
      render: (val: any, record: any, index: number) => {
        return (
          <span className="">
            {process.env.REACT_APP_IS_FAKE_DATA
              ? `BU ${listBU[Math.floor(Math.random() * listBU.length)]}`
              : `${val?.fsu} ${val?.bul}`}
          </span>
        );
      },
    },
    {
      title: "Hire date".replace("_", " "),
      dataIndex: "Features",
      key: "",
      width: 92,
      sorter: (a: any, b: any) =>
        b?.Features?.hire_date.localeCompare(a?.Features?.hire_date),
      render: (record: any) => {
        return <span className="">{`${record?.hire_date}`}</span>;
      },
    },
    {
      title: "Age",
      dataIndex: "Features",
      key: "Features",
      width: 60,

      render: (record: any) => {
        return <span className="">{`${record?.age}`}</span>;
      },
    },
    {
      title: "Skill",
      dataIndex: "Features",
      key: "Features",
      width: 85,

      render: (record: any) => {
        return <span className="">{`${record?.skill}`}</span>;
      },
    },
    {
      title: "Job Code",
      dataIndex: "Features",
      key: "Features",
      width: 81,

      render: (record: any) => {
        return <span className="">{`${record?.job_code}`}</span>;
      },
    },

    {
      title: "Prediction Date".replace("_", " "),
      dataIndex: "PredictionDate",
      width: 130,
      key: "PredictionDate",
      sorter: (a: any, b: any) => {
        return (
          moment(a?.PredictionDate).unix() - moment(b?.PredictionDate).unix()
        );
      },

      render: (record: any) => {
        return (
          <>
            <span className="">{record}</span>
          </>
        );
      },
    },

    {
      title: "Confident",
      dataIndex: "",
      key: "churn_score",
      width: 100,

      sorter: {
        compare: (record1: any, record2: any) => {
          return Number(record1?.Features?.churn_score)
            .toFixed(2)
            .localeCompare(Number(record2?.Features?.churn_score).toFixed(2));
        },
      },
      render: (record: any) => {
        if (Number(record?.Features?.churn_score) > 0.7) {
          return (
            <S.TilteCentr>
              <span
                className=""
                style={{ color: "#f02424", textAlign: "center" }}
              >
                {Number(record?.Features?.churn_score).toFixed(2)}
              </span>
            </S.TilteCentr>
          );
        }

        if (
          Number(record?.Features?.churn_score) <= 0.7 &&
          Number(record?.Features?.churn_score >= 0.5)
        ) {
          return (
            <S.TilteCentr>
              <span
                className=""
                style={{ color: "#ffb300", textAlign: "center" }}
              >
                {Number(record?.Features?.churn_score).toFixed(2)}
              </span>
            </S.TilteCentr>
          );
        }
        if (
          Number(record?.Features?.churn_score) > 0 &&
          Number(record.Features.churn_score) <= 0.5
        ) {
          return (
            <S.TilteCentr>
              <span
                className=""
                style={{ color: "#43a047", textAlign: "center" }}
              >
                {Number(record?.Features?.churn_score).toFixed(2)}
              </span>
            </S.TilteCentr>
          );
        }
      },
    },

    {
      title: "Churn Reasons",
      dataIndex: "Text_Explanation",
      width: 600,
      key: "Text_Explanation",
      render: (record: any) => {
        return (
          <>
            {Array.isArray(record) &&
              record?.map((o: any) => {
                let content = o?.content ? '- ' + o?.content : ''
                return (
                  <Row gutter={10}>
                    <span className="span_churn" dangerouslySetInnerHTML={{__html: content}}></span>
                  </Row>
                );
              })}
          </>
        );
      },
    },

    {
      title: (
        <>
          BUL Confirm
          <Popover
            placement="bottom"
            content={
              <div>
                <p className="title_confirm">
                  Bu lead xác nhận tool dự đoán nhân viên có ý định nghỉ việc
                  hay không, từ đó đưa ra các action để giữ lại bạn nếu cần
                  thiết
                </p>
                <ul className="confirm_ul">
                  <li>
                    Chọn Yes : Nếu tool đoán đúng nhân viên có ý định nghỉ việc
                  </li>
                  <li>
                    Chọn No : Nếu tool đoán sai, nhân viên không có ý định nghỉ
                    việc
                  </li>
                  <li>
                    Chọn Unknown: Nếu anh chị chưa chắc tool đoán đúng hay sai
                    cần thời gian để cân nhắc thêm. Anh chị có thể quay lại đánh
                    lại Yes hay No nếu sau 1 thời gian anh chị thấy tool đoán
                    đúng và sai.
                  </li>
                </ul>
                <p className="title_confirm">
                  Lưu ý: Tool dùng cho việc dự đoán nhân viên có ý định nghỉ
                  việc, mọi hành động sau đó từ Bul/HR làm thay đổi ý định nghỉ
                  việc của nhân viên, nhân viên quyết định ở lại thì vẫn được
                  tính là Yes
                </p>
              </div>
            }
          >
            <IconInfoSvg className="icon_info" />
          </Popover>
        </>
      ),
      dataIndex: "ChurnIntention",
      key: "ChurnIntention",
      width: 200,

      filters: [
        { text: "Need Confirm", value: "Need Confirm" },
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
        { text: "Unknown", value: "Unknown" },
      ],

      onFilter: (value: string, record: any) =>
        record.ChurnIntention.includes(value),
      render: (val: string, row: any, index: number) => {
        if (val === "Yes") {
          return (
            <div className="bul_confirm">
              <span className="" style={{ color: "#2e7d32" }}>
                {val}
              </span>
            </div>
          );
        }
        if (val === "Unknown") {
          return (
            <div className="bul_confirm">
              <span className="" style={{ color: "#FF9800" }}>
                {val}
              </span>
            </div>
          );
        }
        if (val === "No") {
          return (
            <div className="bul_confirm">
              <span className="" style={{ color: "#F44336" }}>
                {val}
              </span>
            </div>
          );
        }
        if (val === "Need Confirm") {
          return (
            <div className="bul_confirm">
              {" "}
              <span className="">{val}</span>
            </div>
          );
        }
      },
    },

    {
      title: (
        <>
          Known Churned in Advance
          <Popover
            placement="bottom"
            content={
              <div>
                <p className="title_confirm">
                  Known Churned in Advance: Thể hiện thông tin về việc BUL đã
                  biết trước nhân viên có khả năng nghỉ việc hay không (chỉ hiển
                  thị với trường hợp BUL chọn "Yes" ở cột "BUL Confirm")
                </p>
                <ul className="confirm_ul">
                  <li>Yes: nếu BUL đã biết trước nhân viên sẽ nghỉ việc</li>
                  <li>
                    No: nếu BUL chưa biết trước (BUL đã phải hỏi các nguồn kênh
                    khác (PM,...) mới biết là nhân viên có khả năng nghỉ việc)
                  </li>
                </ul>
              </div>
            }
          >
            <IconInfoSvg className="icon_info" />
          </Popover>
        </>
      ),
      dataIndex: "Bul_Known",
      key: "Bul_Known",
      width: 150,
      className: "style_bulknow",
      filters: [
        { text: "Default", value: "Default" },
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],

      onFilter: (value: string, record: any) =>
        record?.Bul_Known?.includes(value),
      render: (val: string, row: any, index: number) => {
        if (val === "Yes") {
          return (
            <div className="bul_confirm">
              <span className="" style={{ color: "#2e7d32" }}>
                {val}
              </span>
            </div>
          );
        }

        if (val === "No") {
          return (
            <div className="bul_confirm">
              <span className="" style={{ color: "#F44336" }}>
                {val}
              </span>
            </div>
          );
        }
        if (val === "Default") {
          return (
            <div className="bul_confirm">
              {" "}
              <span className="">{val}</span>
            </div>
          );
        }
      },
    },

    {
      title: "Notified",
      dataIndex: "",
      width: 120,
      key: "NotifyStatus",
      filters: [
        { text: "Sent", value: "Sent" },
        { text: "Fail", value: "Fail" },
        { text: "No", value: "No" },
        { text: "Blank", value: "Blank" },
      ],

      onFilter: (value: string, record: any) =>
        record?.NotifyStatus?.includes(value),
      render: (record: any) => {
        if (record?.NotifyStatus === "Sent") {
          return (
            <S.TitleSuccess>
              <Select
                bordered={false}
                defaultValue={record?.NotifyStatus}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= -1
                }
                onChange={(value) =>
                  props.parentCallback.hanldeChangeNotified(record, value)
                }
              >
                <Option value="Sent" disabled>
                  Sent
                </Option>

                <Option value="No">No</Option>
                <Option value="Fail" disabled>
                  Fail
                </Option>
                <Option value="Blank">Blank</Option>
              </Select>
            </S.TitleSuccess>
          );
        }
        if (record?.NotifyStatus === "Fail") {
          return (
            <S.TitleFail>
              <Select
                bordered={false}
                defaultValue={record?.NotifyStatus}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= -1
                }
                onChange={(value) =>
                  props.parentCallback.hanldeChangeNotified(record, value)
                }
              >
                <Option value="Sent" disabled>
                  Sent
                </Option>

                <Option value="No">No</Option>
                <Option value="Fail" disabled>
                  Fail
                </Option>
                <Option value="Blank">Blank</Option>
              </Select>
            </S.TitleFail>
          );
        }

        return (
          <S.TitleBlank>
            <Select
              bordered={false}
              defaultValue={record?.NotifyStatus}
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= -1
              }
              onChange={(value) =>
                props.parentCallback.hanldeChangeNotified(record, value)
              }
            >
              <Option value="Sent" disabled>
                Sent
              </Option>

              <Option value="No">No</Option>
              <Option value="Fail" disabled>
                Fail
              </Option>
              <Option value="Blank">Blank</Option>
            </Select>
          </S.TitleBlank>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "Comment",
      key: "Comment",
      width: 150,

      render: (comment: any) => {
        return <Note datanote={comment} key={props?.keyId?.uniqueId} />;
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 10,
      render: (comment: any, record: any) => {
        const nameUser = comment?.Comment;

        const listuser = nameUser?.map((o: any) => o?.user_email);
        const commentUer = sessionStorage.getItem("UserEmail");
        return (
          <Dropdown
            trigger={["click"]}
            overlay={
              listuser?.includes(commentUer)
                ? () => props.menuActions?.menuActions(comment)
                : () => props.menuActionsNone?.menuActionsNone(record)
            }
          >
            <a>
              <MoreOutlined style={{ fontSize: "20px", color: "#5440B8" }} />
            </a>
          </Dropdown>
        );
      },
    },
  ];
};
