import { Button, Form, Input, Modal } from "antd";
import * as S from "../../styled";
import { BUInformation } from "../BuInformation";
import TextArea from "antd/lib/input/TextArea";
import { IconBack, IconEmail } from "assets/svg";
import { ILeadership, IUpdateBody } from "types/leadership";
import moment from "moment";
import { useEffect, useState } from "react";

interface IProps {
  data?: ILeadership;
  updatedEmail: string;
  isCongratulationModal: boolean;
  onClose: () => void;
  onOpenBUGrade: () => void;
  onOpenPreview: () => void;
  sendEmail: (data: IUpdateBody, modalType: string) => void;
}

export const Congratulation = ({
  data,
  updatedEmail,
  isCongratulationModal,
  onClose,
  onOpenBUGrade,
  onOpenPreview,
  sendEmail
}: IProps) => {
  const [form] = Form.useForm();
  const [seletectEmail, setEmail] = useState<string | undefined>()

  useEffect(() => {
    form.setFieldValue("sendTo", updatedEmail || data?.bul_contact);
    setEmail(updatedEmail || data?.bul_contact)
  }, [])

  const handleAddCancel = () => {
    onClose();
  };
  const onFinish = (value: any) => {
    if (!data) return;
    console.log('value', value)
    const info = {
      bu_name: data.bu_name,
      bu_email: value.sendTo,
      bu_note: data.note,
      leaderboard_period: moment(data.action_time).format('YYYY-MM'),
      request_type: 'bu_send_email',
    }
    sendEmail(info, 'updateEmail')
  };
  const handleChange = (e: any) => {
    setEmail(e.target.value)
  }
  const handleBUGrade = () => {
    onClose();
    onOpenBUGrade();
  };
  const handlePreview = () => {
    if (!data) return;
    const info = {
      bu_name: data.bu_name,
      bu_email: form.getFieldValue('sendTo'),
      bu_note: data.note,
      leaderboard_period: moment(data.action_time).format('YYYY-MM'),
      request_type: 'bu_send_email',
    }
    sendEmail(info, 'preview')
    onOpenPreview();
    onClose();
  };

  return (
    <Modal
      title={null}
      open={isCongratulationModal}
      onCancel={handleAddCancel}
      width={"800px"}
      footer={null}
    >
      <S.BUGradeHeader>
        <S.ModalTitle>Congratulation notification</S.ModalTitle>
        <S.ModalSubTitle>
          Send a congratulatory notification email to BU's representative
        </S.ModalSubTitle>
      </S.BUGradeHeader>
      <Form
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onFinish}
        form={form}
        // initialValues={initialValues}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "stretch",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <BUInformation data={data} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              flexGrow: 1,
              width: "442px",
            }}
          >
            <div>
              <S.CustomFormLabel label="Board" name="board">
                <Input disabled placeholder="A" />
              </S.CustomFormLabel>
            </div>
            <div>
              <S.CustomFormLabel label="Campaign" name="campaign">
                <Input disabled placeholder="Campaign 1" />
              </S.CustomFormLabel>
            </div>
            <div>
              <S.CustomFormLabel label="Rank" name="rank">
                <Input disabled placeholder={data?.rank?.toString()} />
              </S.CustomFormLabel>
            </div>
            <div style={{ width: "100%" }}>
              <S.CustomFormLabel label={<>Send to <span style={{color: 'red', marginLeft: '5px'}}>*</span></>} name="sendTo">
                <Input onChange={handleChange}/>
              </S.CustomFormLabel>
            </div>
            <div style={{ cursor: "pointer" }} onClick={handlePreview}>
              <S.CustomText>Preview Email</S.CustomText>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleBUGrade}
          >
            <div style={{ padding: "8px" }}>
              <IconBack />
            </div>
            <S.CustomText>BU Grade </S.CustomText>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Form.Item>
              <Button
                onClick={handleAddCancel}
                style={{
                  padding: "8px, 16px, 8px, 16px",
                  width: "116px",
                  height: "40px",
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  padding: "8px, 16px, 8px, 16px",
                  width: "116px",
                  height: "40px",
                }}
                disabled={!seletectEmail}
              >
                Send
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
