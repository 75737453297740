import { combineReducers } from "redux";

import { reducer as reportReducer } from "./report";
import { reducer as uploadReducer } from "./upload";
import { reducer as userReducer } from "./user";
import { reducer as sendReducer } from "./send";
import { reducer as uploadbulReducer } from "./uploadbul";
import { reducer as FeedbackReducer } from "./feedback";

const rootReducer = combineReducers({
  report: reportReducer,
  user: userReducer,
  upload: uploadReducer,
  send: sendReducer,
  uploadbul: uploadbulReducer,
  feedback: FeedbackReducer,
});
export default rootReducer;
