import chatbotLogo from "assets/images/chatbot-logo-shadow.png";
import { useEffect, useMemo, useRef, useState } from "react";
import { Chatbot } from "./components/Chatbot";
import { useLocation } from "react-router-dom";
import { ChatBotNewMsg, ChatBotNoti } from "./styled";
import faceLogo from "assets/images/face-logo.png";
import { RatingModal } from "../RatingModal/RatingModal";

export const WindowChat = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const blackListURL = ["/login", "/admin", "/chat2insight", "/inforgraphic", "/cv-matchmaker", "/happi-buddy"];
  const [noti, setNoti] = useState<string>();
  const [numberNoti, setNumberNoti] = useState<number>(0);
  const route = useLocation();
  const [isOpenRatingModal, setOpenRatingModal] = useState<boolean>(false);
  const isShow = useMemo(() => {
    if (route.pathname === "/") {
      return false;
    }
    let isShow = blackListURL.some((item) => route.pathname.includes(item));
    return !isShow;
  }, [route.pathname]);
  const refResult = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refResult.current && !refResult.current.contains(event.target)) {
        setIsOpened(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  let timer: any;

  useEffect(() => {
    openNoti(`Hey there! I'm Happi, your friendly chatbot buddy! <img
    src="${faceLogo}"
    alt="chatbot logo"
    height="15px"
    style="margin-bottom: 4px;"
  /> Can't wait to have some fun with you and answer all your questions. Let's get started!`);
  }, []);

  const openNoti = (msg: string) => {
    clearTimeout(timer);
    setNoti(msg);
    timer = setTimeout(() => {
      setNoti("");
    }, 10000);
  };

  return (
    <>
      {isShow && (
        <div
          style={{
            position: "fixed",
            bottom: "24px",
            right: "24px",
            zIndex: "999",
          }}
          ref={refResult}
        >
          <div
            onClick={() => setIsOpened(true)}
            style={{ cursor: "pointer", display: isOpened ? "none" : "block" }}
          >
            {noti && (
              <ChatBotNoti
                dangerouslySetInnerHTML={{ __html: noti }}
              ></ChatBotNoti>
            )}
            {!!numberNoti && <ChatBotNewMsg>{numberNoti}</ChatBotNewMsg>}
            <img
              src={chatbotLogo}
              alt="chatbot logo"
              width={"128px"}
              height={"128px"}
              style={{ float: "right", margin: "-24px" }}
            />
          </div>
          <Chatbot
            setIsOpened={setIsOpened}
            isOpened={isOpened}
            openNoti={openNoti}
            setNumberNoti={setNumberNoti}
            setOpenRatingModal={setOpenRatingModal}
          />
        </div>
      )}
      {isOpenRatingModal && (
        <RatingModal
          isOpenModal={isOpenRatingModal}
          onClose={() => {
            setOpenRatingModal(false);
          }}
        />
      )}
    </>
  );
};
