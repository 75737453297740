import { Button, DatePicker, Input, Select } from "antd";
import styled from "styled-components";

export const MenuWrapper = styled.div`
  width: 568px;
  padding: 16px;
  border-radius: 12px;
  opacity: 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fafafa;
`;

export const MenuTitle = styled.div`
  font-size: 24px;
  font-weight: 510;
  line-height: 32px;
  text-align: left;
  color: #262626;
`;

export const AddNewBtn = styled(Button)`
  height: 40px;
  padding: 8px 16px 8px 16px;
  gap: 16px;
  border-radius: 2px;
  border: 1px;
  opacity: 0px;
  background-color: #6e2aff !important;
  color: #fff !important;
  display: flex;
  align-items: center;
`;

export const MenuAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 120px;
  height: 42px;
  padding: 10px 12px 10px 12px;
  gap: 0px;
  border-radius: 4px;
  border: 1px;
  opacity: 0px;
`;
export const CustomCard = styled.div`
  width: 100%;
  height: 106px;
  padding: 20px;
  gap: 8px;
  border-radius: 10px;
  border: 1px;
  opacity: 0px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: #f9f0ff;
  }
`;
export const SurveyText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #262626;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const TimeText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #8c8c8c;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const DepartmentText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8c8c8c;
`;

export const CardsWrapper = styled.div`
  max-height: 814px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 12px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
`;

export const CardStatus = styled.div`
  width: 80px;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border-radius: 6px 0;
  opacity: 0px;
  font-size: 14px;
  font-weight: 510;
  line-height: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
`;

export const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    padding: 4px;
    gap: 10px;
    border-radius: 8px;
    background-color: #F9F0FF;
    display: flex;
    justify-content: center;
    align-items:center;
`;

export const UppercaseText = styled.div`
  display: inline-block;
  &:first-letter {
    text-transform: uppercase;
  }
`;
