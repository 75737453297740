import { useNavigate, useSearchParams } from "react-router-dom";
import { InforgraphicManagement } from "./Inforgraphic Management";
import { Inforgraphics } from "./Inforgraphics";
import * as S from "./styled";
import { useEffect, useState } from "react";

export const Inforgraphic = () => {
  const [searchParams, setSearchParams] = useSearchParams();  
  const navigate = useNavigate();
  const [isUpdate, setIsUpdated] = useState<number>(0)
  const [isUpdateList, setIsUpdatedList] = useState<number>(0)
  const roles = sessionStorage.getItem("roles");
  useEffect(() => {
    if (roles === 'ADMIN' || roles === 'HRA') {
      navigate('/tracking')
    }
  }, [])
  const onChangeTab = (key: string) => {
    if (key === "list") {
      setSearchParams({'tab': 'list'})
      setIsUpdatedList(isUpdateList + 1)
    } else {
      setIsUpdated(isUpdate + 1)
    }
  };
  return (
    <S.Wrapper>
      <S.ChatbotTop>
        <S.ChatbotTitle>Chat2insight</S.ChatbotTitle>
        <S.CustomTabs
          defaultActiveKey={searchParams.get("tab") || "list"}
          onChange={onChangeTab}
          // destroyInactiveTabPane={true}
          items={[
            {
              label: `Infographics`,
              key: "list",
              children: <Inforgraphics isUpdate={isUpdateList}/>,
            },
            {
              label: `Infographics Management`,
              key: "manage",
              children: <InforgraphicManagement isUpdate={isUpdate}/>,
            },
          ]}
        />
      </S.ChatbotTop>
    </S.Wrapper>
  );
};
