import * as S from "./styled";
import { LeadershipTab } from "./components/LeadershipTab";
import { SettingTab } from "./components/SettingTab";
import { Processing } from "./components/Processing";
export const Leadership = () => {
  const roles = sessionStorage.getItem("roles");
  const listTabs = [
    {
      label: `Leadership Board A`,
      key: "1",
      children: <LeadershipTab tabType="A" />,
    },
    {
      label: `Leadership Board B`,
      key: "2",
      children: <LeadershipTab tabType="B" />,
    },
    // {
    //   label: `Setting`,
    //   key: "4",
    //   children: <SettingTab />,
    // },
  ];
  if (roles?.includes("ADMIN")) {
    listTabs.push({
      label: `Processing`,
      key: "3",
      children: <Processing />,
    });
  }

  console.log('roles', roles)
  const onChangeTab = (key: string) => {
    console.log(key);
  };
  return (
    <S.Wrapper>
      <S.LeadershipTop>
        <S.LeadershipTitle>Leadership Board</S.LeadershipTitle>
        <S.CustomTabs
          defaultActiveKey="1"
          onChange={onChangeTab}
          // destroyInactiveTabPane={true}
          items={listTabs}
        />
      </S.LeadershipTop>
    </S.Wrapper>
  );
};
