import { UploadFile } from "antd";
import api from "api";
import axios from "axios";
import {
  ICVChatParam,
  ICVChatRes,
  ICVDetail,
  ICopyTypeParams,
  IEditTypeParams,
} from "types/cvMatchMaker";

export async function sendMsgCVMatchMaker(item: ICVChatParam) {
  const endpoint =
    "https://cvmatch-search.azurewebsites.net/api/v1/backend/candidate-recommendation/chat";

  const response = await api.caller.post(endpoint, item);
  console.log(response);

  return response.data as ICVChatRes;
}

export async function sendMsgAICCVMatchMaker(item: ICVChatParam) {
  const endpoint =
    "https://cvmatch-search.azurewebsites.net/api/v1/backend/candidate-recommendation/chat-internal";

  const response = await api.caller.post(endpoint, item);
  console.log(response);

  return response.data as ICVChatRes;
}

export async function sendMsgCVMatchMakerWithTemp(item: ICVChatParam) {
  const endpoint =
    "https://cvmatch-search.azurewebsites.net/api/v1/backend/candidate-recommendation/chat-selected-files";

  const response = await api.caller.post(endpoint, item);
  console.log(response);

  return response.data as ICVChatRes;
}

export async function downloadCV(path: string) {
  const endpoint = `https://cvmatch-indexer.azurewebsites.net/api/v1/backend/fileshare/cv/download?file_path=${path}`;

  const response = await api.caller.get(endpoint, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "blob",
  });
  console.log(response);

  return response;
}

export async function uploadCV(username: string, file: File) {
  const endpoint = `https://cvmatch-indexer.azurewebsites.net/api/v1/backend/fileshare/cv/indexed/sync?username=${username}`;

  const formData = new FormData();
  formData.append("pdf_file", file);
  const response = await api.caller.post(endpoint, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log(response);

  return response;
}

export async function createIndex(username: string) {
  const endpoint = `https://cvmatch-indexer.azurewebsites.net/api/v1/backend/create-index?username=${username}`;
  const response = await api.caller.post(endpoint);
  console.log(response);

  return response;
}

export async function uploadAndIndexCV(
  username: string,
  file: File | UploadFile,
  department?: string
) {
  const dep = department ? `&department=${department}` : "";
  const endpoint = `https://cvmatch-indexer.azurewebsites.net/api/v1/backend/fileshare/cv/indexed/sync?username=${username}${dep}`;

  const formData = new FormData();
  if ((file as UploadFile).originFileObj) {
    formData.append("pdf_file", (file as UploadFile).originFileObj as File);
  } else {
    formData.append("pdf_file", file as File);
  }
  const response = await api.caller.post(endpoint, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log(response);

  return response;
}

export async function uploadAICCV(
  username: string,
  file: File | UploadFile,
  department?: string
) {
  const dep = department ? `&department=${department}` : "";
  const endpoint = `https://cvmatch-indexer.azurewebsites.net/api/v1/backend/fileshare/cv/indexed/sync-internal?username=${username}${dep}`;

  const formData = new FormData();
  if ((file as UploadFile).originFileObj) {
    formData.append("pdf_file", (file as UploadFile).originFileObj as File);
  } else {
    formData.append("pdf_file", file as File);
  }
  const response = await api.caller.post(endpoint, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log(response);

  return response;
}

export async function indexAICCV(
  username: string,
  file_names: string[],
  department?: string,
  share_to_pool?: boolean,
  index_name?: string
) {
  const endpoint =
    "https://cvmatch-indexer.azurewebsites.net/api/v1/backend/indexer-filename-internal";
  const response = await api.caller.post(endpoint, {
    username,
    file_names,
    department,
    index_name,
    share_to_pool,
  });
  console.log(response);

  return response;
}

export async function indexACV(
  username: string,
  file_names: string[],
  department?: string,
  share_to_pool?: boolean,
  index_name?: string
) {
  const endpoint =
    "https://cvmatch-indexer.azurewebsites.net/api/v1/backend/indexer-filename";
  const response = await api.caller.post(endpoint, {
    username,
    file_names,
    department,
    index_name,
    share_to_pool,
  });
  console.log(response);

  return response;
}

export async function getAllCVs(params: {
  username: string;
  department?: string;
  hiring_status?: string;
  sharing_status?: string;
  search_key_words?: string;
}) {
  const endpoint =
    "https://cvmatch-indexer.azurewebsites.net/api/v1/backend/fileshare/cv/indexed/get_all_cvs";
  const response = await api.caller.get(endpoint, {
    params,
    paramsSerializer: (params) => {
      return Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key]).replace(/%20/g, "%20")}`
        )
        .join("&");
    },
  });
  console.log(response);

  return response.data as ICVDetail[];
}

export async function indexSelectedFiles(
  username: string,
  file_names: string[]
) {
  const endpoint =
    "https://cvmatch-indexer.azurewebsites.net/api/v1/backend/index-selected-files";
  const response = await api.caller.post(endpoint, {
    username,
    file_names,
  });
  console.log(response);

  return response;
}

export async function deleteCV(params: {
  user_name: string;
  hiring_status: string;
  filename: string;
}) {
  console.log(params);
  const endpoint = `https://cvmatch-indexer.azurewebsites.net/api/v1/backend/fileshare/cv/delete-file/${params.user_name}/${params.hiring_status}/${params.filename}`;
  const response = await api.caller.delete(endpoint);
  console.log(response);

  return response;
}

export async function indexToProcess(params: IEditTypeParams) {
  const endpoint =
    "https://cvmatch-indexer.azurewebsites.net/api/v1/backend/cv-management/indexed-to-in-process";
  const response = await api.caller.post(endpoint, params);
  console.log(response);

  return response;
}

export async function processToHired(username: string, file_name: string) {
  const endpoint =
    "https://cvmatch-indexer.azurewebsites.net/api/v1/backend/cv-management/in-process-to-hired";
  const response = await api.caller.post(endpoint, {
    username,
    file_name,
  });
  console.log(response);

  return response;
}

export async function processToIndex(params: IEditTypeParams) {
  const endpoint =
    "https://cvmatch-indexer.azurewebsites.net/api/v1/backend/cv-management/in-process-to-indexed";
  const response = await api.caller.post(endpoint, params);
  console.log(response);

  return response;
}

export async function notShareToShare(params: {
  user_name: string;
  hiring_status: string;
  filename: string;
}) {
  console.log("notShareToShare", params);
  const endpoint = `https://cvmatch-indexer.azurewebsites.net/api/v1/backend/fileshare/cv/delete-file-with-index/${params.user_name}/${params.hiring_status}/${params.filename}`;
  console.log("notShareToShare", params, endpoint);
  const response = await api.caller.delete(endpoint);
  console.log(response);

  return response;
}

export async function copyFile(params: ICopyTypeParams) {
  const endpoint =
    "https://cvmatch-indexer.azurewebsites.net/api/v1/backend/cv-management/copy-cv-from-account-to-pool";
  const response = await api.caller.post(endpoint, params);
  console.log(response);

  return response;
}
