import styled from "styled-components";

export const LeadershipTabWrapper = styled.div`
  width: 100%;
`;

export const LeadershipContent = styled.div`
  display: flex;
  /* background-color: yellow; */
  gap: 20px;
  padding: 24px;
`;

export const LeadershipLeft = styled.div`
  flex: 1;
  /* background-color: blue; */
`;
export const LeadershipRight = styled.div`
  width: 450px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;

  .leadershipRight-content {
    background-color: #fafafa;
    padding: 20px;
  }
`;

export const LeadershipActionTable = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;