import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  DatePicker,
  Button,
  Breadcrumb,
  Form,
  message,
  TreeSelect,
  Table,
  Menu,
  Modal,
  Checkbox,
  Input,
  Dropdown,
  Collapse,
  Affix,
  Spin,
  Empty,
} from "antd";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import "./TableBul.scss";
import { useNavigate } from "react-router-dom";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { reportActions, reporttSelector } from "store";
import ModalComment from "../ModelComment/ModelComment";
import ModelEdit from "../ModelEdit/ModelEdit";
import confirm from "antd/lib/modal/confirm";
import { DropUpSvg, ExportSvg } from "assets/svg";
import { databul } from "./config";
import ModelFeedback from "../ModelFeedback/ModelFeedback";
import ExportExelBul from "../ExportExcel/ExportExelBul";
import ExportCsvBul from "../ExportCSV/ExportCSVBul";
import { Link } from "react-router-dom";
import ModelBulConfirmYes from "../ModalBulConfirmYes/ModelBulConfirmYes";
import ModelBulConfirmNo from "../ModalBulConfirmNo/ModalBulConfirmNo";

import ModalThankYou from "../ModalThankYou/ModalThankYou";

const { TextArea } = Input;

type CSVRow = {
  Source: string;
  Account: string;
  Department: string;
  PredictionDate: string;
  Churn_Reason: string;
  Known_Churned_in_Advance: string;
  BUL_Confirm: string;

  Note: string;
  Confident: string;
};

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const TableBul = () => {
  const useId = () => {
    const idRef = useRef(0);
    idRef.current += 1;
    return idRef.current;
  };
  const uniqueId = useId();

  const dateFormat = "YYYY/MM/DD";
  const roleid = sessionStorage.getItem("roles");
  const roles = roleid;
  const check = roles === "ADMIN";

  const [valueSearch, setValueSearch] = useState<any>();

  const navigate = useNavigate();
  const [YesorNo, setValueYesOrNo] = useState("");
  const [inputComment, setInputComment] = useState({} as any);
  const [inputComments, setInputComments] = useState({} as any);
  const [indexComent, setIndexComent] = useState();
  let dates = new Date();
  const datesNow = moment(dates).format("YYYY/MM/DD");
  const dispatch = useDispatch()<any>;
  const listReport = useSelector(reporttSelector.selectList);
  const dataReport = listReport?.result;
  const [dataReportchurn, setDataReportChurn] = useState(dataReport);
  const fromData = useSelector(reporttSelector.selectdate);
  const currentDate = useSelector(reporttSelector.selectCurrentDate);
  const fromDataDate = fromData?.result?.from_date;
  const [defaultDate] = useState(fromDataDate);
  const [isDelete, setIsDelete] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const { Search } = Input;
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const lstDeparment = useSelector(reporttSelector.selectLst);
  const converTreeData = lstDeparment?.result;
  const treeData = converTreeData?.slice(1);
  const [selectedValues, setSelectedValues] = useState([]);
  const [filters, setFilters] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataselectkey, setDataSlectRowKey] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [user, setUser] = React.useState({} as any);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [bulcheck, setBulCheck] = useState(false);

  const { SHOW_PARENT } = TreeSelect;

  const [updateChurnBul, setUpdateChurBul] = useState(false);
  const [changeData, setChangeData] = useState([]);
  const [modelFeedback, setModelFeedback] = useState(false);
  const [modalBulConfirmYes, setIsModalBulConfirmYes] = useState(false);
  const [modalBulConfirmNo, setIsModalBulConfirmNo] = useState(false);
  const [modalThankYou, setIsModalThankYou] = useState(false);
  const hasSelecteddel = selectedRowKeys.length > 0;
  const refCSVLink = useRef<any>(null);
  const [dataValueChurn, setDataValueChurn] = useState();
  const [valueChurn, setValueChurn] = useState();

  const [exportData, setExportData] = useState<{
    fileName: string;
    csv: CSVRow[];
  }>();
  const [data, setData] = useState({
    pred_date_from: "",
    pred_date_to: "",
    department: "",
    account_query: "",
  });

  const customData = changeData?.map((item: any) => {
    return {
      Source: item.Source,
      Account: item.EmployeeID,
      Department: `${item?.Features?.fsu} ${item?.Features?.bul}`,
      Hire_Date: `${item?.Features?.hire_date}`,

      Age: `${item?.Features?.age}`,

      PredictionDate: item?.PredictionDate,
      Confident: Number(item?.Features?.churn_score).toFixed(2),

      Churn_Reason: `${item?.Text_Explanation?.map(
        (o: any) => o?.content
      )?.join("~")}`,
      BUL_Confirm: item?.ChurnIntention,
      Known_Churned_in_Advance: item?.Bul_Known,

      System_Note: `${item?.Comment?.map(
        (o: any) => `${o?.user_email?.split("@")[0]}: ${o?.content}`
      )?.join("~")}`,
      Bul_Note: "",
    };
  });

  useEffect(() => {
    if (exportData) {
      refCSVLink.current?.link.click();
      setExportData(undefined);
    }
  }, [exportData]);

  interface DataType {
    key: React.Key;
    EmployeeID: string;
    Confident: string;
  }

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRow: any
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDataSlectRowKey(selectedRow);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record?.Source === "SpaceX",
    }),
  };

  const onHanldeCommnet = (row: any) => {
    setInputComment(row);
    setShowModal(true);
  };
  const onHanldeCommnets = (row: any) => {
    const userCom = row?.Comment?.map((o: any) => o.user_email);
    const userLogin = sessionStorage.getItem("UserEmail");
    const index = userCom?.indexOf(userLogin);

    setInputComments(row);
    setIndexComent(row?.Comment?.[index]?.content);
    setShowModals(true);
  };

  const menuActions = (record: any) => {
    return (
      <Menu>
        <Menu.Item key={1}>
          <Button type="text" onClick={() => onHanldeCommnets(record)}>
            Edit
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const menuActionsNone = (record: any) => {
    return (
      <Menu>
        <Menu.Item key={2}>
          <Button type="text" onClick={() => onHanldeCommnet(record)}>
            Comment
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const delEmployee = {
    employee_id: dataselectkey?.map((o: any) => o?.EmployeeID),
    pred_date: dataselectkey?.map((o: any) => o?.PredictionDate),
  };

  const deteteEmployee = async () => {
    setLoading(true);

    confirm({
      className: "table-confirm",
      title: "Are you sure you want delete?",

      okType: "danger",
      onOk() {
        try {
          dispatch(
            reportActions.deleteEmployee({
              params: delEmployee,
              onSuccess: (res: any) => {
                setIsDelete(true);
                message.success("Delete successfully");
                setIsDelete(false);
              },
              onError: (res: any) => {
                message.error(`${res.message}`);
              },
            })
          );
        } catch (error) {
          message.error("Delete fail");
        }
      },
      okButtonProps: {
        className: "custom-ok-btn",
      },
      cancelButtonProps: {
        className: "custom-cancel-btn",
      },
      centered: true,
      okText: "OK",
      cancelText: "Cancel",
    });

    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const handleSelectAll = (e: any) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      const allKeys = getAllKeys(treeData) as any;

      setSelectedValues(allKeys) as any;
    } else {
      setSelectedValues([]);
    }
  };
  const getAllKeys = (data: any[]) => {
    let keys = [] as any;
    data.forEach((item) => {
      keys.push(item.key);
      if (item.children) {
        keys = [...keys, ...getAllKeys(item.children)];
      }
    });
    return keys;
  };
  const getParentKeys = (data: any[]) => {
    let keys = [] as any;
    data.forEach((item) => {
      keys.push(item.key);
    });
    return keys;
  };

  const onSelectChurn = async (dataValue: any, value: any) => {
    const userCom = dataValue?.Comment?.map((o: any) => o.user_email);
    const userLogin = sessionStorage.getItem("UserEmail");
    const index = userCom?.indexOf(userLogin);
    setIndexComent(dataValue?.Comment?.[index]?.content);
    setDataValueChurn(dataValue);
    setValueChurn(value);
    const dataChurn = {
      employee_id: dataValue?.EmployeeID,
      pred_date: dataValue?.PredictionDate,
      churn_intention: value,
      bul_known: value,
      pred_date_from: currentDate ? currentDate : "",
      pred_date_to: datesNow,
    };

    if (value === "Yes") {
      setIsModalBulConfirmYes(true);
    }
    if (value === "No") {
      setIsModalBulConfirmNo(true);
    }
    if (value === "Need Confirm") {
      try {
        await new Promise((res) =>
          dispatch(
            reportActions.updateChurnIntention({
              params: dataChurn,
              onSuccess: (res) => {
                message.success(`${res?.message}`);
                getData();

                if (res?.last_empl === "true") {
                  setIsModalThankYou(true);
                }
              },
              onError: (res: any) => {
                message.error("Update churn fail");
              },
            })
          )
        );
      } catch (error) {
        message.error("Update churn fail");
      }
    }
    if (value === "Unknown") {
      try {
        await new Promise((res) =>
          dispatch(
            reportActions.updateChurnIntention({
              params: dataChurn,
              onSuccess: (res) => {
                message.success(`${res?.message}`);
                getData();
                if (res?.last_empl === "true") {
                  setIsModalThankYou(true);
                }
              },
              onError: (res: any) => {
                message.error("Update churn fail");
              },
            })
          )
        );
        // message.success("Update churn success");
      } catch (error) {
        message.error("Update churn fail");
      }
    }
  };

  const PrerentKey = getParentKeys(treeData) as any;

  const onChangeTreeBul = (valueChange: any) => {
    const isDeparment = JSON.stringify(valueChange);
    setData({ ...data, department: isDeparment });
    setSelectedValues(valueChange);

    if (
      valueChange?.length === PrerentKey?.length &&
      valueChange?.every((val: any) => PrerentKey.includes(val))
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const handleFilterChange = (filters: any, extra: any) => {
    setFilters(filters);
  };
  useEffect(() => {
    let newData = dataReport;
    if (filters?.NotifyStatus) {
      newData = newData?.filter((item: any) =>
        filters?.NotifyStatus?.includes(item?.NotifyStatus)
      );
    }
    if (filters?.Bul_Known) {
      newData = newData?.filter((item: any) =>
        filters?.Bul_Known?.includes(item?.Bul_Known)
      );
    }
    if (filters?.ChurnIntention) {
      newData = newData?.filter((item: any) =>
        filters?.ChurnIntention?.includes(item?.ChurnIntention)
      );
    }
    setChangeData(newData);
    setDataReportChurn(newData);
  }, [filters]);

  const MenuExport = () => {
    return (
      <Menu>
        <Menu.Item key={1}>
          <ExportCsvBul dataExport={customData} />
        </Menu.Item>
        <Menu.Item key={2}>
          <ExportExelBul dataExport={customData} />
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    if (fromDataDate) {
      dispatch(reportActions.setCurrentDate(fromDataDate));
      setData({
        ...data,
        pred_date_from: fromDataDate,
        pred_date_to: datesNow,
      });
    }
  }, [fromDataDate]);

  const getData = async () => {
    await new Promise((res: any) => {
      dispatch(
        reportActions.fetchReport({
          params: data,
          onSuccess: res,
          onError: (res: any) => {
            message.error(`${res.message}`);
          },
        }) as any
      );
    });
  };

  const hanleSearch = async (value: string) => {
    setData({ ...data, account_query: value });
  };

  const onChangeStartDate = (valueDateStart: any, dataString: string) => {
    if (dataString?.length === 0) {
      dispatch(reportActions.setCurrentDate(undefined));
      setData({ ...data, pred_date_from: "" });
    } else {
      dispatch(reportActions.setCurrentDate(dataString));
      setData({ ...data, pred_date_from: dataString });
    }
  };
  const onChangeEndDate = (valueDateEnd: any, dataString: any) => {
    if (dataString?.length === 0) {
      setData({ ...data, pred_date_to: "" });
    } else {
      setData({ ...data, pred_date_to: dataString });
    }
  };
  const hanldeClickFeedback = () => {
    setModelFeedback(!modelFeedback);
  };

  useEffect(() => {
    if (valueSearch === 0) {
      getData();
    }
  }, [valueSearch]);
  useEffect(() => {
    let newData = dataReport;

    if (filters?.NotifyStatus) {
      newData = newData?.filter((item: any) =>
        filters?.NotifyStatus?.includes(item?.NotifyStatus)
      );
    }
    if (filters?.Bul_Known) {
      newData = newData?.filter((item: any) =>
        filters?.Bul_Known?.includes(item?.Bul_Known)
      );
    }
    if (filters?.ChurnIntention) {
      newData = newData?.filter((item: any) =>
        filters?.ChurnIntention?.includes(item?.ChurnIntention)
      );
    }
    setChangeData(newData);
  }, [dataReport, bulcheck]);

  useEffect(() => {
    if (!roleid) {
      navigate("/no_role");
    }
  }, []);

  useEffect(() => {
    if (roles && !check && fromDataDate) getData();
  }, [isDelete, updateChurnBul, bulcheck, valueSearch, data]);

  useEffect(() => {
    dataReport ? setDataReportChurn(dataReport) : setDataReportChurn([]);
  }, [listReport]);

  useEffect(() => {
    if (!check && roles) {
      dispatch(reportActions.listdeparment({}));
    }
  }, [user]);
  useEffect(() => {
    let countNeedConfirm = 0;

    dataReport?.forEach((item: any) => {
      if (item.ChurnIntention === "Need Confirm") {
        countNeedConfirm++;
      }
    });
    setTotalEmployee(countNeedConfirm);
  }, [dataReport]);
  useEffect(() => {
    dispatch(reportActions.getFromDate({}));
  }, [dispatch]);
  // const rowClassName = (val: any, index: any) => {
  //   if (val.ChurnIntention === "Need Confirm") {
  //     return "need-confirm-row"; // Tên lớp CSS cho hàng cần bôi màu
  //   }
  //   return ""; // Trả về chuỗi rỗng nếu không có điều kiện nào được thỏa mãn
  // };

  return (
    <>
      <div className="container-bracrum">
        <S.ControlBracrum>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span style={{ fontSize: 16 }}>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="">
              <span style={{ fontSize: 16 }}> Churn Prediction</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </S.ControlBracrum>
      </div>

      <div className="wrapper_container">
        <div>
          <S.ButtonCreate
            icon={<PlusOutlined />}
            size="large"
            style={{ marginRight: 20 }}
          >
            <Link to="/tracking/add" style={{ color: "#ffffff" }}>
              Add Employee
            </Link>
          </S.ButtonCreate>

          <>
            <TreeSelect
              onChange={onChangeTreeBul}
              allowClear={true}
              placeholder="Choose Deparments"
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              style={{
                minWidth: "15%",
                maxWidth: "35%",
              }}
              size="large"
              dropdownStyle={{
                maxHeight: 300,
                overflow: "auto",
                minWidth: 200,
              }}
              showSearch
              value={selectedValues}
              maxTagCount={2}
              maxTagPlaceholder={(omittedValues) =>
                `+ ${omittedValues.length} Deparments ...`
              }
              treeData={[
                {
                  className: "hide-checkbox",
                  title: (
                    <div className="checkbox">
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        style={{
                          color: "#5440B8",
                          cursor: "pointer",
                          marginLeft: "-3px",
                        }}
                      >
                        <span className="span_select">SELECT ALL</span>
                      </Checkbox>
                    </div>
                  ),
                  value: "xxx",
                  disableCheckbox: true,
                  disabled: true,
                },
                ...treeData,
              ]}
            />
          </>
          <DatePicker
            key={fromDataDate}
            size="large"
            style={{ marginLeft: 20, width: 130, borderRadius: 4 }}
            onChange={onChangeStartDate}
            placeholder="Start Date"
            defaultValue={
              fromDataDate ? moment(fromDataDate, dateFormat) : undefined
            }
            format={dateFormat}
          />
          <DatePicker
            key={dateFormat}
            size="large"
            style={{ marginLeft: 20, width: 130, borderRadius: 4 }}
            onChange={onChangeEndDate}
            placeholder="End Date"
            defaultValue={moment(dates, dateFormat)}
            format={dateFormat}
          />
        </div>

        <div>
          <Search
            className="style_search"
            placeholder="Search Account"
            onSearch={hanleSearch}
            style={{ width: 250 }}
            size="large"
            allowClear
            enterButton
          />
        </div>
      </div>
      {lstDeparment?.loading || fromData?.loading ? (
        <div className="style_loading">
          <Spin size="large" />
        </div>
      ) : (
        <Col>
          <S.ControlP>{`Còn ${totalEmployee} / ${dataReport?.length}  nhân viên chưa được feedback.`}</S.ControlP>
          <Form form={form} component={false}>
            <S.containerTable>
              <Table
                rowKey={(o) => o?.ID}
                rowSelection={rowSelection}
                columns={databul({
                  onSelectChurn: { onSelectChurn },
                  menuActions: { menuActions },
                  menuActionsNone: { menuActionsNone },
                  keyId: { uniqueId },
                })}
                // rowClassName={rowClassName}
                dataSource={dataReportchurn}
                loading={listReport?.loading}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,

                  pageSizeOptions: ["10", "20", "30"],
                }}
                locale={{
                  emptyText: (
                    <Empty
                      description="No results found"
                      className="custom-empty"
                    />
                  ),
                }}
                size="small"
                tableLayout="auto"
                style={{ width: "100%", height: "100%" }}
                onChange={(pagination, filters, sorter, extra) => {
                  handleFilterChange(filters, extra);
                }}
                scroll={!listReport?.loading ? { x: 1500 } : undefined}
              />
              <div className="style-notified">
                {roles?.includes("BUL") ? (
                  <S.PostionBtn>
                    {dataReportchurn?.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <Dropdown overlay={MenuExport} trigger={["click"]}>
                          <S.ButtonExport
                            icon={<ExportSvg style={{ marginRight: 10 }} />}
                          >
                            Export
                          </S.ButtonExport>
                        </Dropdown>
                      </>
                    )}
                    <S.ButtonSubmit
                      onClick={deteteEmployee}
                      disabled={!hasSelecteddel}
                      loading={loading}
                      htmlType={"submit"}
                      style={{ marginLeft: 10 }}
                    >
                      Delete
                    </S.ButtonSubmit>
                  </S.PostionBtn>
                ) : (
                  ""
                )}
              </div>
            </S.containerTable>
          </Form>
        </Col>
      )}

      {
        <ModalComment
          visible={showModal}
          setVisible={setShowModal}
          EmployeeID={inputComment?.EmployeeID}
          pred_date={inputComment?.PredictionDate}
          onDone={getData}
          id={(o: any) => o.ID}
        />
      }
      {
        <ModelEdit
          visible={showModals}
          setVisible={setShowModals}
          EmployeeID={inputComments?.EmployeeID}
          inputComments={indexComent}
          pred_date={inputComments?.PredictionDate}
          onDone={getData}
          id={(o: any) => o.ID}
        />
      }
      {/* {modelFeedback ? (
        <ModelFeedback isModel={setModelFeedback} />
      ) : (
        <Affix offsetBottom={70}>
          <div className="custom_feedbackhrpr">
            <div className="feedback_childhrpr">
              <S.ButtonFeedback
                type="text"
                icon={<DropUpSvg className="css_svg_bul" />}
                onClick={hanldeClickFeedback}
              >
                Feedback
              </S.ButtonFeedback>
            </div>
          </div>
        </Affix>
      )} */}
      {modalBulConfirmYes && (
        <ModelBulConfirmYes
          visible={modalBulConfirmYes}
          setIsModalBulConfirm={setIsModalBulConfirmYes}
          dataValueChurn={dataValueChurn}
          valueChurn={valueChurn}
          setBulCheck={setBulCheck}
          inputComments={indexComent}
          setIsModalThankYou={setIsModalThankYou}
          getData={getData}
        />
      )}
      {modalBulConfirmNo && (
        <ModelBulConfirmNo
          visible={modalBulConfirmNo}
          setIsModalBulConfirm={setIsModalBulConfirmNo}
          dataValueChurn={dataValueChurn}
          valueChurn={valueChurn}
          setBulCheck={setBulCheck}
          setIsModalThankYou={setIsModalThankYou}
          getData={getData}
          inputComments={indexComent}
        />
      )}
      {
        <ModalThankYou
          visible={modalThankYou}
          setIsModalThankYou={setIsModalThankYou}
        />
      }
    </>
  );
};

export default TableBul;
