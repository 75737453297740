import { ImageGlobizen } from "assets/images";
import { PreviewMessageWrap } from "./styled";
interface IProps {
  content?: string,

}
export const PreviewMessage = ({ content }: IProps) => {
  console.log(content);
  return (
    <PreviewMessageWrap>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          padding: "20px",
          width: "350px",
          borderRadius: "12px",
          backgroundColor: "#FAFAFA"
        }}
        dangerouslySetInnerHTML={{ __html: content || '' }}
      >
      </div>
    </PreviewMessageWrap>
  );
};
