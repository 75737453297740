
import { Button } from 'antd';
import {  CSVLink } from "react-csv";
type Props = {
    dataExport: any;
}

const ExportCSVHrpr = (props: Props) => {
const rowData = props?.dataExport;
console.log("rowData", rowData)

const  headers = [
  { label: "Source", key: "Source" },
  { label: "Account", key: "Account" },
  { label: "Department", key: "Department" },
  { label: "Hire_Date", key: "Hire_Date" },

  { label: "Age", key: "Age" },
  { label: "Skill", key: "Skill" },
  { label: "JobCode", key: "JobCode" },
  { label: "PredictionDate", key: "PredictionDate" },
  { label: "Confident", key: "Confident" },
  { label: "Churn_Reason", key: "Churn_Reason" },
  { label: "BUL_Confirm", key: "BUL_Confirm" },
  { label: "Known_Churned_in_Advance", key: "Known_Churned_in_Advance" },
  { label: "Note", key: "System_Note" },
 
];  


const time = new Date();
    const fullyear = time.getFullYear();
    const month = String(time.getMonth() + 1).padStart(2, "0");
    const dateexport = String(time.getDate()).padStart(2, "0");
    const hours = String(time.getHours()).padStart(2, "0");
    const minutes = String(time.getMinutes()).padStart(2, "0");
    const seconds = String(time.getSeconds()).padStart(2, "0");
    const fileName = `export_${fullyear}-${month}-${dateexport}-${hours}_${minutes}_${seconds}`;

const csvReport = {
  data: rowData,
  headers: headers,
  filename: `${fileName}.csv`
};
  return (
    <div>
       <CSVLink  {...csvReport}>
         <Button type='text'> Export to CSV</Button>
        </CSVLink>
    </div>
  )
}

export default ExportCSVHrpr