import { Button, Tabs } from "antd";
import styled from "styled-components";

export const CustomTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color:#262626;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CustomTabs = styled(Tabs)`
  margin: 0 -24px;
  .ant-tabs-nav {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0;
  }
  .ant-tabs-content-holder {
    background-color: #ffffff;
  }
`;

export const SubmitWrapper = styled.div`
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    border-top: 1px solid #D9D9D9;
    padding: 12px 0 0 0;
    margin-bottom: 100px;
`

export const CustomBtn = styled(Button)`
    height: 32px;
    padding: 4px 16px 4px 16px;
    gap: 16px;
    border-radius: 2px;
    border: 1px dashed #D9D9D9;
`

