import chatbotLogo from "assets/images/chatbot-logo.png";
import happiLogo from "assets/images/happi-logo.png";
import subtract from "assets/images/subtract.png";
import menuIcon from "assets/images/menu.png";
import sendIcon from "assets/images/send-icon.png";
import { useEffect, useRef, useState } from "react";
import { Form, Input, Modal } from "antd";
import { getChatbotMsg, sendMsg } from "services/chatbot";
import { IChatBotBody, IChatInfor } from "types/chatbot";
import faceLogo from "assets/images/face-logo.png";
import {
  ChatBotBody,
  ChatBotFooter,
  ChatBotHeader,
  ChatBotMessageReceive,
  ChatBotWrap,
  TypingMsg,
} from "./styled";
import { ChatContent } from "features/Chatbot/WindowChat/components/ChatContent";

interface IProps {
  onDragStart?: (e: any, des: string, img: string, msgId: string) => void;
}

export const ChatbotWindow = ({ onDragStart }: IProps) => {
  const [form] = Form.useForm();
  const [isOpenSuggestion, setIsOpenSuggestion] = useState<boolean>(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingMsg, setIsLoadingMsg] = useState(false);
  const isAuto = useRef(true);
  const userLogin = sessionStorage.getItem("UserEmail");
  const [listOldChat, setListOldChat] = useState<IChatInfor[]>([]);
  const parentRef = useRef<null | HTMLDivElement>(null);
  const [imgSrc, setImgSrc] = useState("");
  const [listChat, setListChat] = useState<IChatInfor[]>([
    {
      content_message: `<p>Hey there! I'm Happi, your friendly chatbot buddy a! <img
        src="${faceLogo}"
        alt="chatbot logo"
        height="15px"
        style="margin-bottom: 4px;"
      /> Can't wait to have some fun with you and answer all your questions. Let's get started!</p>`,
      from: "happi-bot",
    },
  ]);
  let questionIndex = 0;

  const getChatbot = async (date?: string) => {
    try {
      setIsLoading(true);
      const data = await getChatbotMsg("list_message");
      setListOldChat((pre) => [...pre, ...data]);
      setIsLoading(false);
      scrollIntoView();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClick = (event: any) => {
      const target = event.target as HTMLElement;
      if (
        parentRef.current &&
        parentRef.current.contains(event.target) &&
        target.tagName === "IMG"
      ) {
        const src = (target as HTMLImageElement).getAttribute("src") as string;
        setIsOpen(true);
        setImgSrc(src);
      }
    };

    // Add event listener to the document
    document.addEventListener("click", handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    getChatbot();
  }, []);

  const listSuggestion = [
    "Job hot hôm nay",
    "Range lương thị trường IT",
    "Công ty tuyển dụng nhiều",
  ];

  const scrollIntoView = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const onFinish = (values: any) => {
    if (!values?.msgContent) return;
    setListChat((pre) => [
      ...pre,
      {
        from: userLogin || "",
        content_message: values.msgContent,
        is_read: "no",
      },
    ]);
    form.setFieldValue("msgContent", "");
    scrollIntoView();
    handleSendMsg(values.msgContent);
  };

  const handleSendMsg = async (msg: string) => {
    setIsLoadingMsg(true);
    try {
      const data: IChatBotBody = {
        request_type: "happi_send_message",
        message: msg,
      };
      scrollIntoView();
      const res = await sendMsg(data);
      setTimeout(() => {
        if (res.length > 0) {
          // handleIsReadMsg(
          //   res[res.length - 1].id,
          //   res[res.length - 1].message_id
          // );
          setListChat((pre) => [...pre, ...res]);
        }
        setIsLoadingMsg(false);
        scrollIntoView();
      }, 1000);
      scrollIntoView();
    } catch (error) {
      console.log(error);
      // if (listChat.length > 0) {
      //   setListChat((pre) => {
      //     pre[pre.length - 1].isError = true;
      //     return pre;
      //   });
      // }
      setIsLoadingMsg(false);
    }
  };

  return (
    <ChatBotWrap>
      <ChatBotHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "12px" }}>
            <img
              src={chatbotLogo}
              alt="chatbot logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div>
            <img src={happiLogo} alt="happi logo" />
          </div>
        </div>
      </ChatBotHeader>
      <ChatBotBody ref={parentRef}>
        <ChatContent
          listMsg={listOldChat}
          isDraggable={true}
          onDragStart={onDragStart}
        />
        <ChatContent
          listMsg={listChat}
          isDraggable={true}
          onDragStart={onDragStart}
        />

        {isLoadingMsg && (
          <ChatBotMessageReceive>
            <div style={{ width: "32px" }}>
              <img
                src={chatbotLogo}
                alt="chatbot logo"
                width={"32px"}
                height={"32px"}
              />
            </div>
            <div className="content">
              <TypingMsg>
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </TypingMsg>
            </div>
          </ChatBotMessageReceive>
        )}
        <div ref={messagesEndRef}></div>
      </ChatBotBody>
      <ChatBotFooter>
        {isOpenSuggestion && (
          <div className="message-options">
            {listSuggestion.map((item) => (
              <div
                key={item}
                className={`message ${selectedSuggestion === item && "active"}`}
                onClick={() => setSelectedSuggestion(item)}
              >
                {item}
              </div>
            ))}
          </div>
        )}
        <img src={menuIcon} style={{ cursor: "pointer" }} />
        <Form
          form={form}
          onFinish={onFinish}
          style={{ display: "flex", width: "100%" }}
        >
          <Form.Item
            name="msgContent"
            rules={[{ required: true, message: "" }]}
            style={{ flex: 1, marginLeft: "16px" }}
          >
            <Input
              placeholder="Type Message..."
              onClick={() => (isAuto.current = false)}
            />
          </Form.Item>
          <Form.Item>
            <button type="submit">
              <img src={sendIcon} style={{ cursor: "pointer" }} />
            </button>
          </Form.Item>
        </Form>
      </ChatBotFooter>
      <Modal
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
        width={800}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgSrc}
            style={{
              width: "100%",
              maxHeight: '100vh'
            }}
          />
        </div>
      </Modal>
    </ChatBotWrap>
  );
};
