import api from "api";
import { IChatToInsight, IChatToInsightParam } from "types/chatToInsight";

export async function createChat2Insight(item: IChatToInsight) {
  const endpoint = `${process.env.REACT_APP_API_URL}/c2i/chat2insight`;

  const response = await api.caller.post(endpoint, item);
  const data = response.data;

  return data;
}

export async function updateChat2Insight(item: IChatToInsight) {
  const endpoint = `${process.env.REACT_APP_API_URL}/c2i/chat2insight`;

  const response = await api.caller.put(endpoint, item);
  const data = response.data;

  return data;
}

export async function getChat2Insight(params?: IChatToInsightParam) {
  const endpoint = `${process.env.REACT_APP_API_URL}/c2i/chat2insight`;

  const response = await api.caller.get(endpoint, {params});
  const data = response.data;

  return data.campaigns as IChatToInsight[];
}

export async function delChat2Insight(id?: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/c2i/chat2insight?campaign_id=${id}`;

  const response = await api.caller.delete(endpoint);
  const data = response.data;

  return data.campaigns as IChatToInsight[];
}