import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PreviewMessage } from "features/Chatbot/components/PreviewMessage";
import "./content.scss";

interface IProps {
  setContent: (item: any) => void;
  setIsEmpty?: (item: boolean) => void;
  content: string;
  addOrEdit?: string;
}
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
export const Content = ({ setIsEmpty, setContent, content, addOrEdit }: IProps) => {
  const handleChange = (content: any, delta: any, source: any, editor: any) => {
    setIsEmpty && setIsEmpty(!editor.getText().trim())
    setContent(content);
  };

  return (
    <>
      <div
        style={{ padding: "20px 24px 20px 24px", display: "flex", gap: "20px" }}
      >
        <div style={{ width: "100%", flex: 1 }}>
          <div
            style={{
              fontWeight: "700",
              fontSize: "16px",
              marginBottom: "20px",
            }}
          >
            <span style={{ color: "#ff4d4f", fontSize: "14px" }}>*</span>{" "}
            Content
          </div>
          <ReactQuill
            value={content}
            onChange={handleChange}
            placeholder={""}
            modules={modules}
            formats={formats}
            theme={"snow"} // pass false to use minimal theme
          />
        </div>

        <PreviewMessage content={content} />
      </div>
    </>
  );
};
