import { MessageTab } from "./MessageTab";
import { ReminderTab } from "./ReminderTab";
import { SendingListTab } from "./SendingListTab";
import * as S from "./styled";

export const ChatbotManagement = () => {
  const onChangeTab = (key: string) => {
    console.log(key);
  };
  return (
    <S.Wrapper>
      <S.ChatbotTop>
        <S.ChatbotTitle>Chatbot Management</S.ChatbotTitle>
        <S.CustomTabs
          defaultActiveKey="1"
          onChange={onChangeTab}
          // destroyInactiveTabPane={true}
          items={[
            {
              label: `Message`,
              key: "1",
              children: <MessageTab />,
            },
            // {
            //   label: `Reminder`,
            //   key: "2",
            //   children: <ReminderTab />,
            // },
            // {
            //   label: `Sending List`,
            //   key: "3",
            //   children: <SendingListTab />,
            // },
          ]}
        />
      </S.ChatbotTop>
    </S.Wrapper>
  );
};
