import { Button, DatePicker, Form, message } from "antd";
import * as S from "../styled";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IconBack } from "assets/svg";
import { useEffect, useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import moment from "moment";
import _ from "lodash";
import { ChatbotWindow } from "./ChatbotWindow";
import {
  createChat2Insight,
  delChat2Insight,
  getChat2Insight,
  updateChat2Insight,
} from "services/chatToInsight";
import { IChartItem } from "types/chatToInsight";
import { ConfirmModal } from "../components/ConfirmModal/ConfirmModal";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const InforgraphicEdit = () => {
  const navigate = useNavigate();
  const [openDelConfirm, setOpenDelConfirm] = useState<boolean>(false);
  const [openBackConfirm, setOpenBackConfirm] = useState<boolean>(false);
  const [openUpdateConfirm, setOpenUpdateConfirm] = useState<boolean>(false);
  const [openPublishConfirm, setOpenPushlishConfirm] = useState<boolean>(false);
  const [openHiddenConfirm, setOpenHiddenConfirm] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [form] = Form.useForm();
  const isDroppedRef = useRef<boolean>(false);
  const [droppingItem, setDropptingItem] = useState<{
    i: string;
    w: number;
    h: number;
    image: string;
    id: string;
    description: string;
    title: string;
  }>();
  const [status, setStatus] = useState<string>();
  const route = useLocation();
  const params = useParams();
  const isCreated = route.pathname === "/chat2insight/create";
  const id = params?.id || "";
  const roles = sessionStorage.getItem("roles");

  useEffect(() => {
    if (roles === "ADMIN") {
      navigate("/tracking");
    }
  }, []);

  const [layouts, setLayout] = useState<IChartItem[]>([]);

  const getChat2InsightDetail = async () => {
    try {
      const res = await getChat2Insight({ campaign_id: id });

      if (res?.length > 0) {
        setLayout(res[0].charts);
        setStatus(res[0].status);
        form.setFieldsValue({
          name: res[0].name,
          id: res[0].id,
          status: res[0].status,
          date: moment(res[0].publish_date),
        });

        res[0].charts.forEach((item) => {
          form.setFieldsValue({
            [`name-${item.i}`]: item.title,
            [`desc-${item.i}`]: item.description,
          });
        });
      }
    } catch (error) {
      setLayout([]);
    }
  };

  useEffect(() => {
    if (id) {
      getChat2InsightDetail();
    }
  }, []);

  const onFinish = async (values: any) => {
    try {
      const chartData = layouts.map((item) => {
        return {
          i: item.i,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          id: item.id,
          description: form.getFieldValue(`desc-${item.i}`),
          title: form.getFieldValue(`name-${item.i}`),
        };
      });
      const data = {
        name: values.name,
        status: values.status,
        charts: chartData,
        ...(!isCreated ? { campaign_id: id } : null),
      };

      if (isCreated) {
        await createChat2Insight(data);
        message.success("Successfully create inforgraphic");
      } else {
        await updateChat2Insight(data);
        message.success("Successfully update inforgraphic");
      }
      navigate("/chat2insight?tab=manage");
    } catch (error) {
      if (isCreated) {
        message.error("Create inforgraphic fail");
      } else {
        message.error("Update inforgraphic fail");
      }
    }
  };
  const onLayoutChange = (layout: any) => {
    if (!isDroppedRef.current) {
      return;
    }

    const temp = layouts.map((item) => {
      const data = layout.find((item2: any) => item.i === item2.i);
      return {
        ...item,
        x: data.x as number,
        y: data.y as number,
        w: data.w as number,
        h: data.h as number,
      };
    });

    setLayout(temp);
  };
  const onDrop = (layout: any, layoutItem: any, _event: any) => {
    if (!droppingItem) return;
    isDroppedRef.current = true;
    const temp = layout.map((item: any) => {
      const data = [...layouts, droppingItem].find(
        (item2: any) => item.i === item2.i
      );
      return {
        ...item,
        ...data,
      };
    });

    setLayout(temp);
  };

  const onDragStart = (e: any, des: string, image: string, msgId: string) => {
    setDropptingItem({
      i: moment().unix().toString(),
      w: 4,
      h: 4,
      image,
      id: msgId,
      description: des,
      title: "",
    });
    form.setFieldValue(`desc-${moment().unix().toString()}`, des);
    isDroppedRef.current = false;
    return e.dataTransfer.setData("text/plain", "");
  };

  const removeItem = (i: string) => {
    setLayout((prev) => _.reject(prev, { i: i }));
  };

  const handleCreate = async (status: string) => {
    try {
      await form.validateFields();
      if (!layouts?.length) {
        message.error("Please create at least 1 block before Publish");
        return;
      }
      if (status === "published") {
        setOpenPushlishConfirm(true);
        return;
      }
      form.setFieldValue("status", status);
      form.submit();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (status: string) => {
    try {
      await form.validateFields();
      if (!layouts?.length) {
        message.error("Please create at least 1 block before Publish");
        return;
      }
      if (status === "published") {
        setOpenPushlishConfirm(true);
        return;
      }
      if (status === "update") {
        setOpenUpdateConfirm(true);
        return;
      }
      if (status === "hidden") {
        setOpenHiddenConfirm(true);
        return;
      }
      form.setFieldValue("status", status);
      form.submit();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await delChat2Insight(id);
      navigate("/chat2insight?tab=manage");
      message.success("Successfully delete inforgraphic");
    } catch (error) {
      message.success("Delete inforgraphic fail");
    }
  };

  const handleBack = () => {
    if (isTouched) {
      setOpenBackConfirm(true);
      return;
    }
    navigate("/chat2insight?tab=manage");
  };

  return (
    <S.Wrapper>
      <S.ChatbotTop>
        <S.ChatbotTitle>Chat2Insight management</S.ChatbotTitle>
      </S.ChatbotTop>

      <div className="chat2insight-body">
        {/* <div
          className="droppable-element"
          draggable={true}
          unselectable="on"
          // this is a hack for firefox
          // Firefox requires some kind of initialization
          // which we can do by adding this attribute
          // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
          onDragStart={onDragStart}
        >
          Droppable Element (Drag me!)
        </div> */}
        <div style={{ paddingRight: "24px", width: "60%" }}>
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            onFieldsChange={(changedValue, allFields) => {
              if (changedValue.length > 0) {
                setIsTouched(true);
              }
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #D9D9D9",
                  paddingBottom: "12px",
                }}
              >
                <div style={{ display: "flex", gap: "8px" }}>
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                    onClick={handleBack}
                  >
                    <span>
                      <IconBack />
                    </span>{" "}
                    Back
                  </Button>
                </div>

                {isCreated ? (
                  <div style={{ display: "flex", gap: "8px" }}>
                    <S.CustomBtn onClick={() => handleCreate("draft")}>
                      Save draft
                    </S.CustomBtn>
                    <Button
                      type="primary"
                      style={{
                        padding: "8px 16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleCreate("published")}
                    >
                      Publish
                    </Button>
                  </div>
                ) : (
                  <div style={{ display: "flex", gap: "8px" }}>
                    <S.CustomBtn
                      style={{ color: "#F5222D" }}
                      onClick={() => setOpenDelConfirm(true)}
                    >
                      Delete
                    </S.CustomBtn>
                    {status === "draft" && (
                      <S.CustomBtn onClick={() => handleUpdate("draft")}>
                        Save draft
                      </S.CustomBtn>
                    )}
                    {status === "published" && (
                      <S.CustomBtn onClick={() => handleUpdate("hidden")}>
                        Hide
                      </S.CustomBtn>
                    )}
                    {status === "published" && (
                      <Button
                        type="primary"
                        style={{
                          padding: "8px 16px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleUpdate("update")}
                      >
                        Update
                      </Button>
                    )}
                    {status !== "published" && (
                      <Button
                        type="primary"
                        style={{
                          padding: "8px 16px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleUpdate("published")}
                      >
                        Publish
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{ display: "flex", gap: "16px", paddingTop: "10px" }}
                  >
                    <Form.Item
                      label="Infographic name"
                      name="name"
                      style={{ flexGrow: "8", fontWeight: "500" }}
                      rules={[
                        { required: true },
                        {
                          max: 50,
                          message:
                            "Infographic name can only contain up to 50 characters",
                        },
                      ]}
                    >
                      <S.CustomInput style={{ height: "40px" }} />
                    </Form.Item>
                    <Form.Item
                      label="Publish date"
                      name="date"
                      style={{ flexGrow: "2", fontWeight: "500" }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker style={{ width: "100%", height: "40px" }} />
                    </Form.Item>
                    <Form.Item
                      label="Status"
                      name="status"
                      style={{ flexGrow: "2", fontWeight: "500" }}
                    >
                      <S.CustomInput
                        style={{ height: "40px" }}
                        disabled={true}
                      />
                    </Form.Item>
                  </div>
                  {/* <Button
                  onClick={addNewItem}
                  style={{
                    height: "40px",
                    borderRadius: "2px",
                    border: "1px solid #D9D9D9",
                    padding: "8px 16px",
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <span>+</span>
                  Add block
                </Button> */}

                  <ResponsiveGridLayout
                    isDraggable={true}
                    isResizable={true}
                    preventCollision={false}
                    isDroppable={true}
                    breakpoints={{
                      lg: 1200,
                      md: 996,
                      sm: 768,
                      xs: 480,
                      xxs: 0,
                    }}
                    cols={{ lg: 8, md: 8, sm: 8, xs: 8, xxs: 4 }}
                    onLayoutChange={onLayoutChange}
                    onDragStart={() => (isDroppedRef.current = true)}
                    onResizeStart={() => (isDroppedRef.current = true)}
                    onDrop={onDrop}
                    droppingItem={droppingItem}
                    compactType="horizontal"
                    style={{ minHeight: "200px" }}
                  >
                    {layouts.map((item) => (
                      <S.ItemWrapper key={item.i} data-grid={item}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            pointerEvents: "none",
                          }}
                        >
                          <img
                            src={item.image}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                        {/* <Form.Item
                        name={`chart-type-${item.i}`}>
                        <Select
                          defaultValue="weekly"
                          onChange={handleChange}
                          size="large"
                          options={[{ value: "weekly", label: "Jack" }]}
                        />
                      </Form.Item> */}
                        <Form.Item
                          label="Chart name"
                          name={`name-${item.i}`}
                          style={{ width: "100%", fontWeight: "500" }}
                          rules={[
                            { required: true },
                            {
                              max: 120,
                              message:
                                "Chart name can only contain up to 120 characters",
                            },
                          ]}
                        >
                          <S.CustomInput
                            style={{ height: "40px" }}
                            placeholder="Enter name"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Description & analytics"
                          name={`desc-${item.i}`}
                          style={{ width: "100%", fontWeight: "500" }}
                          rules={[
                            { required: true },
                            {
                              max: 1000,
                              message:
                                "Description & analytics can only contain up to 1000 characters",
                            },
                          ]}
                        >
                          <S.CustomTextArea
                            rows={2}
                            placeholder="Enter description"
                          />
                        </Form.Item>
                        <span
                          onClick={() => removeItem(item.i)}
                          className="remove"
                          style={{
                            cursor: "pointer",
                            color: "#F5222D",
                            fontWeight: 700,
                          }}
                        >
                          Delete block
                        </span>
                      </S.ItemWrapper>
                    ))}
                  </ResponsiveGridLayout>
                </div>
                <div></div>
              </div>
            </div>
          </Form>
          <div></div>
        </div>
        <ChatbotWindow onDragStart={onDragStart} />
      </div>
      <ConfirmModal
        submit={() => {
          form.setFieldValue("status", "hidden");
          form.submit();
        }}
        isOpen={openHiddenConfirm}
        setIsOpen={setOpenHiddenConfirm}
        cancelText="Cancel"
        submitText="Hide"
        content="Are you sure to hide this infographic?"
      />
      <ConfirmModal
        submit={handleDelete}
        isOpen={openDelConfirm}
        setIsOpen={setOpenDelConfirm}
        cancelText="Cancel"
        submitText="Delete"
        content="Are you sure to delete this infographic?"
      />
      <ConfirmModal
        submit={() => {
          form.setFieldValue("status", "published");
          form.submit();
        }}
        isOpen={openPublishConfirm}
        setIsOpen={setOpenPushlishConfirm}
        cancelText="Cancel"
        submitText="Publish"
        content="Are you sure to publish this infographic?"
      />
      <ConfirmModal
        submit={() => {
          form.setFieldValue("status", "published");
          form.submit();
        }}
        isOpen={openUpdateConfirm}
        setIsOpen={setOpenUpdateConfirm}
        cancelText="Cancel"
        submitText="Update"
        content="Are you sure to update this infographic?"
      />
      <ConfirmModal
        submit={() => {
          navigate("/chat2insight?tab=manage");
        }}
        isOpen={openBackConfirm}
        setIsOpen={setOpenBackConfirm}
        cancelText="Cancel"
        submitText="Back to list"
        content={
          isCreated
            ? "Your inforgraphic will not be saved. Are you sure to go back to infographic list?"
            : "Your update will not be saved. Are you sure to go back to infographic list?"
        }
      />
    </S.Wrapper>
  );
};
