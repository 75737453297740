import styled from "styled-components";

export const DocumentWrapper = styled.div`
  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-height: 100vh;
    overflow-y: auto; */
  }
`;
