import { Content } from "features/Chatbot/components/Content";
import { Schedule } from "./components/Schedule";
import * as S from "./styled";
import { MenuFooter } from "features/Chatbot/components/MenuFooter";
import { useEffect, useState } from "react";
import {
  createChatbotSurvey,
  deleteChatbotSurvey,
  getChatbotSurvey,
  updateChatbotSurvey,
} from "services/chatbot";
import { Button, message } from "antd";
import { IChatbotSurveyAdd, IChatSurvey, submitStatus } from "types/chatbot";
import { reportActions } from "store";
import { useDispatch } from "react-redux";
import { ConfirmModal } from "./ConfirmModal";

interface IProps {
  updateCount: (item: any) => void;
  addOrEdit: string;
  detailData: IChatSurvey;
  setDetail: (item: any) => void;
}
export const MessageSettings = ({
  updateCount,
  addOrEdit,
  detailData,
  setDetail,
}: IProps) => {
  const [scheduleData, setScheduleData] = useState<any>();
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [contentData, setContentData] = useState<string>("");
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [inputContent, setInputContent] = useState(false);
  const [currentTab, setCurrentTab] = useState("schedule");

  const handleSubmit = (status: string) => {
    if (status === "paused" || status === "running") {
      setIsOpen(true);
      setStatus(status);
    } else if (contentData == undefined) {
      setIsOpen(true);
      setInputContent(true);
    } else {
      console.log("create");
      setStatus(status);
      createAndUpdate();
    }
  };

  const createAndUpdate = () => {
    setTriggerSubmit(true);
  };

  const handleDelete = () => {
    setIsOpen(true);
    setStatus(submitStatus.delete);
  };
  const deleteItem = async () => {
    try {
      console.log("confirm", confirm);
      await deleteChatbotSurvey(detailData.id);
      message.success("Delete successfully");
      updateCount((prev: any) => prev + 1);
      setConfirm(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeTab = (key: string) => {
    setCurrentTab(key);
  };
  const renderFooter = (status: string) => {
    if (status === "draft") {
      return (
        <S.SubmitWrapper>
          <div style={{ display: "flex", gap: "8px" }}>
            <S.CustomBtn onClick={handleDelete} style={{ color: "#F5222D" }}>
              Delete
            </S.CustomBtn>
            <S.CustomBtn onClick={() => handleSubmit(submitStatus.duplicate)}>
              Duplicate
            </S.CustomBtn>
            <S.CustomBtn onClick={() => handleSubmit(submitStatus.draft)}>
              Save Draft
            </S.CustomBtn>
          </div>
          <div>
            <Button
              onClick={() => handleSubmit(submitStatus.create)}
              type="primary"
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Publish
            </Button>
          </div>
        </S.SubmitWrapper>
      );
    } else if (status === "paused") {
      return (
        <S.SubmitWrapper>
          <div style={{ display: "flex", gap: "8px" }}>
            <S.CustomBtn onClick={handleDelete} style={{ color: "#F5222D" }}>
              Delete
            </S.CustomBtn>
            <S.CustomBtn onClick={() => handleSubmit(submitStatus.duplicate)}>
              Duplicate
            </S.CustomBtn>
            <S.CustomBtn onClick={() => handleSubmit(submitStatus.running)}>
              Enable
            </S.CustomBtn>
          </div>
          <div>
            <Button
              onClick={() => handleSubmit(submitStatus.pause)}
              type="primary"
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              disabled={true}
            >
              Save
            </Button>
          </div>
        </S.SubmitWrapper>
      );
    } else if (status === "done") {
      return (
        <S.SubmitWrapper>
          <div style={{ display: "flex", gap: "8px" }}>
            <S.CustomBtn onClick={handleDelete} style={{ color: "#F5222D" }}>
              Delete
            </S.CustomBtn>
            <S.CustomBtn onClick={() => handleSubmit(submitStatus.duplicate)}>
              Duplicate
            </S.CustomBtn>
          </div>
          <div>
            <Button
              disabled={true}
              type="primary"
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Save
            </Button>
          </div>
        </S.SubmitWrapper>
      );
    } else {
      return (
        <S.SubmitWrapper>
          <div style={{ display: "flex", gap: "8px" }}>
            <S.CustomBtn onClick={handleDelete} style={{ color: "#F5222D" }}>
              Delete
            </S.CustomBtn>
            <S.CustomBtn onClick={() => handleSubmit(submitStatus.duplicate)}>
              Duplicate
            </S.CustomBtn>
            <S.CustomBtn
              onClick={() => handleSubmit(submitStatus.pause)}
              disabled={
                detailData?.status === submitStatus.draft ||
                detailData?.status === submitStatus.done
              }
            >
              Pause
            </S.CustomBtn>
          </div>
          <div>
            <Button
              onClick={() => handleSubmit(detailData.status)}
              type="primary"
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              disabled={
                detailData?.status === submitStatus.running
              }
            >
              Save
            </Button>
          </div>
        </S.SubmitWrapper>
      );
    }
  };

  useEffect(() => {
    if (scheduleData) {
      callAPi();
    }
  }, [scheduleData]);

  useEffect(() => {
    if (detailData) {
      setContentData(detailData.survey_message);
      setIsEmpty(false);
    }
    if (addOrEdit === "add") {
      setDetail(null);
      setContentData("");
    }
  }, [detailData, addOrEdit]);

  useEffect(() => {
    dispatch(reportActions.listdeparmentall({}));
  }, []);

  const callAPi = async () => {
    try {
      if (isEmpty) {
        message.error("Content is required");
        return;
      }
      const formData = new FormData();
      console.log(detailData);
      let id: any = detailData?.id;
      const data: IChatbotSurveyAdd = {
        name: scheduleData.name,
        note: scheduleData.note,
        survey_message: contentData,
        start_date: scheduleData.start_date,
        end_date: scheduleData.end_date,
        remind_day: scheduleData.remind_day,
        time: scheduleData.time,
        frequency: scheduleData.frequency,
        status: status === "duplicate" ? "draft" : status,
        departments: scheduleData.departments || [],
        send_list: scheduleData.account,
      };

      formData.append("name", data.name);
      formData.append("note", data.note);
      formData.append("survey_message", data.survey_message);
      formData.append("start_date", data.start_date);
      formData.append("end_date", data.end_date);
      formData.append("remind_day", data.remind_day);
      formData.append("time", data.time);
      formData.append("frequency", data.frequency);
      formData.append("status", data.status);
      formData.append("send_list", data.send_list);
      formData.append("departments", data.departments.join(","));

      if (status === "duplicate") {
        console.log(status);
        id = undefined;
      }
      if (scheduleData.file !== undefined) {
        formData.append("file", scheduleData.file.file.originFileObj);
      }
      if (id) {
        formData.append("id", detailData.id);
      }

      // for (const key in data) {
      //     if (data.hasOwnProperty(key)) {
      //         console.log(key, data[key]);
      //         formData.append(key.toString(), `${data[key]}`);
      //     }
      // }
      if (!id) {
        await createChatbotSurvey(formData);
        message.success("Add successfully");
      } else {
        await updateChatbotSurvey(formData);
        message.success("Edit successfully");
      }

      updateCount((prev: any) => prev + 1);
    } catch (error: any) {
      console.log(error);
      message.error(error?.response?.data?.message || "Save unsuccessfully.");
    }
  };

  return (
    <div style={{ flexGrow: "1" }}>
      <S.CustomTitle>Message setting</S.CustomTitle>
      <S.CustomTabs
        defaultActiveKey="1"
        onChange={onChangeTab}
        // destroyInactiveTabPane={true}
        items={[
          {
            label: `Schedule`,
            key: "schedule",
            children: (
              <Schedule
                detailData={detailData}
                addOrEdit={addOrEdit}
                setTriggerEvent={setTriggerSubmit}
                triggerEvent={triggerSubmit}
                content={contentData}
                setScheDuleData={setScheduleData}
                currentTab={currentTab}
              ></Schedule>
            ),
          },
          {
            label: `Content`,
            key: "content",
            children: (
              <Content
                setIsEmpty={setIsEmpty}
                addOrEdit={addOrEdit}
                content={contentData}
                setContent={setContentData}
              ></Content>
            ),
          },
        ]}
      />
      {addOrEdit === "add" ? (
        <S.SubmitWrapper style={{ justifyContent: "flex-end" }}>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button onClick={() => handleSubmit(submitStatus.draft)}>
              Save Draft
            </Button>
            <Button
              onClick={() => handleSubmit(submitStatus.create)}
              type="primary"
              htmlType="submit"
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Publish
            </Button>
          </div>
        </S.SubmitWrapper>
      ) : (
        renderFooter(detailData?.status)
      )}
      <ConfirmModal
        isOpen={isOpen}
        confirmStatus={status}
        setIsOpen={setIsOpen}
        deleteItem={deleteItem}
        createAndUpdate={createAndUpdate}
        checkInputContent={inputContent}
        setInputContent={setInputContent}
      />
    </div>
  );
};
