import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  message,
  Select,
  TimePicker,
  TimePickerProps,
  TreeSelect,
  Upload,
  UploadProps,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import * as S from "./styled";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { PreviewMessage } from "features/Chatbot/components/PreviewMessage";
import { useSelector } from "react-redux";
import { reportActions, reporttSelector } from "store";
import { useEffect, useState } from "react";
import { IconUpload } from "assets/svg";
import moment from "moment";
import { IChatSurvey, repeatType } from "types/chatbot";
import { time } from "console";

dayjs.extend(customParseFormat);

interface IProps {
  setScheDuleData: (item: any) => void;
  triggerEvent: boolean;
  setTriggerEvent: (item: any) => void;
  addOrEdit: string;
  detailData: IChatSurvey;
  content: string;
  currentTab: string;
}
export const dummyRequest = async ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const props: UploadProps = {
  name: "file",
  customRequest: dummyRequest,
  listType: "picture",
  onChange(info) {
    const maxFileSize = 12 * 1024 * 1024;
    if (info.file.size && info.file.size > maxFileSize) {
      message.error(`${info.file.name} file upload failed (exceeds 250MB)`);
      console.error("File size exceeds the limit of 250MB");
      return false;
    }
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
  },
};

const initValues = {
  name: "",
  note: "",
  status: "",
  start_date: "",
  end_date: "",
  period: [],
  frequency: "",
  remind_day: null,
  time: "",
  departments: undefined,
  account: [],
};
export const Schedule = ({
  setScheDuleData,
  triggerEvent,
  setTriggerEvent,
  addOrEdit,
  detailData,
  content,
  currentTab
}: IProps) => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const lstDeparment = useSelector(reporttSelector.seleclstDeparmentall);
  let deparmentlstall = lstDeparment?.result;
  const { SHOW_PARENT } = TreeSelect;

  const [valuedepart, setValueDepart] = useState([] as any);
  const [changeDeparment, setChangeDeparment] = useState(false);
  const [repeatOption, setRepeatOption] = useState<string>("daily");
  const [period, setPeriod] = useState<string[]>();

  console.log("detailData", detailData);
  console.log("repeatOption", repeatOption);

  const repeatTypeArray = Object.values(repeatType).map((status) => ({
    value: status.toLowerCase(),
    label: status,
  }));

  const onchangeDepar = (newValuedeparment: string[]) => {
    setValueDepart(newValuedeparment);
    setChangeDeparment(true);
  };

  const tPropDepartment = {
    treeData: deparmentlstall,
    value: valuedepart,
    onChange: onchangeDepar,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Please select",

    style: {
      width: "100%",
    },
  };
  const handleChange = (value: string) => {
    form.setFieldValue("date", null);
    setRepeatOption(value);
  };

  const onChange: TimePickerProps["onChange"] = (time, timeString) => {
    console.log(time, timeString);
  };

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };

  const onRangeChange = (date: any, dateStrings: any) => {
    if (date) {
      setPeriod(dateStrings);
    } else {
      console.log("Clear");
    }
  };

  const onFinish = (values: any) => {
    const item: any = {
      name: values.name,
      note: values.note,
      start_date: moment(values.period[0]).format("YYYY-MM-DD"),
      end_date: moment(values.period[1]).format("YYYY-MM-DD"),
      frequency: values.repeat,
      remind_day:
        typeof values.date === "string"
          ? values.date
          : moment(values.date || new Date()).format("YYYY-MM-DD"),
      time: values.time.format("HH:mm"),
      departments: values.department,
      account: values.account,
      file: values.file,
    };
    setScheDuleData(item);
  };

  const handleRepeatChange = (value: string) => {
    console.log("handleRepeatChange");
    // form.setFieldValue('date', '')
    switch (value) {
      case "onetime":
        return (
          <DatePicker
            onChange={onDateChange}
            style={{ width: "100%", height: "40px" }}
            disabledDate={(current) => {
              return period
              ? (current && current < moment(period[0]).startOf("day")) ||
                  current > moment(period[1]).endOf("day")
              : false;
            }}
          />
        );

        break;
      case "daily":
        return (
          <DatePicker
            disabled
            style={{ width: "100%", height: "40px" }}
            disabledDate={(current) => {
              return period
              ? (current && current < moment(period[0]).startOf("day")) ||
                  current > moment(period[1]).endOf("day")
              : false;
            }}
          />
        );

        break;
      case "weekly":
        return (
          <Select
            options={[
              { value: "Mon", label: "Monday" },
              { value: "Tue", label: "Tuesday" },
              { value: "Wed", label: "Wednesday" },
              { value: "Thu", label: "Thusday" },
              { value: "Fri", label: "Friday" },
              { value: "Sat", label: "Saturday" },
              { value: "Sun", label: "Sunday" },
            ]}
            size="large"
          />
        );
        break;
      case "monthly":
        return (
          <DatePicker
            defaultPickerValue={moment(period && period[0])}
            onChange={onDateChange}
            style={{ width: "100%", height: "40px" }}
            disabledDate={(current) => {
              return period
                ? (current && current < moment(period[0]).startOf("day")) ||
                    current > moment(period[1]).endOf("day")
                : false;
            }}
          />
        );
        break;
      case "yearly":
        return (
          <DatePicker
            onChange={onDateChange}
            style={{ width: "100%", height: "40px" }}
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}
          />
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (triggerEvent) {
      form.submit();
      setTriggerEvent(false);
    }
  }, [triggerEvent]);

  useEffect(() => {
    if (addOrEdit === "add") {
      form.resetFields();
    }
  }, [addOrEdit]);

  useEffect(() => {
    if (detailData) {
      setRepeatOption(detailData.frequency);
      form.setFieldsValue({
        name: detailData.name,
        note: detailData.note,
        status: detailData.status,
        next_send_time: detailData?.next_send_time ? dayjs(detailData.next_send_time).format("YYYY-MM-DD HH:mm") : '',
        created_time: detailData.created_date,
        period: [moment(detailData.start_date), moment(detailData.end_date)],
        repeat: detailData.frequency,
        date: detailData.frequency === 'weekly' ? detailData.remind_day : moment(detailData.remind_day),
        time: dayjs(detailData.time, "HH:mm"),
        department: detailData.departments,
        account: detailData.send_list,
      });
      setPeriod([detailData.start_date, detailData.end_date])
      setValueDepart(detailData?.departments);
    } else {
      form.setFieldsValue({
        name: initValues.name,
        note: initValues.note,
        status: initValues.status,
        created_time: initValues.start_date,
        period: initValues.period,
        repeat: initValues.frequency,
        date: initValues.remind_day,
        time: initValues.time,
        department: initValues.departments,
        account: initValues.account,
      });
    }
  }, [detailData]);

  return (
    <div
      style={{ padding: "20px 24px 20px 24px", display: "flex", gap: "20px" }}
    >
      <div style={{ width: "100%" }}>
        <Form
          layout="vertical"
          style={{
            width: "100%",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
          }}
          onFinish={onFinish}
          onFinishFailed={() => {
            console.log(currentTab)
            if (currentTab === 'content') {
              message.error("You need to fill all required fields on schedule tab.");
            }
          }}
          form={form}
          initialValues={initValues}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <div style={{ width: "100%", flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  marginBottom: "12px",
                }}
              >
                <div style={{ fontWeight: "700", fontSize: "16px" }}>
                  General
                </div>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <S.CustomInput maxLength={150} />
                </Form.Item>
                <Form.Item label="Note" name="note">
                  <TextArea rows={3} maxLength={150} />
                </Form.Item>
                {addOrEdit === "edit" ? (
                  <>
                    <Form.Item label="Status" name="status">
                      <S.CustomInput
                        style={{ backgroundColor: "#F5F5F5" }}
                        readOnly
                      />
                    </Form.Item>
                    <Form.Item label="Next send time" name="next_send_time">
                      <S.CustomInput
                        style={{ backgroundColor: "#F5F5F5" }}
                        readOnly
                      />
                    </Form.Item>
                    <Form.Item label="Created time" name="created_time">
                      <S.CustomInput
                        style={{ backgroundColor: "#F5F5F5" }}
                        readOnly
                      />
                    </Form.Item>
                  </>
                ) : (
                  <></>
                )}
                <div style={{ border: "1px dashed #D9D9D9" }}></div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  marginBottom: "12px",
                }}
              >
                <div style={{ fontWeight: "700", fontSize: "16px" }}>Time</div>
                <Form.Item
                  label="Period"
                  name="period"
                  rules={[{ required: true, message: "Please choose period!" }]}
                >
                  <RangePicker
                    style={{ height: "40px", width: "100%" }}
                    onChange={onRangeChange}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current < moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Repeat"
                  name="repeat"
                  rules={[{ required: true, message: "Please choose repeat!" }]}
                >
                  <Select
                    onChange={handleChange}
                    size="large"
                    options={repeatTypeArray}
                  />
                </Form.Item>
                <Form.Item
                  label="Date"
                  name="date"
                  rules={[
                    {
                      required: repeatOption !== "daily",
                      message: "Please choose date!",
                    },
                  ]}
                >
                  {repeatOption && handleRepeatChange(repeatOption)}
                </Form.Item>
                <Form.Item
                  label="Time"
                  name="time"
                  rules={[{ required: true, message: "Please choose time!" }]}
                >
                  <TimePicker
                    onChange={onChange}
                    style={{ height: "40px", width: "100%" }}
                  />
                </Form.Item>
                <div style={{ border: "1px dashed #D9D9D9" }}></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div style={{ fontWeight: "700", fontSize: "16px" }}>
                  Recipient
                </div>
                <Form.Item
                  label="FSU,BU"
                  name="department"
                  rules={[
                    { required: true, message: "Please choose department!" },
                  ]}
                >
                  <TreeSelect
                    size="large"
                    {...tPropDepartment}
                    treeDefaultExpandAll
                    allowClear
                    showSearch
                    value={valuedepart}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{
                      overflow: "auto",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Account"
                  name="account"
                  rules={[{ required: true, message: "Please input account!" }]}
                >
                  <S.CustomInput />
                </Form.Item>
                <Form.Item label="File excel" name="file">
                  <Upload {...props}>
                    <Button
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span>File name</span>
                      <span>
                        <IconUpload />
                      </span>
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
            <PreviewMessage content={content} />
          </div>
        </Form>
      </div>
    </div>
  );
};
