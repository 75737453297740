import React from 'react'
import ExcelJS from 'exceljs';
import { Button } from 'antd';
type Props = {
    dataExport: any;
}

const ExportExelBul = (props: Props) => {
const rowData = props?.dataExport;

const processedRowData = rowData?.map((row: any) => {
    
      const processedSystem = row.System_Note
      ? row.System_Note.split("~")
          .map((line: any) => "- " + line.trim())
          .join("\n")
      : "";
    const processedChurnReason = row.Churn_Reason
      ? row.Churn_Reason.split(",")
          .map((line: any) => "- " + line.trim())
          .join("\n")
      : "";

    return {
      ...row,
    
      System_Note: processedSystem,
      Churn_Reason: processedChurnReason,
    };
  });
const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
   
    const time = new Date();
    const fullyear = time.getFullYear();
    const month = String(time.getMonth() + 1).padStart(2, "0");
    const dateexport = String(time.getDate()).padStart(2, "0");
    const hours = String(time.getHours()).padStart(2, "0");
    const minutes = String(time.getMinutes()).padStart(2, "0");
    const seconds = String(time.getSeconds()).padStart(2, "0");
    const fileName = `export_${fullyear}-${month}-${dateexport}-${hours}_${minutes}_${seconds}`;
    const worksheet = workbook.addWorksheet(fileName);

    const resultArray = [];

    const headerRow = [];
    for (var key in processedRowData?.[0]) {
      headerRow.push(key);
    }
    resultArray.push(headerRow);

    for (var i = 0; i < processedRowData?.length; i++) {
      var dataRow = [];
      for (var key in processedRowData[i]) {
        dataRow.push(processedRowData[i][key]);
      }
      resultArray.push(dataRow);
    }

    worksheet.addRows(resultArray);

    for (let colNumber = 1; colNumber <= headerRow.length; colNumber++) {
      worksheet.getColumn(colNumber).width = 30;
    }
    const rowToStyle = worksheet.getRow(1);
    rowToStyle.height = 50;
    rowToStyle.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      // Bôi đậm chữ
      cell.font = { bold: true };
      cell.alignment = { wrapText: true };

      // Đổ màu nền
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '21b84e' }, // Mã màu hex
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
   
    for (let i = 2; i <= resultArray.length; i++) {
      const headerRowRange = worksheet.getCell(`A${i}`);
      headerRowRange.alignment = { wrapText: true };

      headerRowRange.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    }
    for (let i = 2; i <= resultArray.length; i++) {
      const headerRowRangeB2 = worksheet.getCell(`B${i}`);
      headerRowRangeB2.alignment = { wrapText: true };

      headerRowRangeB2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    }
    for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`C${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`D${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`E${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`F${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`G${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`H${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`I${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`J${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`K${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`L${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`M${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      for (let i = 2; i <= resultArray.length; i++) {
        const headerRowRangeB2 = worksheet.getCell(`N${i}`);
        headerRowRangeB2.alignment = { wrapText: true };
  
        headerRowRangeB2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      } 
      


    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <div>
        <Button type='text' onClick={exportToExcel}>Export To Excel</Button>
    </div>
  )
}

export default ExportExelBul