import { Modal } from "antd";
import styled from "styled-components";

export const Wrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  span.anticon.anticon-close.ant-modal-close-icon {
    display: none;
  }
  .ant-modal-body {
    height: 180px;
  }
`;
