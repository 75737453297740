import { Tabs } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 56px;
  flex: 1;
`;

export const ChatbotTop = styled.div`
  background-color: #f5f5f5;
  padding: 20px 24px 0;
`;

export const ChatbotTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

export const CustomTabs = styled(Tabs)`
  margin: 0 -24px;
  .ant-tabs-nav {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0;
  }
  .ant-tabs-content-holder {
    background-color: #ffffff;
  }
`;

