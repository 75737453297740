import {
  ChatBotHeader,
  ChatBotWrap,
  ChatBotBody,
  ChatBotMessageReceive,
  ChatBotFooter,
} from "../styled";
import chatbotLogo from "assets/images/chatbot-logo.png";
import faceLogo from "assets/images/face-logo.png";
import happiLogo from "assets/images/happi-logo.png";
import subtract from "assets/images/subtract.png";
import menuIcon from "assets/images/menu.png";
import sendIcon from "assets/images/send-icon.png";
import { useEffect, useRef, useState } from "react";
import { Form, Input } from "antd";
import { getChatbotMsg, readMsg, sendMsg } from "services/chatbot";
import { IChatBotBody, IChatInfor, IReadMsgRequest } from "types/chatbot";
import { isArray } from "lodash";
import { TypingMsg } from "./styled";
import { ChatContent } from "./ChatContent";
import { IconStarPurple } from "assets/svg";
import dayjs from "dayjs";

interface Props {
  isOpened: boolean;
  openNoti: (msg: string) => void;
  setIsOpened: (isOpen: boolean) => void;
  setOpenRatingModal: (isOpen: boolean) => void;
  setNumberNoti: (numberNoti: number) => void;
}

export const Chatbot = (props: Props) => {
  const { isOpened, setIsOpened, openNoti, setNumberNoti, setOpenRatingModal } =
    props;
  const [form] = Form.useForm();
  const [isOpenSuggestion, setIsOpenSuggestion] = useState<boolean>(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMsg, setIsLoadingMsg] = useState(false);
  const [listOldChat, setListOldChat] = useState<IChatInfor[]>([]);
  const listOldChatRef = useRef<IChatInfor[]>([]);
  const listChatRef = useRef<IChatInfor[]>([]);
  const hasNewMsg = useRef<boolean>(false);
  const [listChat, setListChat] = useState<IChatInfor[]>([
    {
      content_message: `<p>Hey there! I'm Happi, your friendly chatbot buddy! <img
        src="${faceLogo}"
        alt="chatbot logo"
        height="15px"
        style="margin-bottom: 4px;"
      /> Can't wait to have some fun with you and answer all your questions. Let's get started!</p>`,
      from: "happi-bot",
    },
  ]);
  let timer: any;
  const isLoadingCheckIsRead = useRef<boolean>(false);
  const userLogin = sessionStorage.getItem("UserEmail");

  useEffect(() => {
    scrollIntoView();
    if (isOpened) {
      openNoti("");
      setNumberNoti(0);
      handleIsReadMsg();
    }
  }, [isOpened]);

  const scrollIntoView = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const handleScroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleIsReadMsg();
    }, 500);
  };

  useEffect(() => {
    listChatRef.current = [...listChat]
  }, [listChat])

  const getChatbot = async (type: string) => {
    try {
      setIsLoading(true);
      let data = await getChatbotMsg(type); /* list_message, new_message */
      if (isArray(data) && data?.length === 0) return;
      hasNewMsg.current = data?.some((item) => item.is_read === "no");
      if (data.some((item) => item.is_read === "no")) {
        openNoti("You have a new notification from Happi");
        setNumberNoti(data.filter((item) => item.is_read === "no")?.length);
      }
      if (isOpened) {
        handleIsReadMsg(
          data[data.length - 1].id
        );
      }
      if (type === "list_message") {
        setListOldChat((pre) => [...pre, ...data]);
        listOldChatRef.current = data;
      } else {
        if (isLoadingMsg) return;
        let temp: IChatInfor[] = [];
        for (let i = 0; i < data.length; i++) {
          if (
            ![...listOldChatRef.current, ...listChatRef.current].some(
              (item) => item.id === data[i].id
            )
          ) {
            temp.push(data[i]);
          }
        }
        if (temp.length > 0) {
          setListChat((pre) => {
            let tempNewChat: IChatInfor[] = [];
            if (pre.length > 0) {
              for (let i = 0; i < temp.length; i++) {
                if (!pre.some((item) => item?.id === temp[i]?.id)) {
                  tempNewChat.push(data[i]);
                }
              }
            }
            return [...pre, ...tempNewChat];
          });
        }
      }
      scrollIntoView();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChatbot("list_message");
    const interval = setInterval(() => {
      getChatbot("new_message");
    }, 120000);
    return () => clearInterval(interval);
  }, []);

  const listSuggestion = [
    "Job hot hôm nay",
    "Range lương thị trường IT",
    "Công ty tuyển dụng nhiều",
  ];

  const handleSelectSuggestion = (item: string) => {
    setSelectedSuggestion(item);
    form.setFieldValue("msgContent", item);
    form.submit();
    setIsOpenSuggestion(false);
  };

  const openSuggestion = () => {
    // setSelectedSuggestion("");
    // setIsOpenSuggestion((prev) => !prev);
  };

  const onFinish = (values: any) => {
    if (!values?.msgContent || isLoadingMsg) return;
    setListChat((pre) => [
      ...pre,
      {
        from: userLogin || "",
        content_message: values.msgContent,
        is_read: "no",
      },
    ]);
    form.setFieldValue("msgContent", "");
    scrollIntoView();
    handleSendMsg(values.msgContent);
  };

  const handleSendMsg = async (msg: string) => {
    setIsLoadingMsg(true);
    try {
      const data: IChatBotBody = {
        request_type: "happi_send_message",
        message: msg,
      };
      scrollIntoView();
      const res = await sendMsg(data);
      if (res.length > 0) {
        hasNewMsg.current = true
        handleIsReadMsg(res[res.length - 1].id);
        setListChat((pre) => [...pre, ...res]);
      }
      setIsLoadingMsg(false);
      scrollIntoView();
    } catch (error) {
      console.log(error);
      // if (listChat.length > 0) {
      //   setListChat((pre) => {
      //     pre[pre.length - 1].isError = true;
      //     return pre;
      //   });
      // }
      setIsLoadingMsg(false);
    }
  };

  const handleIsReadMsg = async (id?: string) => {
    if (!hasNewMsg.current || isLoadingCheckIsRead.current) return;
    try {
      isLoadingCheckIsRead.current = true;
      let tempId = id;
      if (!id) {
        if (listOldChat.length > 0) {
          tempId =
            listOldChat[listOldChat.length - 1].is_read === "no"
              ? listOldChat[listOldChat.length - 1].id
              : undefined;
        }
        if (listChat.length > 1) {
          tempId =
            listChat[listChat.length - 1].is_read === "no"
              ? listChat[listChat.length - 1].id
              : undefined;
        }
      }
      if (!tempId) return;
      const data: IReadMsgRequest = {
        request_type: "happi_latest_message_read",
        timestamp: dayjs().unix()
      };
      const res = await readMsg(data);
      setNumberNoti(0);
      openNoti("");
      hasNewMsg.current = res.status === "complete";
      isLoadingCheckIsRead.current = false;
    } catch (error) {
      console.log(error);
      isLoadingCheckIsRead.current = false;
    }
  };

  return (
    <ChatBotWrap style={{ display: isOpened ? "block" : "none" }}>
      <ChatBotHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "12px" }}>
            <img
              src={chatbotLogo}
              alt="chatbot logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div>
            <img src={happiLogo} alt="happi logo" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={() => setOpenRatingModal(true)}
          >
            <IconStarPurple />
          </div>
          <div onClick={() => setIsOpened(false)} style={{ cursor: "pointer" }}>
            <img src={subtract} />
          </div>
        </div>
      </ChatBotHeader>
      <ChatBotBody onClick={() => handleIsReadMsg()} onScroll={handleScroll}>
        <ChatContent listMsg={listOldChat} />
        <ChatContent listMsg={listChat} />
        {isLoadingMsg && (
          <ChatBotMessageReceive>
            <div style={{ width: "32px" }}>
              <img
                src={chatbotLogo}
                alt="chatbot logo"
                width={"32px"}
                height={"32px"}
              />
            </div>
            <div className="content">
              <TypingMsg>
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </TypingMsg>
            </div>
          </ChatBotMessageReceive>
        )}
        <div ref={messagesEndRef} />
      </ChatBotBody>
      <ChatBotFooter onClick={() => handleIsReadMsg()}>
        {isOpenSuggestion && (
          <div className="message-options">
            {listSuggestion.map((item) => (
              <div
                key={item}
                className={`message ${selectedSuggestion === item && "active"}`}
                onClick={() => handleSelectSuggestion(item)}
              >
                {item}
              </div>
            ))}
          </div>
        )}
        <img
          src={menuIcon}
          style={{ cursor: "pointer" }}
          onClick={openSuggestion}
        />
        <Form
          form={form}
          onFinish={onFinish}
          style={{ display: "flex", width: "100%" }}
        >
          <Form.Item
            name="msgContent"
            rules={[{ required: true, message: "" }]}
            style={{ flex: 1, marginLeft: "16px" }}
          >
            <Input placeholder="Type Message..." />
          </Form.Item>
          <Form.Item>
            <button type="submit">
              <img src={sendIcon} style={{ cursor: "pointer" }} />
            </button>
          </Form.Item>
        </Form>
      </ChatBotFooter>
    </ChatBotWrap>
  );
};
