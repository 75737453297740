

import { Form, message, ModalProps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportActions, reporttSelector } from "store";
import { Wrapper } from "./style";
import { memo } from "react";

type Props = ModalProps & {
  setVisible: (value: boolean) => void;
  onDone: () => void;
  EmployeeID?: any;
  inputComments?: any;
  setIsRender?: any;
  pred_date?: any;
  id: any;
};

type FormType = {};

const ModelEdit = (props: Props) => {

  const [form] = Form.useForm<FormType>();
  const onOk = async () => form.submit();
  const dispatch = useDispatch<any>();
  const onCancel = () => onInvisible();
  const [initialValues, setInitialValues] = useState("");
  const employee_id = props.EmployeeID;
  const pred_date = props.pred_date;
  const updatecomment = useSelector(reporttSelector.selectItem);
  



  const onInvisible = () => {
    props.setVisible(false);
    form.resetFields();
  };
  const onFinish = async (inputData: any) => {
    const comment = inputData.Comment;

    const data = {
      employee_id: employee_id,
      pred_date: pred_date,
      comment: comment ? comment : "",
    };
    try {
      await new Promise((res) =>
        dispatch(
          reportActions.saveComment({
            params: data,
            onSuccess: (res) => {
              props.setVisible(false);

              props.onDone();

              message.success("Save comment successfully");
            },
          })
        )
      );
    } catch (error) {
      message.error("Save comment fail");
    }
  };
  useEffect(() => {
    setInitialValues(props.inputComments);
  }, [props]);
  return (
    <Form form={form} onFinish={onFinish} layout="vertical" preserve={false}>
      <Wrapper
        {...props}
        centered
        destroyOnClose
        confirmLoading={updatecomment?.loading}
        onCancel={onCancel}
        onOk={onOk}
        width={500}
      >
        <Form.Item name="Comment">
          <TextArea title="Note" rows={6} defaultValue={initialValues} />
        </Form.Item>
      </Wrapper>
    </Form>
  );
};

export default memo(ModelEdit);
