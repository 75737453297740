import { createSlice } from "@reduxjs/toolkit";
import { importData, previewUpload } from "./actions";

const initialState: any = {
  listhrpr: {
    loading: false,
    result: [],
  },
  listhrprreview: {
    loading: false,
    result: [],
  },

  listbul: {
    loading: false,
    result: [],
  },
};

export const slice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(importData.pending, (state) => {
      state.listhrpr.loading = true;
      state.listhrpr.result = initialState.listhrpr.result;
      state.listhrpr.error = {};
    });
    builder.addCase(importData.fulfilled, (state, action) => {
      state.listhrpr.loading = false;
      state.listhrpr.result = action.payload;
      state.listhrpr.error = {};
    });
    builder.addCase(importData.rejected, (state, action) => {
      state.listhrpr.loading = false;
      state.listhrpr.error = action.error;
      state.listhrpr.result = initialState.listhrpr.result;
    });
    builder.addCase(previewUpload.pending, (state) => {
      state.listhrprreview.loading = true;
      state.listhrprreview.result = initialState.listhrprreview.result;
      state.listhrprreview.error = {};
    });
    builder.addCase(previewUpload.fulfilled, (state, action) => {
      state.listhrprreview.loading = false;
      state.listhrprreview.result = action.payload;
      state.listhrprreview.error = {};
    });
    builder.addCase(previewUpload.rejected, (state, action) => {
      state.listhrprreview.loading = false;
      state.listhrprreview.error = action.error;
      state.listhrprreview.result = initialState.listhrprreview.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  importData,
  previewUpload,
};

export const { reducer } = slice;
