export const buildUrl = (url: string, parameters: any): string => {
  let qs = "";
  for (const key in parameters) {
    const value = parameters[key];
    if (value !== "") {
      qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1);
    url = url + "?" + qs;
  }
  return url;
};

export const bytesToSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)}${sizes[i]}`;
};

export const compareByAlph = (a: string, b: string) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

export const dummyRequest = async ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok', file);
  }, 1000);
};
