import {
  DatePicker,
  DatePickerProps,
  Input,
  Select,
  Space,
  Typography,
  Image,
  Divider,
} from "antd";
import { RankingComponent } from "../RankingComponent";
import * as S from "./styled";
import Table, { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { Leadership_1 } from "assets/images";
import { useEffect, useState } from "react";
import { LeaderBoardContent } from "../LeaderBoardContent";
import { ILeadership } from "types/leadership";
import { getLeadership } from "services/leadership";
import { NotFoundArea } from "../NotFoundArea";
import moment from "moment";

export const LeadershipTab = ({ tabType }: { tabType: string }) => {
  const { Title, Paragraph } = Typography;
  const { Search } = Input;
  const [isLoading, setIsLoading] = useState(false);
  const [originalData, setOriginalData] = useState<ILeadership[]>([]);
  const [dataShow, setDataShow] = useState<ILeadership[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedDate, setDate] = useState<string>("");

  const columns: ColumnsType<ILeadership> = [
    {
      title: "No",
      dataIndex: "no",
      width: 60,
      render: (value: string, record: ILeadership, index: number) => {
        const no = (currentPage - 1) * 10 + index + 1;
        return (
          <div>
            <span className="pl-2">{no}</span>
          </div>
        );
      },
    },
    {
      title: "BU",
      dataIndex: "department",
      width: 145,
      sorter: (a, b) => a.department.localeCompare(b.department),
      render: (value: string, record: ILeadership, index: number) => {
        const no = (currentPage - 1) * 10 + index + 1;
        let buName = value;
        return (
          <div>
            <span className="pl-2">{buName}</span>
          </div>
        );
      },
    },
    {
      title: "Full Feedback",
      dataIndex: "point_confirm",
      width: 145,
      sorter: (a, b) => a.point_confirm - b.point_confirm,
      render: (value: number) => `${Math.round(value)}/30`,
    },
    {
      title: "Fast Feedback",
      dataIndex: "time_score",
      width: 145,
      sorter: (a, b) => a.time_score - b.time_score,
      render: (value: number) => `${Math.round(value)}/30`,
    },
    {
      title: "Informative Feedback",
      dataIndex: "point_feedback",
      width: 145,
      sorter: (a, b) => a.point_feedback - b.point_feedback,
      render: (value: number) => `${Math.round(value)}/40`,
    },
    {
      title: "Total score",
      dataIndex: "total_score",
      width: 145,
      sorter: (a, b) => a.total_score - b.total_score,
      render: (value: number, record: ILeadership) =>
        `${Math.round(value)}/100`,
    },
    {
      title: "Rank",
      dataIndex: "rank",
      sorter: (a, b) => a.rank - b.rank,
      defaultSortOrder: "ascend",
      width: 145,
    },
  ];

  useEffect(() => {
    if (originalData) {
      setCurrentPage(1);
    }
  }, [originalData]);

  const onChangeMonth: DatePickerProps["onChange"] = (date) => {
    const dateData = date ? moment(date).format("YYYY-MM") : "";
    setDate(dateData);
    getLeadershipData(dateData);
  };

  const handleChangeSelect = (value: string) => {
    console.log(`selected ${value}`);
  };

  const getLeadershipData = async (date?: string) => {
    try {
      setIsLoading(true);
      const data = await getLeadership(
        date || "",
        tabType === "A" ? "campain_large" : "campain_small"
      );
      setDate(data?.leaderboard_period);
      if (moment('2024-05-01').unix() < moment(`${data?.leaderboard_period}-02`).unix()) {
        setOriginalData(data?.data);
        setDataShow(data?.data);
      } else {        
        setOriginalData([]);
        setDataShow([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOriginalData([]);
      setDataShow([]);
    }
  };

  useEffect(() => {
    getLeadershipData();
  }, []);

  const onSearch = (value: string) => {
    const newList: ILeadership[] = originalData.filter((item) =>
      item.department.toLowerCase().includes(value.toLowerCase())
    );
    setDataShow(newList);
  };

  const handleChangePage = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current ?? 1);
  };

  return (
    <S.LeadershipTabWrapper>
      <RankingComponent
        data={originalData.sort((a, b) => a.rank - b.rank)}
        tabType={tabType}
        selectedDate={selectedDate}
      />
      <S.LeadershipContent>
        <S.LeadershipLeft>
          <Title level={4} style={{ fontWeight: 500 }}>
            Detail Leadership Board
          </Title>
          <S.LeadershipActionTable>
            <Space direction="horizontal">
              <DatePicker
                onChange={onChangeMonth}
                picker="month"
                size="large"
                style={{ width: 140 }}
                format={"YYYY - MM"}
                value={selectedDate ? moment(selectedDate) : null}
                allowClear={false}
              />
              {/* <Select
                defaultValue="Campaign 01"
                size="large"
                style={{ width: 180 }}
                onChange={handleChangeSelect}
                options={[
                  {
                    value: "Campaign 01",
                    label: "Campaign 01",
                  },
                  {
                    value: "Campaign 02",
                    label: "Campaign 02",
                  },
                  {
                    value: "Campaign 03",
                    label: "Campaign 03",
                  },
                ]}
              /> */}
            </Space>
            <Search
              placeholder="Search BU"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              style={{ width: 304 }}
            />
          </S.LeadershipActionTable>
          <Table
            columns={columns}
            dataSource={dataShow}
            loading={isLoading}
            pagination={{
              position: ["bottomLeft"],
              current: currentPage,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
            }}
            onChange={handleChangePage}
            // scroll={isLoading ? { x: 800 } : undefined}
            // scroll={{ y: 240 }}
            // scroll={{ x: 'max-content' }}
            // style={{ width: "100%" }}
            locale={{
              emptyText: <NotFoundArea />,
            }}
            rowKey={(o) => o?.department}
          />
        </S.LeadershipLeft>
        <S.LeadershipRight>
          <Leadership_1 />
          <LeaderBoardContent tabType={tabType} />
        </S.LeadershipRight>
      </S.LeadershipContent>
    </S.LeadershipTabWrapper>
  );
};
