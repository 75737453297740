import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "assets/styles/style.scss";
import Loading from "components/Loading/Loading";
import Tracking from "features/Report/containers/Tracking";
import AddEmployee from "features/Report/components/AddEmployee/AddEmployee";
import axios from "axios";
import { APP_VERSION } from "version";
import { Leadership } from "features/Leadership/Leadership";
import { ChatbotManagement } from "features/Chatbot/ChatbotManagement";
import { Chat2Insight } from "features/Chat2Insight/Chat2Insight";
import { Affix, Button, Image } from "antd";
import chatbotLogo from "assets/images/chatbot-logo.png";
import { WindowChat } from "features/Chatbot/WindowChat";
import { Chat2InsightDemo } from "features/Chat2Insight/Chat2Insight-demo";
import { Inforgraphic } from "features/Inforgraphic/Inforgraphic";
import { InforgraphicEdit } from "features/Inforgraphic/Inforgraphic Management/InforgraphicEdit";
import { CVMatchMaker } from "features/CVMatchMaker/CVMatchMaker";
import { HappiBuddy } from "features/HappiBuddy/HappiBuddy";

const Login = React.lazy(() => import("features/Login/Login"));
const Home = React.lazy(() => import("features/Home/Home"));

const NotRole = React.lazy(() => import("features/NotRole/NotRole"));

const AdminPage = React.lazy(
  () => import("features/AdminPage/containers/AdminPage")
);
const AddUser = React.lazy(
  () => import("features/AdminPage/components/AddUser/AddUser")
);
const EditUser = React.lazy(
  () => import("features/AdminPage/components/EditUser/EditUser")
);
const WraperLayout = React.lazy(
  () => import("components/WraperLayout/WraperLayout")
);
const UploadExel = React.lazy(
  () => import("features/Report/components/UploadExel/UploadExel")
);

const Router = ({ version }: any) => {
  const [apiVersion, setApiVersion] = useState<any>();

  const endpointRequest = `${process.env.REACT_APP_API_URL}/spacex/hello-request`;

  const checkAppVersion = async () => {
    if (apiVersion && apiVersion !== APP_VERSION) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload();
    }
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_IS_DEMO ? "HappiHRAI" : "SpaceX";
    checkAppVersion();
  }, []);

  useEffect(() => {
    const getRequest = async () => {
      try {
        const response = await axios({
          method: "POST",
          url: endpointRequest,
          data: {
            version: version,
          },
        });
        setApiVersion(response?.headers);
      } catch (error) {
        console.log(error);
      }
    };

    getRequest();
  }, []);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Login />} />

          <Route path="/tracking" element={<WraperLayout />}>
            <Route index element={<Tracking />} />
            <Route path="add" element={<AddEmployee />} />
            <Route path="upload" element={<UploadExel />} />
          </Route>

          <Route path="/admin" element={<WraperLayout />}>
            <Route index element={<AdminPage />} />

            <Route path="add" element={<AddUser />} />
            <Route path="edit/:email" element={<EditUser />} />
            <Route path="chatbot" element={<ChatbotManagement />} />
          </Route>

          <Route path="/dashboard" element={<WraperLayout />}>
            <Route index element={<Home />} />
          </Route>

          <Route path="/leadership" element={<WraperLayout />}>
            <Route index element={<Leadership />} />
          </Route>
          <Route path="cv-matchmaker" element={<WraperLayout />}>
            <Route index element={<CVMatchMaker />} />
          </Route>
          <Route path="happi-buddy" element={<WraperLayout />}>
            <Route index element={<HappiBuddy />} />
          </Route>

          <Route path="/chat2insight" element={<WraperLayout />}>
            <Route index element={<Inforgraphic />} />
            <Route path="create" element={<InforgraphicEdit />} />
            <Route path="edit/:id" element={<InforgraphicEdit />} />
          </Route>

          <Route path="no_role" element={<WraperLayout />}>
            <Route index element={<NotRole />} />
          </Route>
        </Routes>
      </Suspense>

      <WindowChat />
    </>
  );
};

export default Router;
