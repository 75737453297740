import styled from "styled-components";

export const LeaderBoardContentWrap = styled.div`
  .leadershipRight-content {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 20px;
  }
`;

export const MedalGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MedalItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* .ranking-img {
    margin-bottom: 20px;
  } */
  .ranking-name {
    font-size: 16px;
    font-weight: 600;
  }
  .ranking-reward {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Notice = styled.span`
  display: block;
  padding-left: 40px;
`;
