import { useState } from "react";
import { MenuMessages } from "../components/MenuMessages";
import { MessageSettings } from "./MessageSettings";

export const MessageTab = () => {
  const [addOrEdit, setAddOrEdit] = useState<string>("edit");
  const [detailData, setDetailData] = useState<any>();
  const [count, setCount] = useState<number>(0);

  return (
    <div
      style={{ display: "flex", gap: "20px", padding: "20px 24px 20px 24px" }}
    >
      <MenuMessages
        setAdd={setAddOrEdit}
        setDetail={setDetailData}
        increaseCount={count}
        detailData={detailData}
      />
      <MessageSettings
        updateCount={setCount}
        addOrEdit={addOrEdit}
        detailData={detailData}
        setDetail={setDetailData}
      />
    </div>
  );
};
