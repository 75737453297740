import { createSelector } from "@reduxjs/toolkit";
import { State } from "./type";

const selectSelf = (state: any) => state.upload;

export const selector = {
  selectProjectData: createSelector(selectSelf, (state) => state),
  selectListHrpr: createSelector(selectSelf, (state) => state.listhrpr),
  selectListBul: createSelector(selectSelf, (state) => state.listbul),
  selectListHrprReview: createSelector(
    selectSelf,
    (state) => state.listhrprreview
  ),
};
