import { Button, Input, Modal, Tabs } from "antd";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 56px;
  flex: 1;
  .chat2insight-body {
    display: flex;
    padding: 20px 24px;
  }
  .droppable-element {
    width: 150px;
    text-align: center;
    background: #fdd;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
  }

  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.react-grid-placeholder {
    background: grey;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .hide-resize-icon .react-grid-item > .react-resizable-handle {
    display: none;
  }

  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 5px;
    right: 5px;
    cursor: se-resize;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
`;

export const ChatbotTop = styled.div`
  background-color: #f5f5f5;
  padding: 20px 24px 0;
`;

export const ChatbotTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

export const CustomTabs = styled(Tabs)`
  margin: 0 -24px;
  .ant-tabs-nav {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0;
  }
  .ant-tabs-content-holder {
    background-color: #ffffff;
  }
`;

export const HappiWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  padding: 12px 20px;
  background-color: #f9f0ff;
  align-items: center;
  justify-content: space-around;
`;

export const HappiTitle = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 16px 0;
`;

export const ChartItem = styled.div`
  border-radius: 16px;
  padding: 20px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

export const CustomText = styled.span`
  font-weight: 500;
  color: #722ed1;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d9d9d9;
  padding: 12px 0 0 0;
`;

export const CustomBtn = styled(Button)`
  height: 32px;
  padding: 4px 16px 4px 16px;
  gap: 16px;
  border-radius: 2px;
  border: 1px dashed #d9d9d9;
`;

export const CustomInput = styled(Input)`
  height: 36px;
  padding: 8px 12px;
  gap: 4px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
`;


export const CustomTextArea = styled(TextArea)`
  padding: 8px 12px;
  gap: 4px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  resize: none;
`;

export const ItemWrapper = styled.div`
  border-radius: 16px;
  padding: 19px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fafafa;
  margin-bottom: 10px;
  justify-content: space-between;
  overflow: auto;
`;
export const CustomModal = styled(Modal)`
  .ant-modal-title {
    text-align: center;
  }
`;
