import notFound from "assets/svg/not-found.svg";
import * as S from "./styled";
import { Image } from "antd";

export const NotFoundArea = () => {
  return (
    <S.NotFoundArea>
      <Image src={notFound} preview={false} />
      <S.NotFoundTitle>No results found</S.NotFoundTitle>
      <S.NotFoundDes>Please try with another keyword</S.NotFoundDes>
    </S.NotFoundArea>
  );
};
