import { Image } from "antd";
import ImgLogin from "./img.png";
import ImgThankYou from "./thankyou.png";
import Leadership1 from "./Leadership_1.png";
import ImgGlobizen from './globizen.png';
import ImgChart from './img_chart.png'
import ChartImg from './ChartImg.png'

export const ImageLogin = () => {
  return <img src={ImgLogin} />;
};

export const ImageThankYou = () => {
  return <img src={ImgThankYou} />;
};

export const Leadership_1 = () => {
  return <Image preview={false} src={Leadership1} />;
};

export const ImageGlobizen = () => {
  return <img src={ImgGlobizen} style={{ width: "100%", height: "auto", borderRadius: "12px" }} />;
};

export const ImageChart = () => {
  return <img src={ImgChart} style={{ width: "100%", height: "auto" }} />;
};

export const ChartDefaultImg = (width?: any) => {
  return <img src={ChartImg} style={{ width: width ? width : "100%", height: "auto" }} />;
};