import {
  IconBuilding,
  IconHumans,
  IconPerson,
  IconTelephone,
  IconEmailOutline,
} from "assets/svg";
import * as S from "../styled";
import { ILeadership } from "types/leadership";
interface IProps {
  data?: ILeadership;
}

export const BUInformation = ({ data }: IProps) => {
  return (
    <div
      style={{
        width: "300px",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "8px",
        padding: "1rem",
        borderRadius: "4px",
        backgroundColor: "#F9F0FF",
      }}
    >
      <S.RowItem>
        <S.ItemDetail>
          <span>
            <IconHumans />
          </span>
          <span style={{ fontWeight: "600" }}>FSU</span>
        </S.ItemDetail>
        <div>{data?.fsu}</div>
      </S.RowItem>
      <S.RowItem>
        <S.ItemDetail>
          <span>
            <IconBuilding />
          </span>
          <span style={{ fontWeight: "600" }}>BU</span>
        </S.ItemDetail>
        <div>{data?.department}</div>
      </S.RowItem>
      <S.RowItem>
        <S.ItemDetail>
          <span>
            <IconPerson />
          </span>
          <span style={{ fontWeight: "600" }}>BUL</span>
        </S.ItemDetail>
        <div>{data?.bul_contact?.replace('@fpt.com', '')}</div>
      </S.RowItem>
      {/* <S.RowItem>
        <S.ItemDetail>
          <span>
            <IconTelephone />
          </span>
          <span style={{ fontWeight: "600" }}>Contact</span>
        </S.ItemDetail>
        <div>096612345</div>
      </S.RowItem> */}
      <S.RowItem>
        <S.ItemDetail>
          <span>
            <IconEmailOutline />
          </span>
          <span style={{ fontWeight: "600" }}>Email</span>
        </S.ItemDetail>
        <div>{data?.bul_contact}</div>
      </S.RowItem>
    </div>
  );
};
