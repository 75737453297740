import { Button, Modal, Rate, message } from "antd";
import { IconHappi, IconTick } from "assets/svg";
import { CustomModal } from "features/Inforgraphic/styled";
import * as S from "../../Chat2Insight/styled";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { sendFeedBack } from "services/chatbot";
import { useLocation } from "react-router-dom";

interface IProps {
  isOpenModal: boolean;
  onClose: () => void;
}

const dataArr = [
  { id: 1, status: false, title: "Correct response" },
  { id: 2, status: false, title: "Quick response" },
  { id: 3, status: false, title: "Really supportive" },
  { id: 4, status: false, title: "Happy mood" },
  { id: 5, status: false, title: "Friendly" },
  { id: 6, status: false, title: "Remind when need" },
  { id: 7, status: false, title: "Not correct response" },
  { id: 8, status: false, title: "Bother" },
];

export const RatingModal = ({ isOpenModal, onClose }: IProps) => {
  const [ratingContent, setRatingContent] = useState(dataArr);
  const [rate, setRate] = useState<number>(0);
  const [otherExperience, setOtherExperience] = useState<string>("");
  
  const route = useLocation();
  const handleClick = (id: number, newStatus: boolean) => {
    setRatingContent((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, status: newStatus } : item
      )
    );
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // console.log('Change:', e.target.value);
    setOtherExperience(e.target.value);
  };

  const submit = async () => {
    const listRatingContent = ratingContent.filter((item) => item.status);
    const data = {
      request_type: "chatbot_rating",
      star_rating: rate.toString(),
      rating_content: listRatingContent.map(item => item.title),
      other_experience: otherExperience,
    };
    try {
      console.log(data);
      const domain = route.pathname === '/happi-buddy' ? 'https://happihr-backend.azurewebsites.net/chatbot/rating' : undefined
      const res = await sendFeedBack(data, domain)
      message.success("Thank for your feedback.");
      onClose()
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <CustomModal
      open={isOpenModal}
      onCancel={onClose}
      width={"800px"}
      footer={null}
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <div style={{ textAlign: "center" }}>
        <div style={{ fontWeight: 700, fontSize: "20px" }}>Rating</div>
        <div>
          Your rating will help Happi improve ability and be more supportive
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "stretch",
          marginTop: "10px",
        }}
      >
        <S.CustomWrapper>
          <IconHappi />
          <div>Happi Assistant</div>
          <div>Rate how Happi assisted you</div>
          <div>
            <Rate allowHalf onChange={setRate} style={{fontSize: '28px'}}/>
          </div>
        </S.CustomWrapper>
        <div style={{ width: "60%" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            <div style={{ fontWeight: 500 }}>Tell us more about Happi:</div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "6px",
                padding: "12px",
                borderRadius: "12px",
                backgroundColor: "#FAFAFA",
              }}
            >
              {ratingContent.map((item) => {
                if (!item.status) {
                  return (
                    <S.ItemWrapper onClick={() => handleClick(item.id, true)}>
                      {item.title}
                    </S.ItemWrapper>
                  );
                } else {
                  return (
                    <S.ItemWrapper
                      onClick={() => handleClick(item.id, false)}
                      style={{
                        padding: "8px 10px",
                        backgroundColor: "#D3ADF7",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <span>{item.title}</span>
                      <span>
                        <IconTick />
                      </span>
                    </S.ItemWrapper>
                  );
                }
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <div style={{ fontWeight: 500 }}>Your other experience</div>
            <div>
              <TextArea
                rows={3}
                style={{ width: "100%" }}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            <Button
              style={{
                height: "40px",
                width: "116px",
                borderRadius: "2px",
                border: "1px solid #D9D9D9",
                padding: "8px 16px",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              style={{
                height: "40px",
                width: "116px",
                borderRadius: "2px",
                border: "1px solid #D9D9D9",
                padding: "8px 16px",
                backgroundColor: "#6E2AFF",
                color: "#fff",
              }}
              onClick={submit}
              disabled={rate===0}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
