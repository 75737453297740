import { IconAICenter, LogoHappi } from "assets/svg";
import { InforgraphicList } from "../components/InforgraphicList";
import * as S from "../styled";
import { Button, message } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FullAnalysis } from "../components/FullAnalysis";
import {
  delChat2Insight,
  getChat2Insight,
  updateChat2Insight,
} from "services/chatToInsight";
import {
  IChartItem,
  IChatToInsight,
  IChatToInsightParam,
} from "types/chatToInsight";
import dayjs from "dayjs";
import { Responsive, WidthProvider } from "react-grid-layout";
import { ConfirmModal } from "../components/ConfirmModal/ConfirmModal";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const InforgraphicManagement = ({isUpdate}: {isUpdate: number}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDelConfirm, setOpenDelConfirm] = useState<boolean>(false);
  const [openHiddenConfirm, setOpenHiddenConfirm] = useState<boolean>(false);
  const [openPublishConfirm, setOpenPublishConfirm] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [publishDate, setPublishDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [layouts, setLayout] = useState<IChartItem[]>([]);
  const [params, setParams] = useState<IChatToInsightParam>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [listChat2Insight, setListChat2Insight] = useState<IChatToInsight[]>(
    []
  );
  const [selectedItem, setSelectedItem] = useState<IChartItem>();

  const getChat2InsightList = async () => {
    try {
      const res = await getChat2Insight(params);
      if (res.length > 0) {
        setSearchParams({ id: res[0].id || "1" });
      } else {
        setLayout([]);
        setTitle("");
      }
      setListChat2Insight(res);
    } catch (error) {
      setListChat2Insight([]);
      console.log(error);
    }
  };

  useEffect(() => {
    getChat2InsightList();
  }, [params, isUpdate]);

  const getChat2InsightDetail = async () => {
    try {
      const res = await getChat2Insight({
        campaign_id: searchParams.get("id") || undefined,
      });
      if (res.length > 0) {
        setLayout(res[0].charts);
        setTitle(res[0].name);
        setStatus(res[0].status);
        setPublishDate(res[0].publish_date || "");
      } else {
        setLayout([]);
        setTitle("");
      }
    } catch (error) {
      setLayout([]);
    }
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      getChat2InsightDetail();
    }
  }, [searchParams.get("id")]);

  const showModal = (item: IChartItem) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    try {
      const res = await delChat2Insight(searchParams.get("id") || undefined);
      getChat2InsightList();
      message.success("Successfully delete inforgraphic");
    } catch (error) {
      message.success("Delete inforgraphic fail");
    }
  };

  const handleUpdate = async (status: string) => {
    try {
      if (!searchParams.get("id")) {
        return;
      }
      const data = {
        name: title,
        status: status,
        publishDate: publishDate,
        charts: layouts,
        campaign_id: searchParams.get("id"),
      };
      await updateChat2Insight(data);
      message.success("Successfully update inforgraphic");
      getChat2InsightList();
      getChat2InsightDetail();
    } catch (error) {
      message.error("Update inforgraphic fail");
    }
  };

  return (
    <div
      style={{ display: "flex", gap: "20px", padding: "20px 24px 20px 24px" }}
    >
      <InforgraphicList
        listChat2Insight={listChat2Insight}
        setParams={setParams}
        params={params}
      />
      {title && (
        <div style={{ width: "100%", paddingBottom: "100px" }}>
          <S.HappiWrapper>
            <div>
              <IconAICenter />
            </div>

            <S.HappiTitle>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "24px",
                  color: "#531DAB",
                }}
              >
                {title}
              </div>
              {status === "published" && (
                <div>Published {dayjs(publishDate).format("DD/MM/YYYY")}</div>
              )}
            </S.HappiTitle>
            <div>
              <span>
                <LogoHappi />
              </span>
            </div>
          </S.HappiWrapper>
          <S.ChartWrapper>
            <ResponsiveGridLayout
              isDraggable={false}
              isResizable={false}
              preventCollision={false}
              breakpoints={{
                lg: 1200,
                md: 996,
                sm: 768,
                xs: 480,
                xxs: 0,
              }}
              cols={{ lg: 8, md: 8, sm: 8, xs: 8, xxs: 4 }}
              compactType="horizontal"
              style={{ minHeight: "200px", width: "100%" }}
              className="hide-resize-icon"
            >
              {layouts.map((item) => (
                <S.ChartItem key={item.i} data-grid={item}>
                  <div>
                    <img
                      src={item.image}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                  <div style={{ fontWeight: 500, fontSize: "24px", wordBreak: "break-word" }}>
                    {item.title}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center ",
                    }}
                  >
                    <div>
                      <S.CustomText
                        style={{ cursor: "pointer" }}
                        onClick={() => showModal(item)}
                      >
                        View full analysis
                      </S.CustomText>
                    </div>
                  </div>
                </S.ChartItem>
              ))}
            </ResponsiveGridLayout>
          </S.ChartWrapper>
          <S.SubmitWrapper>
            <div style={{ display: "flex", gap: "8px" }}>
              <S.CustomBtn
                style={{ color: "#F5222D" }}
                onClick={() => setOpenDelConfirm(true)}
              >
                Delete
              </S.CustomBtn>
              {status === "published" && (
                <S.CustomBtn onClick={() => setOpenHiddenConfirm(true)}>
                  Hide
                </S.CustomBtn>
              )}
              {(status === "hidden" || status === "draft") && (
                <S.CustomBtn onClick={() => setOpenPublishConfirm(true)}>
                  Publish
                </S.CustomBtn>
              )}
            </div>
            <div>
              <Button
                type="primary"
                style={{
                  padding: "8px 16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link to={`/chat2insight/edit/${searchParams.get("id")}`}>
                  Edit
                </Link>
              </Button>
            </div>
          </S.SubmitWrapper>
        </div>
      )}
      <ConfirmModal
        submit={() => handleUpdate("hidden")}
        isOpen={openHiddenConfirm}
        setIsOpen={setOpenHiddenConfirm}
        cancelText="Cancel"
        submitText="Hide"
        content="Are you sure to hide this infographic?"
      />
      <ConfirmModal
        submit={() => handleUpdate("published")}
        isOpen={openPublishConfirm}
        setIsOpen={setOpenPublishConfirm}
        cancelText="Cancel"
        submitText="Publish"
        content="Are you sure to publish this infographic?"
      />
      <ConfirmModal
        submit={handleDelete}
        isOpen={openDelConfirm}
        setIsOpen={setOpenDelConfirm}
        cancelText="Cancel"
        submitText="Delete"
        content="Are you sure to delete this infographic?"
      />
      {selectedItem && (
        <FullAnalysis
          selectedItem={selectedItem}
          isOpenModal={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
};
