import styled from "styled-components";
import { Button as AntButton } from "antd";
export const Customa = styled.a`
  color: #2b88d8;
  font-size: 16px;
`;
export const CustomH2 = styled.h2`
  font-weight: bold;

  margin: 40px 0px;
  text-align: center;
`;
export const ButtonSubmit = styled(AntButton)`
  padding: 5px 15px 5px 15px;
  min-width: 140px;
  width: 209px;
  height: 40px;
  background: #5440b8;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 1.25px;
  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0px;
  border: 1px solid #5440b8;
  &:hover {
    color: #fff;
    border-color: #5440b8;
    background: #5440b8;
    opactity: 0.8;
  }
  &:focus {
    color: #fff;
    border-color: #5440b8;
    background: #5440b8;
    opactity: 0.8;
  }
`;
export const ButtonCancel = styled(AntButton)`
  padding: 5px 15px 5px 15px;
  width: 209px;
  height: 40px;
  background: #fff;
  color: #5440b8;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 1.25px;
  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  line-height: 16px;
  /* margin: 10px; */
  border: 1px solid #f1f1f1;
  &:hover {
    color: #5440b8;
    border-color: #5440b8;
  }
  &:focus {
    color: #5440b8;
    border-color: #5440b8;
  }
`;
export const PostionBtn = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;
export const InputProject = styled.div`
  .ant-input:focus {
    border-color: #2b88d8 !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .ant-input:hover {
    border-color: #2b88d8 !important;
  }
  input.ant-input.ant-input-lg.ant-input-status-success {
    font-size: 14px;
    padding: 8px;
  }
`;
export const WrapperForm = styled.div`
  margin-top: 0;

  padding: 1.5rem;

  min-height: calc(120vh - 135px);
  margin: auto;
  width: 80%;
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    ):before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "";
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;

export const ButtonFeedback = styled(AntButton)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #322e41;
  margin: 5px 0px 5px 0px;
`;
export const ControlBracrum = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
