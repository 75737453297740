import { Button, Form, Input, Modal } from "antd";
import * as S from "../../styled";
import { BUInformation } from "../BuInformation";
import TextArea from "antd/lib/input/TextArea";
import { IconEmail } from "assets/svg";
import { ILeadership, IUpdateBody } from "types/leadership";
import moment from "moment";
import { useEffect } from "react";

interface IProps {
  selectedDate: string;
  data?: ILeadership;
  isBUGradeModal: boolean;
  updateInfo: (data: IUpdateBody) => void;
  onClose: () => void;
  onOpenCongrat: () => void;
}

export const BUGrade = ({
  selectedDate,
  data,
  isBUGradeModal,
  onClose,
  onOpenCongrat,
  updateInfo
}: IProps) => {
  const [form] = Form.useForm();

  const handleAddCancel = () => {
    onClose();
  };
  const onFinish = (value: any) => {
    if (!data) return;
    console.log('value', value)
    const info = {
      bu_name: data.bu_name,
      bu_email: data.bul_contact,
      bu_note: form.getFieldValue('note'),
      leaderboard_period: moment(data.action_time).format('YYYY-MM'),
      request_type: 'bu_send_feedback',
    }
    updateInfo(info)
  };
  const handleCongratulation = () => {
    onOpenCongrat();
    onClose();
  };
  return (
    <Modal
      title={null}
      open={isBUGradeModal}
      onCancel={handleAddCancel}
      width={"800px"}
      footer={null}
    >
      <S.BUGradeHeader>
        <S.ModalTitle>BU grade</S.ModalTitle>
        <S.ModalSubTitle>BU Information for leadership board</S.ModalSubTitle>
      </S.BUGradeHeader>
      <Form
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onFinish}
        form={form}
        initialValues={{
          note: data?.note
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "stretch",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <BUInformation data={data} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              flexGrow: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <S.CustomFormLabel label="Period" name="period">
                <Input
                  disabled
                  placeholder={selectedDate ? moment(selectedDate).format("MMM YYYY") : ""}
                />
              </S.CustomFormLabel>
              <S.CustomFormLabel label="Current rank" name="current_rank">
                <Input disabled placeholder={data?.rank?.toString()} />
              </S.CustomFormLabel>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <S.CustomFormLabel label="Full Feedback" name="full_feedback">
                <Input
                  disabled
                  placeholder={`${Math.round(data?.point_confirm || 0)}/30`}
                />
              </S.CustomFormLabel>
              <S.CustomFormLabel label="Fast Feedback" name="fast_feedback">
                <Input
                  disabled
                  placeholder={`${Math.round(data?.time_score || 0)}/30`}
                />
              </S.CustomFormLabel>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <S.CustomFormLabel
                label="Informative Feedback"
                name="informative_feedback"
              >
                <Input
                  disabled
                  placeholder={`${Math.round(data?.point_feedback || 0)}/40`}
                />
              </S.CustomFormLabel>
              <S.CustomFormLabel label="Total Score" name="total_score">
                <Input
                  disabled
                  placeholder={`${Math.round(data?.total_score || 0)}/100`}
                />
              </S.CustomFormLabel>
            </div>
            <div style={{ width: "100%" }}>
              <S.CustomFormLabel label="Note" name="note">
                <TextArea rows={4} placeholder="Input note" />
              </S.CustomFormLabel>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleCongratulation}
          >
            <div style={{ padding: "8px" }}>
              <IconEmail />
            </div>
            <S.CustomText>Send congratulation </S.CustomText>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Form.Item>
              <Button
                onClick={handleAddCancel}
                style={{
                  padding: "8px, 16px, 8px, 16px",
                  width: "116px",
                  height: "40px",
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  padding: "8px, 16px, 8px, 16px",
                  width: "116px",
                  height: "40px",
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
