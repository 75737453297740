import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addempolyee,
  addempolyeePlain,
  bulUpload,
  bulUploadPlain,
  deleteEmployee,
  exportData,
  fetchReport,
  getFromDate,
  get_search,
  listdeparment,
  listdeparmentall,
  previewUploadBul,
  previewUploadBulPlain,
  saveComment,
  searchAccount,
  updateBulKnown,
  updateChurnIntention,
  updateNotified,
} from "./actions";
import { State } from "./type";

const initialState: State = {
  lstDepart: {
    loading: false,
    result: [],
  },
  list: {
    loading: false,
    result: [],
  },
  lstdepartmentall: {
    loading: false,
    result: [],
  },

  item: {
    loading: false,
    result: undefined,
  },
  delete: {
    loading: false,
  },
  getdate: {
    loading: false,
    result: undefined,
  },
  currentDate: undefined,
};

export const slice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setDate(state, action) {
      state.getdate.result = { from_date: action.payload };
    },
    setCurrentDate(state, action) {
      state.currentDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateChurnIntention.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(updateChurnIntention.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });

    builder.addCase(updateChurnIntention.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });

    builder.addCase(saveComment.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(saveComment.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });

    builder.addCase(saveComment.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
    builder.addCase(fetchReport.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(fetchReport.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(fetchReport.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });
    builder.addCase(listdeparment.pending, (state) => {
      state.lstDepart.loading = true;
      state.lstDepart.result = initialState.list.result;
      state.lstDepart.error = {};
    });
    builder.addCase(listdeparment.fulfilled, (state, action) => {
      state.lstDepart.loading = false;
      state.lstDepart.result = action.payload;
      state.lstDepart.error = {};
    });
    builder.addCase(listdeparment.rejected, (state, action) => {
      state.lstDepart.loading = false;
      state.lstDepart.error = action.error;
      state.lstDepart.result = initialState.lstDepart.result;
    });
    builder.addCase(listdeparmentall.pending, (state) => {
      state.lstdepartmentall.loading = true;
      state.lstdepartmentall.result = initialState.lstdepartmentall.result;
      state.lstdepartmentall.error = {};
    });
    builder.addCase(listdeparmentall.fulfilled, (state, action) => {
      state.lstdepartmentall.loading = false;
      state.lstdepartmentall.result = action.payload;
      state.lstdepartmentall.error = {};
    });
    builder.addCase(listdeparmentall.rejected, (state, action) => {
      state.lstdepartmentall.loading = false;
      state.lstdepartmentall.error = action.error;
      state.lstdepartmentall.result = initialState.list.result;
    });
    builder.addCase(get_search.pending, (state) => {
      state.list.loading = true;
      state.list.result = undefined;
      state.list.error = {};
    });
    builder.addCase(get_search.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(get_search.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = undefined;
    });

    builder.addCase(addempolyee.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });

    builder.addCase(addempolyee.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });

    builder.addCase(addempolyee.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
    // builder.addCase(exportData.pending, (state) => {
    //   state.item.loading = true;
    //   state.item.listExport = undefined;
    //   state.item.error = {};
    // });
    // builder.addCase(exportData.fulfilled, (state, action) => {
    //   state.item.loading = false;
    //   state.item.listExport = action.payload;
    //   state.item.error = {};
    // });
    // builder.addCase(exportData.rejected, (state, action) => {
    //   state.item.loading = false;
    //   state.item.error = action.error;
    //   state.item.listExport = undefined;
    // });

    builder.addCase(deleteEmployee.pending, (state) => {
      state.delete.loading = true;

      state.delete.error = {};
    });
    builder.addCase(deleteEmployee.fulfilled, (state, action) => {
      state.delete.loading = false;

      state.delete.error = {};
    });
    builder.addCase(deleteEmployee.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = action.error;
    });

    builder.addCase(searchAccount.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(searchAccount.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(searchAccount.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });

    builder.addCase(getFromDate.pending, (state) => {
      state.getdate.loading = true;
      state.getdate.result = initialState.getdate.result;
      state.getdate.error = {};
    });
    builder.addCase(getFromDate.fulfilled, (state, action) => {
      state.getdate.loading = false;
      state.getdate.result = action.payload;
      state.getdate.error = {};
    });
    builder.addCase(getFromDate.rejected, (state, action) => {
      state.getdate.loading = false;
      state.getdate.error = action.error;
      state.getdate.result = initialState.getdate.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  get_search,
  fetchReport,
  updateChurnIntention,
  saveComment,
  listdeparment,
  getFromDate,
  addempolyee,
  exportData,
  deleteEmployee,
  updateNotified,
  searchAccount,
  previewUploadBul,
  bulUpload,
  listdeparmentall,
  addempolyeePlain,
  bulUploadPlain,
  previewUploadBulPlain,
  updateBulKnown,
};

export const { reducer } = slice;
