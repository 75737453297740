import {
  DatePicker,
  DatePickerProps,
  Pagination,
  PaginationProps,
  Select,
  Space,
} from "antd";
import * as S from "./styled";
import {
  IconAdd,
  IconArrowLeft,
  IconArrowRight,
  IconInforgraphicList,
} from "assets/svg";
import { useEffect, useState } from "react";
import { getChat2Insight } from "services/chatToInsight";
import { IChatToInsight, IChatToInsightParam } from "types/chatToInsight";
import dayjs from "dayjs";
import { Link, useSearchParams } from "react-router-dom";

interface IProps {
  listChat2Insight: IChatToInsight[], 
  params: IChatToInsightParam,
  setParams: (params: IChatToInsightParam) => void
}

export const InforgraphicList = ({listChat2Insight, params, setParams}: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const onChangeMonth: DatePickerProps["onChange"] = (date, dateString) => {
    setParams({
      ...params,
      month: dateString,
    });
  };
  const handleChangeSelect = (value: string) => {
    setParams({
      ...params,
      status: value,
    });
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageSize(pageSize);
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <a>
          <IconArrowLeft />
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          <IconArrowRight />
        </a>
      );
    }
    return originalElement;
  };
  return (
    <S.MenuWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <S.MenuTitle>Infographics list</S.MenuTitle>
        <Link to="/chat2insight/create">
          <S.AddNewBtn>
            <span style={{height: "14px"}}>
              <IconAdd />
            </span>
            <span>New</span>
          </S.AddNewBtn>
        </Link>
      </div>
      <div>
        <S.MenuAction>
          <Space direction="horizontal" style={{ width: "568px" }}>
            <DatePicker
              onChange={onChangeMonth}
              picker="month"
              size="large"
              style={{ width: 120 }}
            />
            <Select
              defaultValue="All"
              size="large"
              style={{ width: 120 }}
              onChange={handleChangeSelect}
              options={[
                {
                  value: "",
                  label: "All",
                },
                {
                  value: "draft",
                  label: "Draft",
                },
                {
                  value: "published",
                  label: "Publish",
                },
                {
                  value: "hidden",
                  label: "Hidden",
                },
              ]}
            />
          </Space>
        </S.MenuAction>
        <div style={{ marginTop: "20px", fontWeight: "600", fontSize: "12px" }}>
          Showing: {listChat2Insight?.length || 0}
        </div>
      </div>
      <S.CardsWrapper>
        {listChat2Insight
          .slice((currentPage - 1) * pageSize, currentPage * pageSize)
          .map((item, index) => {
            return (
              <Link to={`?id=${item.id}`} key={index}>
                <S.CustomCard
                  className={`${searchParams.get("id") === item.id && "selected"}`}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <S.IconWrapper>
                      <span>
                        <IconInforgraphicList />
                      </span>
                    </S.IconWrapper>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <S.SurveyText>{item.name}</S.SurveyText>
                      <S.TimeText>
                        {dayjs(item.publish_date).format("HH:mm - DD/MM/YYYY")}
                      </S.TimeText>
                    </div>
                  </div>
                  <S.CardStatus>
                    {item.status === "published" && (
                      <span style={{ color: "#389E0D" }}>Published</span>
                    )}
                    {item.status === "draft" && (
                      <span style={{ color: "#1890FF" }}>Draft</span>
                    )}
                    {item.status === "hidden" && (
                      <span style={{ color: "#FAAD14" }}>Hidden</span>
                    )}
                  </S.CardStatus>
                </S.CustomCard>
              </Link>
            );
          })}
      </S.CardsWrapper>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          total={listChat2Insight.length}
          itemRender={itemRender}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          current={currentPage}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </S.MenuWrapper>
  );
};
