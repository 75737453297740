import { createGlobalStyle } from "helpers/styled-component";
import React from "react";

const CssBaseLine = createGlobalStyle`
    html,
    

    body {
        height: 100%;
        width: 100%;
        line-height: 1.35;
        color: #201F1E;
    }
    
.ant-menu-item a {
  color: #201F1E;
}

.ant-menu {
  font-size: 1.14rem;
  color: #201F1E;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  outline: none;
}

button,
input {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "roboto";
  color: #201F1E;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  color: #201F1E;
}

p {
  font-size: 16px;
}

body {
  margin: 0;
  color: #201F1E;
  box-sizing: border-box;
  font-family: "roboto";
}
.wraper {
  max-width: 1200px;
  margin: auto;

}
.ant-table-thead>tr>th {
    color: #201F1E;
}

.page {
  padding-bottom: 75px;
}

.app {
  background: #fff;
  min-height: auto;
  margin: auto;
  padding: 0px;
}


.text-center {
  text-align: center;
}

.uppercasse {
  text-transform: uppercase !important;
}

.text-gradient {
  color: #2828bd;
}

p,
span,
label {
  font-family: "roboto";
    line-height: 1.35em;
}
input, select, textarea {
  font-family: "roboto";
    font-size: inherit;
}
img {
    border-style: none;
    vertical-align: middle;
}
.ant-layout{
  background-color: white !important;
  min-height: auto
}
.ant-layout-header {
   
    padding: 0 50px;
    color: rgba(0,0,0,.85);
    line-height: 56px;
    background: linear-gradient(90.08deg, #5440B8 50%, #2252CD 185.19%);
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 56px;
}
.admin-header .ant-menu-dark.ant-menu-horizontal {
    background: linear-gradient(90.08deg, #5440B8 50%, #2252CD 185.19%);
}
td.ant-table-cell {
  font-family: "roboto";
    color: #201F1E;

}
.ant-select-selector {
  font-family: "roboto";
    color: #201F1E;
}
.mainContent {
    background-color: white;
}
tr.ant-table-row.ant-table-row-level-0 {
    vertical-align: top;
}
.ant-select.ant-select-borderless.ant-select-single.ant-select-show-arrow {
    margin-top: -10px;
}
.ant-select-multiple .ant-select-selection-item-content {
  
    margin-top: 4px;
}
th.ant-table-cell {
    font-weight: bold !important;
}
.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    /* background-color: rgba(0,0,0,.06); */
    transform: translateY(-50%);
    transition: background-color .3s;
    content: "";
    display: none;
}
.ant-col.ant-col-3 {
        margin-top: 20px !important;
    }
.table-confirm {
  & .ant-modal-confirm-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.6rem;

    & svg {
      fill: var(--ant-error-color)
    }
      } 
    }
  .admin-layout {
    min-height: 100vh;
    flex-direction: column;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-upload {
    width: 100%;
  }
`;

type Props = {
  children: React.ReactNode;
};

const GlobalStyled = (props: Props) => {
  return (
    <>
      {props.children}
      <CssBaseLine />
    </>
  );
};

export default GlobalStyled;
