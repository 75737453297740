import { Button, Input } from "antd";
import styled from "styled-components";

export const CustomInput = styled(Input)`
    width: 100%;
    height: 40px;
    padding: 8px 12px 8px 12px;
    gap: 4px;
    border-radius: 2px;
    opacity: 0px;
    border: 1px solid #D9D9D9;
`

export const SubmitWrapper = styled.div`
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    border-top: 1px solid #D9D9D9;
    padding: 12px 0 0 0;
`

export const CustomBtn = styled(Button)`
    height: 32px;
    padding: 4px 16px 4px 16px;
    gap: 16px;
    border-radius: 2px;
    border: 1px dashed #D9D9D9;
`