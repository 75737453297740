import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Chat2InsightDemo } from "features/Chat2Insight/Chat2Insight-demo";
import { PDFPreview } from "components/PDFPreview/PDFPreview";
import {
  copyFile,
  deleteCV,
  downloadCV,
  getAllCVs,
  indexACV,
  indexAICCV,
  uploadAICCV,
  indexToProcess,
  notShareToShare,
  processToHired,
  processToIndex,
  uploadAndIndexCV,
  uploadCV,
} from "services/cvMatchMaker";
import { ICVDetail } from "types/cvMatchMaker";
import { dummyRequest } from "helpers/utils/app";
import { UploadChangeParam } from "antd/lib/upload";
import saveAs from "file-saver";
import moment from "moment";
import dayjs from "dayjs";

const { Search } = Input;
const { confirm } = Modal;

interface Filter {
  username: string;
  search_term?: string;
  department: string;
  hiring_status?: string;
  sharing_status?: string;
  search_key_words?: string;
}

interface SelectedFile {
  user_name: string;
  hiring_status: string;
  file_path: string;
  sharing_status: string;
}

// const props: UploadProps = {
//   name: "file",
//   // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   headers: {
//     authorization: "authorization-text",
//   },
//   onChange(info) {
//     if (info.file.status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (info.file.status === "done") {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };

// const data: DataType[] = [];
// for (let i = 0; i < 11; i++) {
//   data.push({
//     key: i,
//     cvName: `Edward King ${i}`,
//     uploadDate: "17/06/2024",
//     sharingStatus: "Shared",
//     hiringStatus: "Available",
//   });
// }

export const CvManagement = () => {
  const whitelistUser = process.env.REACT_APP_WHITE_LIST || "";
  const userLogin = sessionStorage.getItem("UserEmail");
  let username = userLogin?.replace("@fpt.com", "") || "aic";
  // username = "datdt67";
  const [form] = Form.useForm();

  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [listFilter, setListFilter] = useState<Filter>({
    username: username,
    department: "aic",
  });
  const [listData, setListData] = useState<ICVDetail[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<SelectedFile[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<ICVDetail>();
  // const [status, setStatus] = useState<string | null>(null);
  const [disabledOptions, setDisabledOptions] = useState<string[]>([]);
  const [isSharedDisabled, setIsSharedDisabled] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  // const [fileUploadState, setFileUploadState] = useState<UploadFile | null>(
  //   null
  // );
  const countRef = useRef<number>(0);
  const isShowConfirm = useRef<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[] | undefined>();
  const [AICfileList, setAICFileList] = useState<UploadFile[] | undefined>();

  const handleUpload = async (
    username: string,
    // msg: string,
    isShared: boolean
  ) => {
    try {
      // setIsSendingMsg(true);
      // if (listSelectedFiles.length > 0) {
      //   await beforeSendMsg(msg, username, listSelectedFiles);
      //   return;
      // }
      setIsUploading(true);
      const listPromise =
        fileList &&
        fileList?.length &&
        fileList.map(async (item) => {
          await uploadAndIndexCV(username, item);
          if (isShared) {
            await uploadAndIndexCV("aic", item);
          }
        });
      const res = listPromise && (await Promise.allSettled(listPromise));
      if (res && res.some((item) => item.status === "fulfilled")) {
        // const listPromiseIndexACV = fileList &&
        //   fileList?.length && [
        //     indexACV(
        //       username,
        //       fileList.map((item) => item.name)
        //     ),
        //   ];
        // if (isShared) {
        //   listPromiseIndexACV &&
        //     listPromiseIndexACV.push(
        //       indexACV(
        //         username,
        //         fileList.map((item) => item.name),
        //         "aic",
        //         true
        //       )
        //     );
        // }
        // listPromiseIndexACV && (await Promise.all(listPromiseIndexACV));
        // await beforeSendMsg(
        //   msg,
        //   username,
        //   listCVs.map((item) => item.name)
        // );

        const listPromiseIndexACV = fileList &&
          fileList?.length && [
            indexACV(
              username,
              fileList.map((item) => item.name),
              "aic",
              isShared
            ),
          ];
        listPromiseIndexACV && (await Promise.all(listPromiseIndexACV));
        message.success(`Upload file success`);
      }
      setIsUploading(false);
      await getListMessage();

      // if (res && res.some((item) => item.status === "rejected")) {
      //   res
      //     .filter((item) => item.status === "rejected")
      //     .forEach((item: any) => {
      //       if (item?.reason?.response?.data?.detail) {
      //         message.error(item?.reason?.response?.data?.detail, 3);
      //       } else {
      //         message.error(`Something went wrong!`);
      //       }
      //       // setIsSendingMsg(false);
      //     });
      // }
    } catch (error) {
      setIsUploading(false);
      message.error(`Index file errors!`);
      await getListMessage();
      // setIsSendingMsg(false);
    }
  };

  const handleUploadAICPool = async (
    username: string,
    // msg: string,
    isShared: boolean
  ) => {
    try {
      // setIsSendingMsg(true);
      // if (listSelectedFiles.length > 0) {
      //   await beforeSendMsg(msg, username, listSelectedFiles);
      //   return;
      // }
      const listPromise =
        AICfileList &&
        AICfileList?.length &&
        AICfileList.map(async (item) => {
          await uploadAICCV(username, item, "aic");
          // if (isShared) {
          //   await uploadAndIndexCV("aic", item);
          // }
        });
      const res = listPromise && (await Promise.allSettled(listPromise));
      if (res && res.some((item) => item.status === "fulfilled")) {
        const listPromiseIndexACV = AICfileList &&
          AICfileList?.length && [
            indexAICCV(
              username,
              AICfileList.map((item) => item.name),
              "aic",
              isShared
            ),
          ];
        listPromiseIndexACV && (await Promise.all(listPromiseIndexACV));
        await getListMessage();
        message.success(`Upload successful!`);
      }

      if (res && res.some((item) => item.status === "rejected")) {
        console.log(res);
        res
          .filter((item) => item.status === "rejected")
          .forEach((item: any) => {
            if (item?.reason?.response?.data?.detail) {
              console.log("aa", item?.reason?.response?.data?.detail);
              message.error(item?.reason?.response?.data?.detail);
            } else {
              message.error(`Something went wrong!`);
            }
            // setIsSendingMsg(false);
          });
      }
    } catch (error) {
      message.error(`Index file errors!`);
      await getListMessage();
      // setIsSendingMsg(false);
    }
  };

  const handleFileChange = async (info: UploadChangeParam<UploadFile>) => {
    console.log("info", info.file, info.fileList);
    // const file = info.fileList[0];
    // const fileObj = info.fileList[0].originFileObj;
    // if (info.fileList.length > 0) {
    // const formData = new FormData();
    // if (info.file.status === 'removed' || file.status === 'error') {
    //   setFileUploadState(null);
    // } else {
    //   setFileUploadState(file);
    if (info.file.status === "done") {
      // message.success(`${info.file.name} file uploaded successfully.`);
      console.log("done", info.file);
      // setFileList((prevFileList) => [...prevFileList, info.file]);
    }
    // { fileList: newFileList }) => {
    //   setFileList(newFileList);
    // }
    setFileList(info.fileList);
    countRef.current = info.fileList.length;
    // file && formData.append("pdf_file", file);
    // if (file && file.status !== "uploading" && fileObj) {
    //   try {
    //     const response = await uploadCV(username, fileObj);
    //     message.success("Files uploaded successfully");
    //     getListMessage();
    //   } catch (error: any) {
    //     console.log(error?.response?.data.detail);
    //     // message.error("File upload failed.");
    //     message.error(error?.response?.data.detail);
    //   }
    // }
    // }
  };

  const handleAICCVFileChange = async (info: UploadChangeParam<UploadFile>) => {
    console.log("info", info.file, info.fileList);
    // const file = info.fileList[0];
    // const fileObj = info.fileList[0].originFileObj;
    // if (info.fileList.length > 0) {
    // const formData = new FormData();
    // if (info.file.status === 'removed' || file.status === 'error') {
    //   setFileUploadState(null);
    // } else {
    //   setFileUploadState(file);
    if (info.file.status === "done") {
      // message.success(`${info.file.name} file uploaded successfully.`);
      console.log("done", info.file);
      // setFileList((prevFileList) => [...prevFileList, info.file]);
    }
    // { fileList: newFileList }) => {
    //   setFileList(newFileList);
    // }
    setAICFileList(info.fileList);
    countRef.current = info.fileList.length;
    // file && formData.append("pdf_file", file);
    // if (file && file.status !== "uploading" && fileObj) {
    //   try {
    //     const response = await uploadCV(username, fileObj);
    //     message.success("Files uploaded successfully");
    //     getListMessage();
    //   } catch (error: any) {
    //     console.log(error?.response?.data.detail);
    //     // message.error("File upload failed.");
    //     message.error(error?.response?.data.detail);
    //   }
    // }
    // }
  };

  // const handleFileChange: UploadProps["onChange"] = ({
  //   fileList: newFileList,
  // }) => {
  //   console.log(fileList);
  //   setFileList(newFileList);
  // };

  const handleDownloadCV = async (cv: string) => {
    try {
      const res = await downloadCV(cv);
      if (!res) return;
      // const contentDisposition = res?.headers
      //   ? res.headers["content-disposition"]
      //   : "";
      // console.log("contentDisposition", contentDisposition);
      const blob = new Blob([res.data], { type: "application/pdf" });
      const fileName = cv.split("/")[cv.split("/").length - 1];
      saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCV = async (data: SelectedFile) => {
    try {
      const params = {
        user_name: data.user_name,
        hiring_status: data.hiring_status,
        filename: extractFileName(data.file_path),
      };

      await deleteCV(params);

      if (data.sharing_status === "Shared") {
        const params = {
          user_name: "aic",
          hiring_status: data.hiring_status,
          filename: extractFileName(data.file_path),
        };
        await deleteCV(params);
      }
      message.success(`Delete file success`);
      getListMessage();
    } catch (error) {
      console.log(error);
      message.success(`Delete file success`);
    }
  };

  const props: UploadProps = {
    name: "file",
    accept: ".pdf",
    multiple: true,
    customRequest: dummyRequest,
    onChange: handleFileChange,
    // maxCount: 1,
    showUploadList: false,
    // onDrop(e) {
    //   console.log('Dropped files', e.dataTransfer.files);
    // },
    // beforeUpload: (file: File) => {
    //   const isLt5M = file.size / 1024 / 1024 < 5;
    //   const isValidType = [
    //     'application/msword',
    //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //     'application/vnd.ms-excel',
    //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //   ].includes(file.type);

    //   if (!isLt5M) {
    //     message.error(`File size invalid. CV file maximum size is 5MB`);
    //   }
    //   if (!isValidType) {
    //     message.error(`The file is not in the correct format`);
    //   }

    //   return isLt5M && isValidType ? true : Upload.LIST_IGNORE;
    // },
    // fileList: fileList ? fileList : [],
    fileList,
  };

  const propsAICPool: UploadProps = {
    name: "file",
    // accept:
    //   '.doc,.docx,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    multiple: true,
    customRequest: dummyRequest,
    onChange: handleAICCVFileChange,
    // maxCount: 1,
    showUploadList: false,
    // onDrop(e) {
    //   console.log('Dropped files', e.dataTransfer.files);
    // },
    // beforeUpload: (file: File) => {
    //   const isLt5M = file.size / 1024 / 1024 < 5;
    //   const isValidType = [
    //     'application/msword',
    //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //     'application/vnd.ms-excel',
    //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //   ].includes(file.type);

    //   if (!isLt5M) {
    //     message.error(`File size invalid. CV file maximum size is 5MB`);
    //   }
    //   if (!isValidType) {
    //     message.error(`The file is not in the correct format`);
    //   }

    //   return isLt5M && isValidType ? true : Upload.LIST_IGNORE;
    // },
    // fileList: fileList ? fileList : [],
    fileList: AICfileList,
  };

  const showModal = () => {
    setIsModalEditOpen(true);
  };

  const extractFileName = (filePath: string): string => {
    const match = filePath.match(/[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/);
    return match ? match[0] : filePath;
  };

  const columns: ColumnsType<ICVDetail> = [
    {
      title: "CV Name",
      dataIndex: "file_path",
      render: (text: string) => extractFileName(text),
    },
    {
      title: "Upload date",
      dataIndex: "last_modified",
      render: (value) => {
        return (
          <span>{value ? dayjs(value * 1000).format("DD/MM/YYYY") : ""}</span>
        );
      },
    },
    {
      title: "Sharing status",
      dataIndex: "sharing_status",
    },
    {
      title: "Hiring status",
      dataIndex: "hiring_status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <EyeOutlined onClick={() => setIsModalPreviewOpen(true)} /> */}
          <EditOutlined
            onClick={() => {
              console.log("recordEdit", record);
              setDataEdit(record), showModal();
            }}
          />
          <DeleteOutlined
            onClick={() => {
              console.log("record", record);
              const data = {
                user_name: username,
                hiring_status: record.hiring_status,
                file_path: record.file_path,
                sharing_status: record.sharing_status,
              };
              showConfirmDelete(data);
            }}
          />
        </Space>
      ),
    },
  ];

  const handleOk = () => {
    form.submit();
    // setIsModalEditOpen(false);
    // setDataEdit(undefined);
  };

  const handleCancel = () => {
    setIsModalEditOpen(false);
    setDataEdit(undefined);
  };

  // const onSelectChange = (
  //   newSelectedRowKeys: React.Key[],
  //   selectedRows: ICVDetail[]
  // ) => {
  //   const newSelectedFilePaths = selectedRows.map((row) => row.file_path);
  //   console.log(
  //     "selectedFilePaths changed: ",
  //     newSelectedFilePaths,
  //     selectedRows
  //   );
  //   setSelectedRowKeys(newSelectedFilePaths);
  // };

  // const rowSelection = {
  //   selectedRowKeys: selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: ICVDetail[]) => {
      console.log("selectedRows", selectedRows);
      const newSelectedRowsData = selectedRows.map((row) => ({
        user_name: username,
        hiring_status: row.hiring_status,
        file_path: row.file_path,
        sharing_status: row.sharing_status,
      }));
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRowsData(newSelectedRowsData);
    },
    // getCheckboxProps: (record: DataType) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  const onSearch = (value: string) => {
    setListFilter((prevFilter) => ({
      ...prevFilter,
      search_key_words: value,
    }));
  };

  const onChangeHiringSelect = (value: string) => {
    console.log(value);
    setListFilter((prevFilter) => {
      const newFilter = { ...prevFilter };
      if (value === "") {
        delete newFilter.hiring_status;
      } else {
        newFilter.hiring_status = value;
      }
      return newFilter;
    });
  };
  const onChangeSharingSelect = (value: string) => {
    setListFilter((prevFilter) => {
      const newFilter = { ...prevFilter };
      if (value === "") {
        delete newFilter.sharing_status;
      } else {
        newFilter.sharing_status = value;
      }
      return newFilter;
    });
  };

  const getListMessage = async () => {
    console.log("listFilter", listFilter);
    try {
      const res = await getAllCVs(listFilter);
      // const temp = res.map((item) =>
      //   item.file_path.replace(`cvs_indexed/${username.toLowerCase()}/`, "")
      // );
      // setListCVsShow(temp);
      // setListCVs(temp);
      setListData(res);
      console.log("res", res);
    } catch (error) {}
  };

  const showConfirmDelete = (data?: SelectedFile) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      // content: "Some descriptions",
      onOk() {
        console.log("OK");
        console.log("selectedRowsData", selectedRowsData);

        console.log("datadatadatadatadata", data);
        if (!data) {
          selectedRowsData.forEach((item) => {
            console.log("item delete", item);
            handleDeleteCV(item);
          });
        } else {
          handleDeleteCV(data);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
      okButtonProps: {
        style: { float: "right", marginLeft: "8px" },
      },
      cancelButtonProps: {
        style: { float: "right" },
      },
      okText: "Delete",
    });
  };

  const removeAllSelectedRows = () => {
    setSelectedRowKeys([]);
    setSelectedRowsData([]);
  };

  // const handleChangeHiringStatus = (value: string) => {
  //   if (value === "Available") {
  //     setDisabledOptions(["Available"]);
  //   } else if (value === "Interviewing") {
  //     setDisabledOptions(["Interviewing"]);
  //   } else if (value === "Hired") {
  //     setDisabledOptions(["Available", "Interviewing", "Hired"]);
  //   }
  // };

  const hiringOptions = [
    {
      value: "Hired",
      label: "Hired",
      disabled: disabledOptions.includes("Hired"),
    },
    {
      value: "Available",
      label: "Available",
      disabled: disabledOptions.includes("Available"),
    },
    {
      value: "Interviewing",
      label: "Interviewing",
      disabled: disabledOptions.includes("Interviewing"),
    },
  ];

  const sharingOptions = [
    {
      value: "Shared",
      label: "Shared",
      disabled: isSharedDisabled,
    },
    { value: "Not Shared", label: "Not Shared" },
  ];

  const handleChangeHiringStatus = (value: string) => {
    form.setFieldsValue({ hiring_status: value });
    if (value === "Interviewing" || value === "Hired") {
      form.setFieldsValue({ sharing_status: "Not Shared" });
      setIsSharedDisabled(true);
    } else {
      setIsSharedDisabled(false);
    }
  };

  const handleChangeSharingStatus = (value: string) => {
    form.setFieldsValue({ sharing_status: value });
  };

  const onFinish = async (data: any) => {
    console.log("data", data);
    console.log("dataEdit", dataEdit);
    const dataEditCheck = dataEdit && {
      file_name: extractFileName(dataEdit.file_path),
      last_modified: dataEdit.last_modified
        ? dayjs(dataEdit.last_modified * 1000).format("DD/MM/YYYY")
        : "",
      hiring_status: dataEdit.hiring_status,
      sharing_status: dataEdit.sharing_status,
    };
    if (
      dataEditCheck &&
      JSON.stringify(data) === JSON.stringify(dataEditCheck)
    ) {
      console.log("No changes detected, API calls will not be made.");
      if (data.hiring_status === "Hired") {
        setIsModalEditOpen(false);
        setIsSubmitLoading(false);
        setDataEdit(undefined);
      }
      return;
    }

    try {
      setIsSubmitLoading(true);
      if (dataEdit && data.hiring_status !== dataEdit?.hiring_status) {
        console.log("hiring_status changes detected");

        switch (data.hiring_status) {
          case "Hired":
            await processToHired(username, data.file_name);
            break;
          case "Available":
            const dataParamsAvailable = {
              username: username,
              department: "aic",
              file_name: data.file_name,
              is_shared: data.sharing_status === "Shared" ? true : false,
            };
            console.log(
              `Interviewing/notShare -> Available/is_shared ${data.sharing_status === "Shared" ? true : false}`
            );
            console.log("dataParamsAvailable", dataParamsAvailable);
            await processToIndex(dataParamsAvailable);
            break;
          case "Interviewing":
            const dataParamsInterviewing = {
              username: username,
              department: "aic",
              file_name: data.file_name,
              is_shared: dataEdit.sharing_status === "Shared" ? true : false,
            };
            console.log(`Available/Share -> Interviewing/notShare`);
            console.log("dataParamsInterviewing", dataParamsInterviewing);
            await indexToProcess(dataParamsInterviewing);
            break;

          default:
            break;
        }
      }
      if (
        data.sharing_status !== dataEdit?.sharing_status &&
        data.hiring_status === "Available"
      ) {
        if (data.sharing_status === "Shared") {
          console.log(`Shared only sharing_status Shared`);

          const dataParamsCopySharedOnly = {
            username: username,
            file_name: data.file_name,
            department: "aic",
          };
          const dataParamsAvailableSharedOnly = {
            username: "aic",
            department: "aic",
            file_name: data.file_name,
            is_shared:
              dataEdit && dataEdit.sharing_status === "Shared" ? true : false,
          };
          console.log(
            "data Not Share to Share DataCopy/dataParamsAvailable",
            dataParamsCopySharedOnly,
            dataParamsAvailableSharedOnly
          );
          await copyFile(dataParamsCopySharedOnly);
          await processToIndex(dataParamsAvailableSharedOnly);
        } else {
          const dataParamsNotShare = {
            user_name: "aic",
            hiring_status: dataEdit?.hiring_status as string,
            filename: data.file_name,
          };
          console.log(`Shared only sharing_status Not Share`);
          await notShareToShare(dataParamsNotShare);
        }
      }
      // setDataEdit(undefined);
      setIsModalEditOpen(false);
      setIsSubmitLoading(false);
      await getListMessage();
    } catch (error) {
      console.log("error", error);
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    // if (isShowConfirm.current) return;

    if (
      fileList &&
      (fileList.length = countRef.current) &&
      fileList.every((file) => file.status === "done")
    ) {
      // isShowConfirm.current = true;
      console.log("countRef", countRef);
      console.log(
        "fileListaaaaaa",
        fileList,
        fileList.length,
        countRef.current
      );
      confirm({
        title: "Do you want to share your uploaded CV in the non AIC pool?",
        icon: <ExclamationCircleOutlined />,
        content: "Other users will be able to search for these CVs",
        okText: "Yes",
        cancelText: "No",
        className: "custom-confirm",
        closable: false,
        onOk() {
          handleUpload(username, true);
          setFileList(undefined);
          countRef.current = 0;
        },
        onCancel() {
          handleUpload(username, false);

          setFileList(undefined);
          countRef.current = 0;
        },
      });
      // (fileInputCVsRef.current as HTMLInputElement).value = "";
      // (fileInputJDsRef.current as HTMLInputElement).value = "";
      return;
    }
  }, [fileList]);

  useEffect(() => {
    // if (isShowConfirm.current) return;

    if (
      AICfileList &&
      (AICfileList.length = countRef.current) &&
      AICfileList.every((file) => file.status === "done")
    ) {
      // isShowConfirm.current = true;
      console.log("countRef", countRef);
      console.log(
        "fileListaaaaaa",
        AICfileList,
        AICfileList.length,
        countRef.current
      );
      confirm({
        title: `Do you want to upload ${AICfileList.length} CV(s) to AIC's pool?`,
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        cancelText: "No",
        className: "custom-confirm",
        closable: false,
        onOk() {
          handleUploadAICPool(username, false);
          setAICFileList(undefined);
          countRef.current = 0;
        },
        onCancel() {
          setAICFileList(undefined);
          countRef.current = 0;
        },
      });
      // (fileInputCVsRef.current as HTMLInputElement).value = "";
      // (fileInputJDsRef.current as HTMLInputElement).value = "";
      return;
    }
  }, [AICfileList]);

  useEffect(() => {
    console.log("listFilter", listFilter);
    getListMessage();
  }, [listFilter]);

  // useEffect(() => {
  //   console.log(fileUploadState);
  //   if (fileUploadState) {
  //     confirm({
  //       title: "Do you want to share your uploaded CV in the public pool?",
  //       icon: <ExclamationCircleOutlined />,
  //       content: "Other users will be able to search for these CVs",
  //       okText: "Yes",
  //       cancelText: "No",
  //       className: "custom-confirm",
  //       onOk() {
  //         // handleUpload(username, values.msgContent, true);
  //         // setFileUploadState(null);
  //       },
  //       onCancel() {
  //         // handleUpload(username, values.msgContent, false);
  //         // setFileUploadState(null);
  //       },
  //     });
  //     return;
  //   }
  // }, [fileUploadState]);

  useEffect(() => {
    if (dataEdit) {
      console.log(dataEdit);
      form.setFieldsValue({
        file_name: extractFileName(dataEdit.file_path),
        last_modified: dataEdit.last_modified
          ? dayjs(dataEdit.last_modified * 1000).format("DD/MM/YYYY")
          : "",
        hiring_status: dataEdit.hiring_status,
        sharing_status: dataEdit.sharing_status,
      });
      // setStatus(dataEdit.hiring_status);
      if (dataEdit.hiring_status === "Available") {
        setDisabledOptions(["Hired"]);
        setIsSharedDisabled(false);
      } else if (dataEdit.hiring_status === "Interviewing") {
        setDisabledOptions(["Interviewing"]);
        setIsSharedDisabled(true);
      } else if (dataEdit.hiring_status === "Hired") {
        setIsSharedDisabled(true);
        setDisabledOptions(["Available", "Interviewing", "Hired"]);
      }
    }
  }, [dataEdit]);

  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
          alignItems: "flex-end",
        }}
      >
        <div>
          <Space align={"end"}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "4px" }}>Sharing status</span>
              <Select
                defaultValue=""
                style={{ width: 200 }}
                // onChange={handleChange}
                placeholder="Sharing status"
                onChange={onChangeSharingSelect}
                size="large"
                options={[
                  {
                    value: "Shared",
                    label: "Shared",
                  },
                  {
                    value: "Not Shared",
                    label: "Not Shared",
                  },
                  {
                    value: "",
                    label: "All",
                  },
                ]}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "4px" }}>Hiring status</span>
              <Select
                style={{ width: 200 }}
                defaultValue=""
                placeholder="Hiring Status"
                onChange={onChangeHiringSelect}
                size="large"
                options={[
                  {
                    value: "Hired",
                    label: "Hired",
                  },
                  {
                    value: "Available",
                    label: "Available",
                  },
                  {
                    value: "Interviewing",
                    label: "Interviewing",
                  },
                  {
                    value: "",
                    label: "All",
                  },
                ]}
              />
            </div>

            <Search
              placeholder="Search"
              onSearch={onSearch}
              enterButton
              size="large"
              style={{ width: 200 }}
            />
            {/* <Button type="primary">Button</Button> */}
            {/* <Upload>
            <Button>
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
          <Popconfirm title="Are you sure delete this task?" okText="Yes" cancelText="No">
            <Button>Confirm</Button>
          </Popconfirm> */}
          </Space>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {whitelistUser &&
            whitelistUser.includes((userLogin || "")?.toLocaleLowerCase()) && (
              <Upload {...propsAICPool}>
                <Button
                  type="primary"
                  size="large"
                  icon={<CloudUploadOutlined />}
                  style={{ marginRight: "10px" }}
                >
                  Upload CV to AIC pool
                </Button>
              </Upload>
            )}
          <Upload {...props}>
            <Button
              type="primary"
              size="large"
              icon={<CloudUploadOutlined />}
              // disabled={isUploading}
              loading={isUploading}
            >
              Upload CV
            </Button>
          </Upload>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={listData ? listData : []}
          pagination={{ position: ["bottomLeft"], showSizeChanger: true }}
          rowKey={"file_path"}
        />
        <div style={{ position: "absolute", bottom: "12px", right: 0 }}>
          <Space>
            <Button
              size="large"
              onClick={() => showConfirmDelete()}
              disabled={selectedRowsData && selectedRowsData.length === 0}
            >
              Delete
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                selectedRowsData.forEach((item) => {
                  handleDownloadCV(item.file_path);
                });
                removeAllSelectedRows();
              }}
              disabled={selectedRowsData && selectedRowsData.length === 0}
            >
              Download
            </Button>
          </Space>
        </div>
      </div>
      <Modal
        title="CV information"
        open={dataEdit && isModalEditOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        forceRender
        confirmLoading={isSubmitLoading}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item name="file_name" label="CV file name">
            <Input readOnly disabled />
          </Form.Item>
          <Form.Item name="last_modified" label="Upload date">
            <Input readOnly disabled />
          </Form.Item>
          <Form.Item name="hiring_status" label="Hiring status">
            <Select
              // value={status}
              onChange={handleChangeHiringStatus}
              placeholder="Hiring Status"
              size="large"
              options={hiringOptions}
            />
          </Form.Item>
          <Form.Item name="sharing_status" label="Sharing status">
            <Select
              // defaultValue="lucy"
              // style={{ width: 200 }}
              onChange={handleChangeSharingStatus}
              placeholder="Sharing status"
              size="large"
              options={sharingOptions}
              disabled={
                form.getFieldValue("hiring_status") === "Interviewing" ||
                form.getFieldValue("hiring_status") === "Hired"
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={<div style={{ textAlign: "center" }}>Preview</div>}
        open={isModalPreviewOpen}
        // onOk={() => setIsModalPreviewOpen(false)}
        // onCancel={() => {
        //   setIsModalPreviewOpen(false);
        // }}
        // okText="Save"
        closable={false}
        width={800}
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              console.log("Download");
            }}
          >
            Download
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              console.log("OK");
              setIsModalPreviewOpen(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <PDFPreview />
      </Modal>
    </div>
  );
};
