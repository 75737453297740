import chatbotLogo from "assets/images/chatbot-logo.png";
import happiLogo from "assets/images/happi-logo.png";
import sendIcon from "assets/images/send-icon.png";
import { useEffect, useRef, useState } from "react";
import { Form, Input, message, Select, Tooltip } from "antd";
import { readMsg } from "services/chatbot";
import { IChatInfor, IReadMsgRequest } from "types/chatbot";
import { IconStarPurple } from "assets/svg";
import dayjs from "dayjs";
import {
  ChatBotBody,
  ChatBotContentWrap,
  ChatBotFooter,
  ChatBotHeader,
  ChatBotMessageReceive,
  ChatBotWrap,
  TypingMsg,
} from "./styled";
import { ChatContent } from "features/Chatbot/WindowChat/components/ChatContent";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
  LoadingOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import {
  downloadCV,
  indexACV,
  indexSelectedFiles,
  sendMsgAICCVMatchMaker,
  sendMsgCVMatchMaker,
  sendMsgCVMatchMakerWithTemp,
  uploadAndIndexCV,
} from "services/cvMatchMaker";
import { ICVChatParam } from "types/cvMatchMaker";
import saveAs from "file-saver";
import documentBriefcase from "assets/images/document-briefcase.png";
import { ModalChooseCV } from "components/ModalChooseCV/ModalChooseCV";
import { pdfjs } from "react-pdf";
import confirm from "antd/lib/modal/confirm";

interface Props {
  setOpenRatingModal: (isOpen: boolean) => void;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const Chatbot = (props: Props) => {
  const { setOpenRatingModal } = props;
  const [form] = Form.useForm();
  const [isOpenSuggestion, setIsOpenSuggestion] = useState<boolean>(false);
  const [isOpenSelectCVModal, setIsOpenSelectCVModal] =
    useState<boolean>(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMsg, setIsLoadingMsg] = useState(false);
  const [isSendingMsg, setIsSendingMsg] = useState(false);
  const [listOldChat, setListOldChat] = useState<IChatInfor[]>([]);
  const listOldChatRef = useRef<IChatInfor[]>([]);
  const listChatRef = useRef<IChatInfor[]>([]);
  const hasNewMsg = useRef<boolean>(false);
  const listCVPath = useRef<string[]>([]);
  const [listCVs, setListCVs] = useState<File[]>([]);
  const [listJDs, setListJDs] = useState<File[]>([]);
  const [listSelectedFiles, setListSelectedFiles] = useState<string[]>([]);
  const [lastSessionId, setLastSessionId] = useState<string>();
  const [chatType, setTypeChat] = useState<string>();
  const [jdContent, setJDContent] = useState<string>();
  // const [lastTempIndexName, setLastTempIndexName] = useState<string>();
  const fileInputJDsRef = useRef<HTMLInputElement | null>(null);
  const fileInputCVsRef = useRef<HTMLInputElement | null>(null);
  const [listChat, setListChat] = useState<IChatInfor[]>([
    // {
    //   content_message: `<p>Hey there! I'm Happi, your friendly chatbot buddy! <img
    //     src="${faceLogo}"
    //     alt="chatbot logo"
    //     height="15px"
    //     style="margin-bottom: 4px;"
    //   /> Can't wait to have some fun with you and answer all your questions. Let's get started!</p>`,
    //   from: "happi-bot",
    // },
  ]);
  // let timer: any;
  const isLoadingCheckIsRead = useRef<boolean>(false);
  const userLogin = sessionStorage.getItem("UserEmail");

  useEffect(() => {
    scrollIntoView();
  }, []);

  const scrollIntoView = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    listChatRef.current = [...listChat];
  }, [listChat]);

  // const getChatbot = async (type: string) => {
  //   try {
  //     setIsLoading(true);
  //     let data = await getChatbotMsg(type); /* list_message, new_message */
  //     if (isArray(data) && data?.length === 0) return;
  //     hasNewMsg.current = data?.some((item) => item.is_read === "no");
  //     if (type === "list_message") {
  //       setListOldChat((pre) => [...pre, ...data]);
  //       listOldChatRef.current = data;
  //     } else {
  //       if (isLoadingMsg) return;
  //       let temp: IChatInfor[] = [];
  //       for (let i = 0; i < data.length; i++) {
  //         if (
  //           ![...listOldChatRef.current, ...listChatRef.current].some(
  //             (item) => item.id === data[i].id
  //           )
  //         ) {
  //           temp.push(data[i]);
  //         }
  //       }
  //       if (temp.length > 0) {
  //         setListChat((pre) => {
  //           let tempNewChat: IChatInfor[] = [];
  //           if (pre.length > 0) {
  //             for (let i = 0; i < temp.length; i++) {
  //               if (!pre.some((item) => item?.id === temp[i]?.id)) {
  //                 tempNewChat.push(data[i]);
  //               }
  //             }
  //           }
  //           return [...pre, ...tempNewChat];
  //         });
  //       }
  //     }
  //     scrollIntoView();
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getChatbot("list_message");
  //   const interval = setInterval(() => {
  //     getChatbot("new_message");
  //   }, 120000);
  //   return () => clearInterval(interval);
  // }, []);

  const listSuggestion = [
    "Job hot hôm nay",
    "Range lương thị trường IT",
    "Công ty tuyển dụng nhiều",
  ];

  const handleSelectSuggestion = (item: string) => {
    setSelectedSuggestion(item);
    form.setFieldValue("msgContent", item);
    form.submit();
    setIsOpenSuggestion(false);
  };

  const openSuggestion = () => {
    // setSelectedSuggestion("");
    // setIsOpenSuggestion((prev) => !prev);
  };

  const getContentFromList = (list: File[] | string[]) => {
    console.log(list);
    let content = "";
    for (let i = 0; i < list.length; i++) {
      content =
        content +
        `<div style="
              display: flex;
              color: black;
              background: white;
              align-items: center;
              padding: 8px;
              border-radius: 10px;
              margin: 5px 0;
            "><p style="
              background: #F9F0FF;
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              margin-right: 8px;
          "><img src="${documentBriefcase}" style="
              width: 16px;
          "></p>${typeof list[i] === "string" ? list[i] : (list[i] as File).name}</div>`;
    }
    return content;
  };

  useEffect(() => {
    if (listSelectedFiles.length > 0) {
      setListCVs([]);
      // setListJDs([]);
    }
  }, [listSelectedFiles]);

  useEffect(() => {
    setLastSessionId(undefined);
  }, [chatType]);

  const onFinish = (values: any) => {
    if (!values?.msgContent || isLoadingMsg || isSendingMsg) return;
    let username = userLogin?.replace("@fpt.com", "") || "aic";
    if (listCVs.length > 0) {
      confirm({
        title: "Do you want to share your uploaded CV in the non AIC pool?",
        icon: <ExclamationCircleOutlined />,
        content: "Other users will be able to search for these CVs",
        okText: "Yes",
        cancelText: "No",
        className: "custom-confirm",
        closable: false,
        onOk() {
          handleUpload(username, values.msgContent, true);
        },
        onCancel() {
          handleUpload(username, values.msgContent, false);
        },
      });
      (fileInputCVsRef.current as HTMLInputElement).value = "";
      (fileInputJDsRef.current as HTMLInputElement).value = "";
      return;
    }
    if (listSelectedFiles.length > 0) {
      handleUpload(username, values.msgContent, false);
      return;
    }
    let contentMessage = values.msgContent;
    if (listJDs.length > 0) {
      contentMessage = `${jdContent} ${values.msgContent}`;

      setListChat((pre) => [
        ...pre,
        {
          id: dayjs().unix().toString(),
          from: userLogin || "",
          content_message: values.msgContent,
          is_read: "no",
        },
        {
          id: dayjs().unix().toString(),
          from: userLogin || "",
          content_message: getContentFromList(listJDs.map((item) => item.name)),
          is_read: "no",
        },
      ]);
      setListJDs([]);
      (fileInputJDsRef.current as HTMLInputElement).value = "";
    } else {
      setListChat((pre) => [
        ...pre,
        {
          id: dayjs().unix().toString(),
          from: userLogin || "",
          content_message: values.msgContent,
          is_read: "no",
        },
      ]);
    }
    form.setFieldValue("msgContent", "");
    scrollIntoView();
    let department = undefined;
    if (chatType === "B") {
      username = "aic";
    } else if (chatType === "C") {
      department = "aic";
    } else if (chatType === "D") {
      department = "aic";
    }
    handleSendMsg(contentMessage, username, undefined, department);
    // handleSendMsg(contentMessage);
  };

  const handleSendMsg = async (
    msg: string,
    username?: string,
    tempIndexName?: string,
    department?: string
  ) => {
    setIsLoadingMsg(true);
    try {
      const data: ICVChatParam = {
        session_id: lastSessionId || dayjs().unix().toString(),
        username: username || "aic",
        user_query: msg,
        ...(tempIndexName && { temp_index_name: tempIndexName }),
        ...(department && { department: department }),
      };
      scrollIntoView();
      let res;
      if (tempIndexName) {
        res = await sendMsgCVMatchMakerWithTemp(data);
      } else if (chatType === "D") {
        res = await sendMsgAICCVMatchMaker(data);
      } else {
        res = await sendMsgCVMatchMaker(data);
      }

      console.log("res", res);
      if (res) {
        const data: IChatInfor = {
          content_message: res.chat_response,
          from: "cv-matchmaker-bot",
          id: dayjs().unix().toString(),
          cv_sources: res.cv_sources,
        };
        setLastSessionId(res.session_id);
        setListChat((pre) => [...pre, data]);
        listCVPath.current = [...listCVPath.current, ...res.cv_sources];
      }
      setIsLoadingMsg(false);
      scrollIntoView();
    } catch (error) {
      message.error(`Can't send message!`);
      setIsLoadingMsg(false);
    }
  };

  const handleDownloadCV = async (cv: string) => {
    try {
      const res = await downloadCV(cv);
      if (!res) return;
      const contentDisposition = res?.headers
        ? res.headers["content-disposition"]
        : "";
      console.log("contentDisposition", contentDisposition);
      const blob = new Blob([res.data], { type: "application/pdf" });
      const fileName = cv.split("/")[cv.split("/").length - 1];
      saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const url = (target as HTMLAnchorElement).getAttribute("href") as string;
      const decodedUrl = decodeURIComponent(url);
      if (target.tagName === "A" && listCVPath.current.includes(decodedUrl)) {
        event.preventDefault(); // Prevent the default action
        // Call API download
        handleDownloadCV(decodedUrl);
      }
    };

    // Adding event listener to the document
    document.addEventListener("click", handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const beforeSendMsg = async (
    msg: string,
    username: string,
    listFileName: string[]
  ) => {
    try {
      const tempIndexRes = await indexSelectedFiles(username, listFileName);
      const tempIndexName = tempIndexRes.data.temp_index_name;
      // setLastTempIndexName(tempIndexName)
      let contentMessage = msg;
      const tempContent = [
        {
          id: dayjs().unix().toString(),
          from: userLogin || "",
          content_message: msg,
          is_read: "no",
        },
        {
          id: dayjs().unix().toString(),
          from: userLogin || "",
          content_message: getContentFromList(listFileName),
          is_read: "no",
        },
      ];
      if (listJDs.length > 0) {
        contentMessage = `${jdContent} ${msg}`;
        tempContent.push({
          id: dayjs().unix().toString(),
          from: userLogin || "",
          content_message: getContentFromList(listJDs.map((item) => item.name)),
          is_read: "no",
        });
      }
      setListChat((pre) => [...pre, ...tempContent]);
      scrollIntoView();
      setListSelectedFiles([]);
      setListCVs([]);
      setListJDs([]);
      form.setFieldValue("msgContent", "");
      handleSendMsg(contentMessage, username, tempIndexName);
      setIsSendingMsg(false);
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        message.error(error?.response?.data?.detail);
      } else {
        message.error(`Can't send message!`);
      }
      setIsSendingMsg(false);
    }
  };

  const handleUpload = async (
    username: string,
    msg: string,
    isShared: boolean
  ) => {
    try {
      setIsSendingMsg(true);
      if (listSelectedFiles.length > 0) {
        await beforeSendMsg(msg, username, listSelectedFiles);
        return;
      }
      const listPromise = listCVs.map(async (item) => {
        await uploadAndIndexCV(username, item);
        if (isShared) {
          await uploadAndIndexCV("aic", item);
        }
      });
      const res = await Promise.allSettled(listPromise);
      if (res.some((item) => item.status === "fulfilled")) {
        if (isShared) {
          await indexACV(
            username,
            listCVs.map((item) => item.name),
            "aic",
            true
          );
        } else {
          await indexACV(
            username,
            listCVs.map((item) => item.name)
          );
        }
        await beforeSendMsg(
          msg,
          username,
          listCVs.map((item) => item.name)
        );
      }

      if (res.some((item) => item.status === "rejected")) {
        res
          .filter((item) => item.status === "rejected")
          .forEach((item: any) => {
            if (item?.reason?.response?.data?.detail) {
              message.error(item?.reason?.response?.data?.detail, 3);
            } else {
              message.error(`Can't send message!`);
            }
            setIsSendingMsg(false);
          });
      }
    } catch (error) {
      message.error(`Can't send message!`);
      setIsSendingMsg(false);
    }
  };

  const extractTextFromPDF = async (file: File) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
      const textArray: string[] = [];

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(" ");
        textArray.push(pageText);
      }

      setJDContent(textArray.join("\n"));
    } catch (error) {}
  };

  const handleFileSelected = async (
    e: React.ChangeEvent<HTMLInputElement>,
    isJD?: boolean
  ) => {
    try {
      if (!e?.target?.files) return;
      const files = Array.from(e.target.files);
      console.log("files", files);
      if (isJD) {
        // setListCVs([]);
        // setListSelectedFiles([]);
        (fileInputCVsRef.current as HTMLInputElement).value = "";
        setListJDs(files);
        extractTextFromPDF(files[0]);
      } else {
        // setListJDs([]);
        setListSelectedFiles([]);
        (fileInputJDsRef.current as HTMLInputElement).value = "";
        setListCVs(files);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (item: File, isJDs?: boolean) => {
    if (isSendingMsg) return;
    if (isJDs) {
      const newFile = listJDs.filter((file) => item.name !== file.name);
      setListJDs(newFile);
    } else {
      const newFile = listCVs.filter((file) => item.name !== file.name);
      setListCVs(newFile);
    }
  };

  const handleChangeSelect = (value: string) => {
    setTypeChat(value);
  };

  return (
    <ChatBotWrap>
      <ChatBotHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "12px" }}>
            <img
              src={chatbotLogo}
              alt="chatbot logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div style={{ marginRight: "15px" }}>
            <img src={happiLogo} alt="happi logo" />
          </div>
          <Select
            defaultValue="A"
            size="large"
            style={{
              width: 400,
              height: 40,
              borderRadius: "4px",
              border: "1px solid #D9D9D9",
            }}
            onChange={handleChangeSelect}
            options={[
              {
                value: "D",
                label: "Search within AIC's pool",
              },
              {
                value: "B",
                label: "Search within the public pool (non-AIC)",
              },
              {
                value: "A",
                label: "Search within your uploaded CVs",
              },
              {
                value: "C",
                label: "Search within your uploaded CVs and the public pool",
              },
            ]}
          />
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setOpenRatingModal(true)}
          >
            <IconStarPurple />
          </div>
        </div>
      </ChatBotHeader>
      <ChatBotBody>
        <ChatBotContentWrap>
          <ChatContent listMsg={listOldChat} isMarkdownContent={true} />
          <ChatContent listMsg={listChat} isMarkdownContent={true} />
          {isLoadingMsg && (
            <ChatBotMessageReceive>
              <div style={{ width: "32px" }}>
                <img
                  src={chatbotLogo}
                  alt="chatbot logo"
                  width={"32px"}
                  height={"32px"}
                />
              </div>
              <div className="content">
                <TypingMsg>
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </TypingMsg>
              </div>
            </ChatBotMessageReceive>
          )}
          <div ref={messagesEndRef} />
        </ChatBotContentWrap>
      </ChatBotBody>
      <div className="file-list">
        {listCVs.length > 5 && (
          <p
            style={{
              color: "red",
              marginLeft: "10px",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={() => {
              (fileInputCVsRef.current as HTMLInputElement).value = "";
              setListCVs([]);
            }}
          >
            Clear All
          </p>
        )}
        {listCVs.map((item: File) => (
          <div key={item.name} className="file-item">
            <div className="file-icon">
              <FilePdfOutlined />
            </div>
            <div className="file-name">{item.name}</div>
            <div className="file-delete" onClick={() => handleDelete(item)}>
              <DeleteOutlined style={{ color: "red" }} />
            </div>
          </div>
        ))}
        {listJDs.map((item: File) => (
          <div key={item.name} className="file-item">
            <div className="file-icon">
              <FilePdfOutlined />
            </div>
            <div className="file-name">{item.name}</div>
            <div
              className="file-delete"
              onClick={() => handleDelete(item, true)}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </div>
          </div>
        ))}
        {listSelectedFiles.map((item: string) => (
          <div key={item} className="file-item">
            <div className="file-icon">
              <FilePdfOutlined />
            </div>
            <div className="file-name">{item}</div>
          </div>
        ))}
      </div>
      <ChatBotFooter>
        {isOpenSuggestion && (
          <div className="message-options">
            {listSuggestion.map((item) => (
              <div
                key={item}
                className={`message ${selectedSuggestion === item && "active"}`}
                onClick={() => handleSelectSuggestion(item)}
              >
                {item}
              </div>
            ))}
          </div>
        )}
        {/* <img
          src={menuIcon}
          style={{ cursor: "pointer" }}
          onClick={openSuggestion}
        /> */}
        <Tooltip placement="top" title="Upload CV">
          <label
            style={{ display: "flex", marginRight: "8px", cursor: "pointer" }}
          >
            <VerticalAlignTopOutlined style={{ fontSize: "24px" }} />
            <input
              ref={fileInputCVsRef}
              style={{
                width: 0,
                opacity: 0,
              }}
              type="file"
              multiple
              accept="application/pdf"
              onChange={handleFileSelected}
              disabled={isSendingMsg}
            />
          </label>
        </Tooltip>
        <Tooltip placement="top" title="Upload JD">
          <label
            style={{ display: "flex", marginRight: "8px", cursor: "pointer" }}
          >
            <VerticalAlignTopOutlined style={{ fontSize: "24px" }} />
            <input
              ref={fileInputJDsRef}
              style={{
                width: 0,
                opacity: 0,
              }}
              type="file"
              accept="application/pdf"
              onChange={(e) => handleFileSelected(e, true)}
              disabled={isSendingMsg}
            />
          </label>
        </Tooltip>
        <Tooltip placement="top" title="Search within your uploaded CVs">
          <FolderOpenOutlined
            style={{ fontSize: "24px" }}
            onClick={() => setIsOpenSelectCVModal(true)}
          />
        </Tooltip>
        <Form
          form={form}
          onFinish={onFinish}
          style={{ display: "flex", width: "100%" }}
        >
          <Form.Item
            name="msgContent"
            rules={[{ required: true, message: "" }]}
            style={{ flex: 1, marginLeft: "16px" }}
          >
            <Input placeholder="Type Message..." />
          </Form.Item>
          <Form.Item>
            <button type="submit">
              {isSendingMsg ? (
                <LoadingOutlined />
              ) : (
                <img src={sendIcon} style={{ cursor: "pointer" }} />
              )}
            </button>
          </Form.Item>
        </Form>
      </ChatBotFooter>
      {isOpenSelectCVModal && (
        <ModalChooseCV
          selectedFiles={listSelectedFiles}
          isOpen={isOpenSelectCVModal}
          onClose={() => setIsOpenSelectCVModal(false)}
          setListSelectedFiles={setListSelectedFiles}
        />
      )}
    </ChatBotWrap>
  );
};
