import { Button, Checkbox, Col, Input, List, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { CustomModal } from "./styled";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  DeleteOutlined,
  LoadingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { getAllCVs } from "services/cvMatchMaker";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export const ModalChooseCV = ({
  isOpen,
  selectedFiles,
  onClose,
  setListSelectedFiles,
}: {
  isOpen: boolean;
  selectedFiles: string[];
  onClose: () => void;
  setListSelectedFiles: (listFiles: string[]) => void;
}) => {
  const { Search } = Input;
  const [dataSelected, setDataSelected] = useState<string[]>([]);
  const [listCVs, setListCVs] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listCVsShow, setListCVsShow] = useState<string[]>([]);
  const userLogin = sessionStorage.getItem("UserEmail");

  const handleCancel = () => {
    onClose();
  };

  const getCVs = async () => {
    try {
      setIsLoading(true);
      const username = userLogin?.replace("@fpt.com", "") || "aic";
      const res = await getAllCVs({ username, hiring_status: "Available" });
      const temp = res.map((item) =>
        item.file_path.replace(`cvs_indexed/${username.toLowerCase()}/`, "")
      );
      setListCVsShow(temp);
      setListCVs(temp);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDataSelected(selectedFiles);
    getCVs();
  }, []);

  const onChange = (e: CheckboxChangeEvent) => {
    const temp = [...dataSelected];
    if (temp.includes(e.target.value)) {
      temp.splice(temp.indexOf(e.target.value), 1);
    } else {
      temp.push(e.target.value);
    }
    setDataSelected(temp);
  };

  const onSearch = (value: string) => {
    if (!value) {
      setListCVsShow(listCVs);
    }
    const filteredCVs = listCVs.filter((cv) =>
      cv.toLowerCase().includes(value.toLowerCase())
    );
    setListCVsShow(filteredCVs);
  };

  const handleRemove = (index: number) => {
    const newData = dataSelected.filter((_, i) => i !== index);
    setDataSelected(newData);
  };

  return (
    <CustomModal
      title={
        <div className="title-group">
          <div className="main-title">Choose CV</div>
          <div className="sub-title">Search within your uploaded CVs</div>
        </div>
      }
      open={isOpen}
      // onOk={handleOk}
      // onCancel={handleCancel}
      width={800}
      closable={false}
      footer={[
        <Button key="Cancel" onClick={handleCancel} size="large">
          Cancel
        </Button>,
        <Button
          key="choose"
          type="primary"
          onClick={() => {
            onClose();
            setListSelectedFiles(dataSelected);
          }}
          size="large"
        >
          Choose
        </Button>,
      ]}
    >
      <div className="main-content">
        <div className="left">
          <div className="header-group">
            <h3>Selected CVs</h3>
            <Button type="link" onClick={() => setDataSelected([])}>
              Delete all
            </Button>
          </div>
          <ul className="selected-list">
            {dataSelected.map((item, index) => (
              <li key={item} className="selected-item">
                <div className="item-wrap">
                  <span className="item-icon">
                    <UserOutlined style={{ color: "#722ED1" }} />
                  </span>
                  <span className="item-text">{item}</span>
                  <span
                    className="item-action"
                    onClick={() => handleRemove(index)}
                  >
                    <DeleteOutlined />
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="right">
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            enterButton
            className="input-search"
          />
          <div className="list-cvs">
            {!isLoading && (
              <Row>
                {listCVsShow.map((item) => {
                  return (
                    <Col span={24} className="list-item" key={item}>
                      <Checkbox
                        value={item}
                        onChange={onChange}
                        checked={dataSelected.includes(item)}
                      >
                        {item}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            )}
            {isLoading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <LoadingOutlined style={{ fontSize: "40px" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
