import { createSlice } from "@reduxjs/toolkit";
import { importDatabul, previewUploadbul } from "./actions";
import { State } from "./type";

const initialState: State = {
  listbul: {
    loading: false,
    result: [],
  },
  listbulreview: {
    loading: false,
    result: [],
  },
};

export const slice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(importDatabul.pending, (state) => {
      state.listbul.loading = true;
      state.listbul.result = initialState.listbul.result;
      state.listbul.error = {};
    });
    builder.addCase(importDatabul.fulfilled, (state, action) => {
      state.listbul.loading = false;
      state.listbul.result = action.payload;
      state.listbul.error = {};
    });
    builder.addCase(importDatabul.rejected, (state, action) => {
      state.listbul.loading = false;
      state.listbul.error = action.error;
      state.listbul.result = initialState.listbul.result;
    });
    builder.addCase(previewUploadbul.pending, (state) => {
      state.listbulreview.loading = true;
      state.listbulreview.result = initialState.listbulreview.result;
      state.listbulreview.error = {};
    });
    builder.addCase(previewUploadbul.fulfilled, (state, action) => {
      state.listbulreview.loading = false;
      state.listbulreview.result = action.payload;
      state.listbulreview.error = {};
    });
    builder.addCase(previewUploadbul.rejected, (state, action) => {
      state.listbulreview.loading = false;
      state.listbulreview.error = action.error;
      state.listbulreview.result = initialState.listbulreview.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  importDatabul,
  previewUploadbul,
};

export const { reducer } = slice;
