import { Divider, Image, Typography } from "antd";
import * as S from "./styled";
import no1Svg from "assets/svg/no1.svg";
import no2Svg from "assets/svg/no2.svg";
import no3Svg from "assets/svg/no3.svg";

export const LeaderBoardContent = ({ tabType = "A" }: { tabType?: string }) => {
  const { Title, Paragraph } = Typography;

  return (
    <S.LeaderBoardContentWrap>
      <div className="leadershipRight-content">
        {/* <Image
          // width={200}
          preview={false}
          src="../src/assets/images/Leadership_1.png"
        /> */}
        <Title level={3} style={{ textAlign: "center", fontWeight: "500" }}>
          Leadership Board {tabType}
        </Title>
        <Paragraph
          // ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
          style={{ textAlign: "center" }}
        >
          {`Campaign sẽ được tính điểm từ ngày bắt đầu gửi danh sách và 2 tuần sau. Leadership Board ${tabType} dành cho các BU có số lượng ${tabType === "A" ? ">" : "2 hoặc"} 3 nhân viên trong danh sách dự đoán tháng đó.`}
        </Paragraph>
        <S.MedalGroup>
          <S.MedalItem>
            <Image src={no1Svg} width={50} preview={false} />
            <div className="ranking-name">Gold medal</div>
            <div className="ranking-reward">
              {tabType === "A" ? "300,000" : "200,000"} VND
            </div>
          </S.MedalItem>
          <S.MedalItem>
            <Image src={no2Svg} width={50} preview={false} />
            <div className="ranking-name">Silver medal</div>
            <div className="ranking-reward">
              {tabType === "A" ? "200,000" : "150,000"} VND
            </div>
          </S.MedalItem>
          <S.MedalItem>
            <Image src={no3Svg} width={50} preview={false} />
            <div className="ranking-name">Bronze medal</div>
            <div className="ranking-reward">
              {tabType === "A" ? "100,000" : "50,000"} VND
            </div>
          </S.MedalItem>
        </S.MedalGroup>
        <Divider dashed={true} />
        <Title level={3} style={{ textAlign: "center", fontWeight: "500" }}>
          Cách tính điểm
        </Title>
        <ul>
          <li>
            <strong>Full Feedback: </strong>
            điểm được tính dựa trên tỉ lệ feedback của BU kể từ ngày gửi danh
            sách của tháng đó, feedback được coi là hoàn thành dựa vào điền cột
            BUL Confirm (với những BU được 100% feedback hoàn thành sẽ đạt được
            điểm tối đa 30/30, tỉ lệ % hoàn thành ít hơn sẽ trừ theo số điểm
            tương ứng)
          </li>
          <li>
            <strong>Fast Feedback:</strong> điểm được tính dựa trên thời gian
            feedback của BU kể từ ngày gửi danh sách của tháng đó (với những BU
            hoàn thành full feedback trong thời gian nhanh nhất sẽ được điểm tối
            đa 30/30 và những BU hoàn thành sau sẽ trừ theo số điểm tương ứng)
          </li>
          <li>
            <strong>Informative Feedback:</strong> hoàn thành lý do tại sao BUL
            nghĩ bạn nhân viên đó có/không có khả năng nghỉ việc, điểm tối đa
            khi hoàn thành đủ là 40/40
          </li>
        </ul>
        <S.Notice>
          Lưu ý: Điểm chỉ hiển thị khi BU hoàn thành cả 3 tiêu chí{" "}
        </S.Notice>
        <S.Notice>
          Thời gian kết thúc: Sau 2 tuần kể từ ngày gửi danh sách sẽ công bố kết
          quả trên web SpaceX{" "}
        </S.Notice>
      </div>
    </S.LeaderBoardContentWrap>
  );
};
