import { Button } from "antd";
import styled from "styled-components";
export const customNoteEmail = styled.p`
  font-size: 14px;
  color: #388e3c;
`;
export const customNoteEmailhr = styled.span`
  font-size: 14px;
  color: #5440b8;
  min-width: 300px;
`;
export const ButtonMore = styled(Button)`
  text-align: end;
  font-weight: bold;
  padding: 0px !important;
  text-align: top;
  position: absolute;
  right: 0px;
  weight: 10px;
  background-color: #fafafa !important;
`;
export const Div = styled.div`
  text-align: justify;
`;
