import { Modal, ModalProps } from "antd";
import { ImageThankYou } from "assets/images";
import React from "react";
import * as S from "./styles";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { reporttSelector } from "store";

type Props = ModalProps & {
  setIsModalThankYou: (value: boolean) => void;
};


const ModalThankYou = (props: Props) => {
  const { instance, accounts } = useMsal();
  const responseFeedback = useSelector(reporttSelector.selectItem);

  const handleCancel = () => {
    props.setIsModalThankYou(false)
  }
  return (
    <Modal {...props} centered footer={null} width={578} onCancel={handleCancel}>

      <S.CustomDivThankYou>
        <ImageThankYou />
        <S.CustomPTitleThankYou>{`Team SpaceX cảm ơn Anh/Chị ${accounts?.[0]?.username?.split('@')[0]}`}</S.CustomPTitleThankYou>
        <p>{`đã hoàn thiện feedback về danh sách dự đoán nhân viên có ý định nghỉ việc. Điều này đã đóng góp rất nhiều cho dữ liệu huấn luyện của mô hình AI và giúp chúng ta cùng nắm bắt tốt hơn động thái của nhân viên, từ đó có những chiến lược và biện pháp phát triển nhân sự hợp lý và kịp thời cho đơn vị và cho cả Fsoft.`}</p>
      </S.CustomDivThankYou>

    </Modal>
  );
};

export default ModalThankYou;
