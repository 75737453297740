import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncAction } from "../../helpers/thunkWrapper";
import { ActionParams, Response } from "../../types/common";
import { buildUrl } from "helpers/utils/app";

export const fetchReport = createAsyncThunk(
  "report/get_report",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(
      `${process.env.REACT_APP_API_URL}/get-predictions`,
      payload.params
    );

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
export const updateChurnIntention = createAsyncThunk(
  "update/churnintention",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/update-churn-intention`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const saveComment = createAsyncThunk(
  "save/save-comment",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/save-comments`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const listdeparment = createAsyncThunk(
  "list/list-deparment",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/list-department`;

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
export const listdeparmentall = createAsyncThunk(
  "list/list-deparment-all",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(
      `${process.env.REACT_APP_API_URL}/list-department`,
      payload.params
    );

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
export const get_search = createAsyncThunk(
  "search/get_search",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(
      `${process.env.REACT_APP_API_URL}/search-data`,
      payload.params
    );

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
export const sendemail = createAsyncThunk(
  "send/send-email",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/notify-status`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const addempolyee = createAsyncThunk(
  "add/employee",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/insert-data`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const exportData = createAsyncThunk(
  "export/exportData",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(
      `${process.env.REACT_APP_API_URL}/export-data`,
      payload.params
    );

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
export const deleteEmployee = createAsyncThunk(
  "delete/delete-employee",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/delete-data`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const updateNotified = createAsyncThunk(
  "update/updateNotified",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/update-notified`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const importData = createAsyncThunk(
  "import/importData",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/import-data`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const previewUpload = createAsyncThunk(
  "preview/previewUpload",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/preview-upload`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const previewUploadBul = createAsyncThunk(
  "previewbul/previewUploadBul",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/bul-preview`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const bulUpload = createAsyncThunk(
  "bul/bulUpload",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/bul-upload`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const addempolyeePlain = createAsyncThunk(
  "add/employeeplain",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/insert-data-plain`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const bulUploadPlain = createAsyncThunk(
  "bul/bulUploadPlain",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/bul-upload-plain`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const previewUploadBulPlain = createAsyncThunk(
  "previewbul/previewUploadBulPlain",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/bul-preview-plain`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const updateBulKnown = createAsyncThunk(
  "update/bulknown",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/bul-known`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const searchAccount = createAsyncThunk(
  "search/searchPermission",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(
      `${process.env.REACT_APP_API_URL}/search-account`,
      payload.params
    );

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const getFromDate = createAsyncThunk(
  "getdate/getfromdate",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/get-default-from-date`;

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);
