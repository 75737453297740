import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncAction } from "../../helpers/thunkWrapper";
import { ActionParams } from "../../types/common";

export const importData = createAsyncThunk(
  "import/importData",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/import-data`;

    const response = await api.caller.post(endpoint, payload.params, { headers: {
      "Content-Type": "multipart/form-data" 
     
    },});
    const data = response.data;

    return data;
  })
);
export const previewUpload = createAsyncThunk(
  "preview/previewUpload",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/preview-upload`;
    console.log("payload", payload.params)
    const response = await api.caller.post(endpoint, payload.params, { headers: {
      "Content-Type": "multipart/form-data" 
     
    },});
    const data = response.data;

    return data;
  })
);
