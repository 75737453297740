import styled from "styled-components";



export const CustomPTitleThankYou = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #141414;
  margin-top: 20px;
  margin-bottom: 20px;

`;
export const CustomDivThankYou = styled.div`
 text-align: center;

`;
