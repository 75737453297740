import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncAction } from "../../helpers/thunkWrapper";
import { ActionParams } from "../../types/common";

export const importDatabul = createAsyncThunk(
  "import/importDatabul",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/bul-upload-plain`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
export const previewUploadbul = createAsyncThunk(
  "preview/previewUploadbul",
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/bul-preview-plain`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
